import React from 'react';
import { Label } from 'office-ui-fabric-react';
import './OrderLineItem.scss'
import { injectIntl } from 'react-intl';
import './OrderLineItem.scss';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';

const OrderLineItemInfo = (props) => {

    let onAccordionToggle = (toggle) =>{      
        let lineItem  = {...props};
        lineItem.isCollapsed = toggle;
        props.handleChange(lineItem);
    }

    return (
        <div className="line-item-info" >
            <Accordion isOpen={!props.isCollapsed} onAccordionToggle={onAccordionToggle}>
                <AccordionHeader accordionheader={'Line Item' + props.DisplayId}>
                    <div className="line-item-header">
                        <span className="line-item-number">
                            Line Item {props.DisplayId} 
                            {props.isCollapsed && (props.EndItem.value || props.Quantity.value) ? "  -" : ""}
                        </span>
                        {
                            props.isCollapsed && props.EndItem.value &&
                            <span className="line-item-part">
                                {"[" + props.EndItem.value + "]"}
                            </span>
                        }
                        {
                            props.isCollapsed &&  (props.Quantity.value)  &&
                            <span className="line-item-qty">
                                {"Qty: " + props.Quantity.value}
                            </span>
                        }                   
                    </div>                  
                </AccordionHeader>
                <AccordionBody>
                    <div className="ms-Grid">
                        <div className="ms-Grid-row line-item-sub-header">
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ARLineItemNoLabel" id="ARLineItemNoLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderLineItem.ARLineitemNoTitle', defaultMessage: 'AR Line Item No' })}</Label>
                                <Label name="ARLineItemNoValue" id="ARLineItemNoValue" className="labelValue">{typeof (props.ArlineItemNumber) === 'string' ? props.ArlineItemNumber : props.ArlineItemNumber.value}</Label>
                            </div>
                            <div className="ms-Grid-col ms-md4">
                                <Label name="LineItemQuantityLabel" id="LineItemQuantityLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderLineItem.Quantity', defaultMessage: 'Quantity' })}</Label>
                                <Label name="LineItemQuantityValue" id="LineItemQuantityValue" className="labelValue">{typeof (props.Quantity) === 'string' ? props.Quantity : props.Quantity.value}</Label>
                            </div>
                        </div>
                        <div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-md12">
                                    <Label name="EndItemLabel" id="EndItemLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderLineItem.EndItemTitle', defaultMessage: 'End Item' })}</Label>
                                    <Label name="EndItemValue" id="EndItemValue" className="labelValue">{typeof (props.EndItem) === 'string' ? props.EndItem : props.EndItem.text}</Label>
                                </div>
                            </div>
                            {props.LicensableItem && props.LicensableItem.text && props.LicensableItem.text !== '' ?
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-md12">
                                        <Label name="LicensableItemLabel" id="LicensableItemLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderLineItem.LicensableTitle', defaultMessage: 'Licensable' })}</Label>
                                        <Label name="LicensableItemValue" id="LicensableItemValue" className="labelValue">{props.LicensableItem.text}</Label>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>


        </div>
    );
}

export default injectIntl(OrderLineItemInfo);