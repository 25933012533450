import { makeActionCreator } from "../../utility/makeActionCreator";

export const SHOW_ALERT = "core/Components/Alert/SHOW_ALERT";
export const showAlert = makeActionCreator(SHOW_ALERT,true,"alert");

export const HIDE_ALERT = "core/Components/Alert/HIDE_ALERT";
export const hideAlert = makeActionCreator(HIDE_ALERT,true,"alert");

export const HIDE_ALL_ALERTS = "core/Components/Alert/HIDE_ALL_ALERTS";
export const hideAllAlerts = makeActionCreator(HIDE_ALL_ALERTS,true);

export const HIDE_FEATURE_ALERTS = "core/Components/Alert/HIDE_FEATURE_ALERT";
export const hideFeatureAlert = makeActionCreator(HIDE_FEATURE_ALERTS,true,"feature");

export const SHOW_HIDDEN_ALERT = "core/Components/Alert/SHOW_HIDDEN_ALERT";
export const showHiddenAlert = makeActionCreator(SHOW_HIDDEN_ALERT,true,"alert");

export const HIDE_HIDDEN_ALERT = "core/Components/Alert/HIDE_HIDDEN_ALERT";
export const hideHiddenAlert = makeActionCreator(HIDE_HIDDEN_ALERT,true,"alert");