import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

const propTypes = {
    chartTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            y: PropTypes.number.isRequired,
        })
    ).isRequired,
    onAxisClick: PropTypes.func,
};

const ChunkStatuses = {
    LOADED: "Loaded",
    FAILED: "Failed",
    INPROGRESS: "InProgress"
}

class ColumnChart extends Component {
    state = {
        modulesLoaded: ChunkStatuses.INPROGRESS,
        HighchartsReact: null, // Default value as null
    };

    componentDidMount() {
        this.loadModules();
    }

    loadModules = async () => {
        try {
            // Dynamically import Highcharts modules
            const [HighchartsAccessibility, HighchartsExporting, HighchartsReact] = await Promise.all([
                import('highcharts/modules/accessibility'),
                import('highcharts/modules/exporting'),
                import('highcharts-react-official')
            ]);

            // Initialize modules
            HighchartsAccessibility.default(Highcharts);
            HighchartsExporting.default(Highcharts);

            // Update state with loaded modules
            this.setState({
                modulesLoaded: ChunkStatuses.LOADED,
                HighchartsReact: HighchartsReact.default
            });
        } catch (error) {
            console.error("Failed to load chart modules:", error);
            this.setState({ modulesLoaded: ChunkStatuses.FAILED });
        }
    };

    getColumnChartOptions = () => {
        const { chartTitle, xAxisTitle, yAxisTitle, chartData } = this.props;

        // Extract x-axis categories from chart data
        const categories = chartData.map(data => data.name);

        console.log("Generating chart options...");

        return {
            credits: { enabled: false },
            chart: { type: "column" },
            accessibility: {
                description: chartTitle || "",
                point: {
                    valueDescriptionFormat: (point) => {
                        console.log("valueDescriptionFormat called with point:", point);
                        const category = categories[point.index];
                        return `The number of orders Created on ${category} is ${point.y}.`;
                    },
                    descriptionFormatter: (point) => {
                        const category = categories[point.index];
                        return `The number of orders Created on ${point.name} is ${point.y}.`;
                    },
                },
            },
            title: { text: chartTitle || "" },
            xAxis: {
                type: "category",
                title: { text: xAxisTitle || "" },
                labels: {
                    formatter: function () {
                        return this.value; // Display x-axis values directly
                    }
                }
            },
            yAxis: {
                title: { text: yAxisTitle || "" },
                min: 0,
            },
            legend: { enabled: false },
            plotOptions: {
                series: {
                    dataLabels: { enabled: true, format: "{point.y:.0f}" },
                    events: { click: this.handleChartAxisClick },
                    point: { events: { click: this.handleChartAxisClick } },
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span>{point.name}</span>: <b>{point.y:.0f}</b><br/>'
            },
            series: [{ name: "Value", data: chartData }],
        };
    };

    handleChartAxisClick = (event) => {
        const { onAxisClick } = this.props;
        if (onAxisClick) {
            const point = event.point || event.target;
            onAxisClick(point);
        }
    };

    render() {
        const { modulesLoaded, HighchartsReact } = this.state;

        if (modulesLoaded === ChunkStatuses.INPROGRESS) {
            return <div>Loading chart modules...</div>;
        }

        if (modulesLoaded === ChunkStatuses.FAILED) {
            return <div>Error loading chart modules. Please try again later.</div>;
        }

        if (!HighchartsReact) {
            return null;
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={this.getColumnChartOptions()}
                containerProps={{ style: { height: '400px', width: '100%' } }}
            />
        );
    }
}

ColumnChart.propTypes = propTypes;

export default ColumnChart;
