import { call, put, apply, take } from 'redux-saga/effects';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { InvokeUrl } from '../../../core/utility';
import { GET_SHIPMENT_DETAILS, SET_SHIPMENT_APPROVAL_STATUS, setShipmentDetails } from './ShipmentDetails.actions';
import {showAlert} from '../../../core/components/Alert';
import {AlertsTypes} from '../../../core/constants';
import {throwException } from '../../../core/components/Logger/Logger.actions';

export function* getShipmentDetailsSaga() {
    while (true) {
        try {
            const { siteId, msShipmentId } = yield take(GET_SHIPMENT_DETAILS);   
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/${msShipmentId}`, method:"GET"}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(setShipmentDetails(data));
            }
        } catch (error) {   
            yield put(throwException("getShipmentData error: " + error));
        }
    }
}

export function* setShipmentApprovalStatus() {
    while (true) {
        try {
            const { siteId, msShipmentId, status } = yield take(SET_SHIPMENT_APPROVAL_STATUS);   
            const config = yield ApplicationConfig.config;
            const operation = status === true ? 'approve' : 'reject';
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/${msShipmentId}/${operation}`, method:"PUT"}});
            if (response.status === 200) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Shipment status is updated successfully"]}));
                const orderData = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/${msShipmentId}`, method:"GET"}});
                const orderObject = yield apply(orderData, orderData.json);
                yield put(setShipmentDetails(orderObject));
            }
        } catch (error) {     
            yield put(throwException("getOrderSaga error: " + error));
        }
    }
}



