import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {GET_VALIDATE_SHIPMENTID, setValidateShipmentId,
        GET_CUSTOMERTYPES, setCustomerTypes} from './ShipmentHeader.actions';
import { showAlert } from '../../../core/components';
import { AlertsTypes, AlertsFeature } from '../../../core/constants';

export function* isDuplicateShipmentIdSaga() {

    while (true) {
        try {
            const { siteId, shipmentId } = yield take(GET_VALIDATE_SHIPMENTID);
            const config = yield ApplicationConfig.config;
            let requestData = JSON.stringify(shipmentId);
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/isduplicate`, method:"POST", data: requestData}});

            if (responseC.status === 200) {
                const isValid = yield apply(responseC, responseC.json);
                yield put(setValidateShipmentId({ id: shipmentId.Id, status: isValid }));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to validate ShipmentId."]}));

                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to validate ShipmentId."]}));

                console.log(["Failed to validate ShipmentId."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to validate ShipmentId."]}));

            console.log("isDuplicateShipmentIdSaga error:" + error);
        }
    }
}

export function* customerTypesSaga() {

    while (true) {
        try {
           yield take(GET_CUSTOMERTYPES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/soldtotypes?isOrder=false`,method: 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);
                var customerTypesData = data.map((d) => {
                    return { key: d.DistributionChannelType.toString(), text: d.Description };
                });
                yield put(setCustomerTypes(customerTypesData));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to retrieve CustomerTypes."]}));

                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to retrieve CustomerTypes."]}));

                console.log(["Failed to retrieve CustomerTypes."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_ALERTS, message: ["Failed to retrieve CustomerTypes."]}));

            console.log("customerTypesSaga error:" + error);
        }
    }
}