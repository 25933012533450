import React from 'react';
import './OrderHeader.scss'
import { Label } from 'office-ui-fabric-react';
import { injectIntl } from 'react-intl';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';
import { TabIndexes } from '../../../common/constants';
const OrderHeaderInfo = (props) => {
    return (
        <div ref={props.headerRef} className="order-header-info">
            <Accordion isOpen={!props.isCollapsed}>
                <AccordionHeader  accordionheader={props.intl.formatMessage({ id: 'orderHeader.HeaderInfoTitle', defaultMessage: 'Order Header Details' })}>
                    <div className="header-text">
                        <h3>
                        {props.intl.formatMessage({ id: 'orderHeader.HeaderInfoTitle', defaultMessage: 'Order Header Details' })}
                        </h3>
                    </div>
                </AccordionHeader>
                <AccordionBody>
                    <div className="ms-Grid-row">
                        {
                            props.SiteId && props.SiteId.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ReportedByLabel" id="ReportedByLabel" className="labelHeader">{props.intl.formatMessage({ id: 'common.ARSiteTitle', defaultMessage: 'AR Site' })}</Label>
                                <Label name="ReportedByValue" id="ReportedByValue" className="labelValue">{props.SiteId.text}</Label>
                            </div>
                        }
                        {
                            props.OrderId && props.OrderId.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="AROrderLabel" id="AROrderLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.OrderIdTitle', defaultMessage: 'AR Order#' })}</Label>
                                <Label name="AROrderValue" id="AROrderValue" className="labelValue">{props.OrderId.text}</Label>
                            </div>
                        }
                    </div>
                    <div className="ms-Grid-row">
                        {
                            props.OrderType && props.OrderType.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="OrderTypeHeaderLabel" id="OrderTypeHeaderLabel" className="labelHeader">{props.intl.formatMessage({ id: 'order.OrderTypeTitle', defaultMessage: 'Order Type' })}</Label>
                                <Label name="OrderTypeHeaderValue" id="OrderTypeHeaderValue" className="labelValue">{props.OrderType.text}</Label>
                            </div>
                        }
                    </div>
                    <div className="ms-Grid-row">
                        {
                            props.SoldToType && props.SoldToType.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="SoldToTypeLabel" id="SoldToTypeLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.SoldToTitle', defaultMessage: 'Sold To' })}</Label>
                                <Label name="SoldToTypeValue" id="SoldToTypeValue" className="labelValue">{props.SoldToType.text}</Label>
                            </div>
                        }
                    </div>
                    <div className="ms-Grid-row">
                        {
                            props.SoldToId && props.SoldToId.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="OrganizationLabel" id="OrganizationLabel" className="labelHeader">{props.intl.formatMessage({ id: 'common.SoldToOrgTitle', defaultMessage: 'Sold-To-Organization' })}</Label>
                                <Label name="OrganizationValue" id="OrganizationValue" className="labelValue">{props.SoldToId.text}</Label>
                            </div>
                        }
                        {
                            props.BrandId && props.BrandId.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="BrandLabel" id="BrandLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.BrandNameTitle', defaultMessage: 'Brand Name' })}</Label>
                                <Label name="BrandValue" id="BrandValue" className="labelValue">{props.BrandId.text}</Label>
                            </div>
                        }
                        {
                            props.PoNumber && props.PoNumber.value &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="PONumberLabel" id="PONumberLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.PONumberTitle', defaultMessage: 'OEM PO Number' })}</Label>
                                <Label name="PONumberValue" id="PONumberValue" className="labelValue">{props.PoNumber.value} </Label>
                            </div>
                        }
                    </div>
                    <div className="ms-Grid-row">
                        {
                            props.PoDate && props.PoDate.value &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="PODateLabel" id="PODateLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.PODateTitle', defaultMessage: 'PO Date' })}</Label>
                                <Label name="PODateValue" id="PODateValue" className="labelValue">{props.intl.formatDate(new Date(props.PoDate.value))}</Label>
                            </div>
                        }
                        {
                            props.CommittedDate && props.CommittedDate.value &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ComDateLabel" id="ComDateLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.CommDateTitle', defaultMessage: 'Committed Date' })}</Label>
                                <Label name="ComDateValue" id="ComDateValue" className="labelValue">{props.intl.formatDate(new Date(props.CommittedDate.value))}</Label>
                            </div>
                        }
                        {
                            props.ReqShipDate && props.ReqShipDate.value &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ReqShipDateLabel" id="ReqShipDateLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.ReqShipDateTitle', defaultMessage: 'Requested Ship Date' })}</Label>
                                <Label name="ReqShipDateValue" id="ReqShipDateValue" className="labelValue">{props.intl.formatDate(new Date(props.ReqShipDate.value))}</Label>
                            </div>
                        }
                    </div>
                    <div className="ms-Grid-row">
                        {
                            props.ShipToType && props.ShipToType.text && props.ShipToType.value !== '0' &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ShipToTypeLabel" id="ShipToTypeLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.ShipToTitle', defaultMessage: 'Ship To' })}</Label>
                                <Label name="ShipToTypeValue" id="ShipToTypeValue" className="labelValue">{props.ShipToType.text}</Label>
                            </div>
                        }
                        {
                            props.ShipToType && props.ShipToType.text && props.ShipToId && props.ShipToId.text &&
                            <div className="ms-Grid-col ms-md4">
                                <Label name="ShipToLabel" id="ShipToLabel" className="labelHeader">{props.intl.formatMessage({ id: 'orderHeader.selectshiptoloctitle', defaultMessage: 'Ship - To - Location ' })}</Label>
                                <Label name="ShipToValue" id="ShipToValue" className="labelValue">{props.ShipToId.text}</Label>
                            </div>
                        }
                    </div>
                </AccordionBody>
            </Accordion>
        </div>
    );
}

export default injectIntl(OrderHeaderInfo);