import React, { Component } from "react";
import "./CreateOrder.scss";
import { WizardNav, FeatureAlerts } from "../../../core/components";
import { OrderConstants } from "../../constants";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as orderActions from "./CreateOrder.actions";
import { bindActionCreators } from "redux";
import {
  orderWizardDataSelector,
  createdOrderSelector,
} from "./CreateOrder.selectors";
import { WizardStepFactory } from "../../../core/components";

import OrderHeaderStep from "../OrderHeader/OrderHeaderStep";
import OrderLineItemStep from "../OrderLineItem/OrderLineItemStep";
import OrderReviewStep from "../OrderReview/OrderReviewStep";
import { Label, Announced } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import { setPageTitle } from "../../../common/helpers/pageTitle";
import ReactAI from "react-appinsights";
import { AlertsFeature } from "../../../core/constants";
import { PageTitles, TabIndexes } from "../../../common/constants";

const extractDate = (dt) => {
  dt = new Date(dt);
  return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
};

export class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arialabeloption: false,
    };
  }

  getStepComponentMap = () => {
    return {
      ORDER_HEADER: OrderHeaderStep,
      ORDER_LINE_ITEM: OrderLineItemStep,
      REVIEW_SUBMIT: OrderReviewStep,
    };
  };

  componentWillMount() {
    this.resetOrderData();
  }
  componentDidMount() {
    setPageTitle(PageTitles.CREATE_ORDER);
  }

  componentWillUnmount() {
    this.resetOrderData();
  }

  resetOrderData = () => {
    if (this.props.actions) {
      this.props.actions.getOrderWizardData();
      this.props.actions.setOrder(null);
    }
  };

  getCurrentStep = (steps) => {
    return (
      steps && steps.find((item) => item.status === OrderConstants.IN_PROGRESS)
    );
  };

  setWizardStep = (step) => {
    // if (step && (step.status === OrderConstants.DISABLED ||
    //     step.status === OrderConstants.IN_PROGRESS)) {
    //     return;
    // }
    // let wizardSteps = this.props.wizardData;
    // let lastStep = this.getCurrentStep(wizardSteps);
    // let lastStepIndex = wizardSteps.findIndex(item => item.id === lastStep.id);
    // let nextStepIndex = wizardSteps.findIndex(item => item.id === step.id);
    // if (nextStepIndex > lastStepIndex && !lastStep.isStateValid) {
    //     return;
    // }
    // this.props.actions.setOrderWizardCurrentStep(step);
  };

  onPreviousClick = (data) => {
    this.props.actions.orderWizardStepPrevious(data);
  };

  onSubmitClick = (data) => {
    if (!data.isStateValid) {
      return;
    }
    if (data.key === OrderConstants.REVIEW_SUBMIT) {
      let wizardSteps = this.props.wizardData;
      let index = wizardSteps.findIndex((item) => item.key === data.key);
      if (index >= 0) {
        wizardSteps[index] = data;
      }
      var validItems =
        wizardSteps && wizardSteps.filter((item) => item.isStateValid);
      if (wizardSteps.length !== validItems.length) return;
      var headerdata = wizardSteps.find(
        (item) => item.key === OrderConstants.ORDER_HEADER
      );
      let siteId = headerdata.inputData.SiteId.value;
      this.props.actions.saveOrder(siteId, this.getOrderRequest(wizardSteps));
    } else {
      this.props.actions.orderWizardStepNext(data);
    }
  };

  getOrderRequest = (orderData) => {
    var orderInfo = {};
    var review = orderData.find(
      (item) => item.key === OrderConstants.REVIEW_SUBMIT
    );

    var orderHeader = orderData.find(
      (item) => item.key === OrderConstants.ORDER_HEADER
    );
    if (orderHeader) {
      let headerdata = { ...orderHeader.inputData };
      orderInfo = {
        OrderId: headerdata.OrderId.value,
        OrderType: headerdata.OrderType.value,
        SoldToId: headerdata.SoldToId.value,
        PoNumber: headerdata.PoNumber.value,
        BrandId: headerdata.BrandId.value,
        ShipToType: headerdata.ShipToType.value,
        ShipToId:
          headerdata.ShipToType.value === OrderConstants.SHIPTOTYPE_OEM_VALUE
            ? headerdata.ShipToId.value
            : "",
        InstallerId:
          headerdata.ShipToType.value === OrderConstants.SHIPTOTYPE_TPI_VALUE
            ? headerdata.ShipToId.value
            : "",
        PoDate: headerdata.PoDate.value
          ? extractDate(headerdata.PoDate.value)
          : null,
        CommittedDate: headerdata.CommittedDate.value
          ? extractDate(headerdata.CommittedDate.value)
          : null,
        ReqShipDate: headerdata.ReqShipDate.value
          ? extractDate(headerdata.ReqShipDate.value)
          : null,
        Remark: review.inputData.Remark.value,
        ClientTransactionType: 0,
      };
    }

    var orderLineItems = orderData.find(
      (item) => item.key === OrderConstants.ORDER_LINE_ITEM
    );
    if (orderLineItems) {
      let items = [];
      Object.keys(orderLineItems.inputData).forEach((index) => {
        items.push({
          EndItemId: orderLineItems.inputData[index].EndItem.value,
          ShipmentTypeCode: orderLineItems.inputData[index].ShipmentType.value,
          Quantity: orderLineItems.inputData[index].Quantity.value,
          ArlineItemNumber:
            orderLineItems.inputData[index].ArlineItemNumber.value,
          LicensableItemId:
            orderLineItems.inputData[index].LicensableItem.value,
          LicenseId: orderLineItems.inputData[index].LicenseId.value,
          ClientTransactionType: 0,
        });
      });
      orderInfo.LineItems = items;
    }
    return orderInfo;
  };

  renderAnnounced = (message) => {
    return <Announced message={message} />;
  };
  onFocus = () => {
    this.setState({
      arialabeloption: true,
    });
  };
  onBlur = () => {
    this.setState({
      arialabeloption: false,
    });
  };
  render() {
    return (
      <div
        className="container order-main-section"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        role={this.state.arialabeloption ? "application" : "presentation"}
      >
        <div className="page-header">
          <h1>
            {this.props.intl.formatMessage({
              id: "createOrder.title",
              defaultMessage: "Create Order",
            })}
          </h1>
          {this.props.createdOrder ? (
            <div className="order-status-header">
              <Label className="order-status">
                MS Order Id:{" "}
                <span className="order-status-text">
                  {" "}
                  {this.props.createdOrder.msOrderId}{" "}
                </span>
              </Label>
              <Label className="order-status">
                Order Status:{" "}
                <span className="order-status-text">
                  {" "}
                  {this.props.createdOrder.status}
                </span>{" "}
              </Label>
            </div>
          ) : null}
        </div>

        {this.props.wizardData && (
          <div className="create-order-wizard">
            <div className="create-order-nav-items">
              <WizardNav
                items={this.props.wizardData}
                onItemClick={this.setWizardStep}
              ></WizardNav>
            </div>
            <FeatureAlerts
              timeOut={5000}
              feature={AlertsFeature.ORION_ALERTS}
            />

            <div className="create-order-steps">
              {this.props.createdOrder ? (
                <div className="order-confirmation">
                  {this.renderAnnounced("Order Submitted Successfully")}
                  <div
                    className="status-msg-header"
                    role="alert"
                    aria-live="assertive"
                  >
                    Order Submitted Successfully
                  </div>
                  <Label className="status-msg">
                    Your new order#{" "}
                    <Link
                      aria-label={
                        "New Order" + this.props.createdOrder.msOrderId
                      }
                      className="order-status-text"
                      to={`/Order/${this.props.createdOrder.msOrderId}?siteId=${this.props.createdOrder.siteId}`}
                    >
                      {this.props.createdOrder.msOrderId}
                    </Link>{" "}
                    has been submitted successfully
                  </Label>
                  <Label className="status-msg">
                    {" "}
                    You can view the order by clicking the link{" "}
                  </Label>
                </div>
              ) : (
                <WizardStepFactory
                  stepComponentMap={this.getStepComponentMap()}
                  currentStep={this.getCurrentStep(this.props.wizardData)}
                  onPreviousClick={this.onPreviousClick}
                  onSubmitClick={this.onSubmitClick}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let wizardDataInfo = orderWizardDataSelector(state);
  let createdOrderinfo = createdOrderSelector(state);

  if (createdOrderinfo && wizardDataInfo) {
    wizardDataInfo.forEach((item) => {
      item.status = 3;
    });
  }
  return {
    wizardData: wizardDataInfo,
    createdOrder: createdOrderinfo,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(orderActions, dispatch),
  };
};

export default ReactAI.withTracking(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateOrder)),
  PageTitles.CREATE_ORDER
);
