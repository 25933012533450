
//states : 1 -> Not Started, 2-> In Progress, 3 -> Completed, 0 -> Disabled
export const ShipmentConstants = {
    DISABLED: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    VALIDATION_ERRROR: 4,
    SHIPMENT_HEADER: "SHIPMENT_HEADER",
    SHIPMENT_LINE_ITEM: "SHIPMENT_LINE_ITEM",
    REVIEW_SUBMIT: "REVIEW_SUBMIT",
    CUSTOMERTYPE_OEM_VALUE:"1",   
    CUSTOMERTYPE_AR_VALUE:"3",
    CUSTOMERTYPE_EMBDisti_VALUE:"8",
    YES_TEXT: "Yes",
    No_TEXT: "No",
    SHIPMENTLINEITEM_PRODUCTTYPE:"ProductType",
    SHIPMENTLINEITEM_PRODUCTTYPE_ENDITEM_VALUE:"1",
    SHIPMENTLINEITEM_PRODUCTTYPE_COMPONENT_VALUE:"2",
    SHIPMENTLINEITEM_PRODUCTTYPE_ENDITEM_TEXT:"End Item",
    SHIPMENTLINEITEM_PRODUCTTYPE_COMPONENT_TEXT:"Component",
    SHIPMENTLINEITEM_LINEITEMID:"LineItemId",
    SHIPMENTLINEITEM_INACTIVEENDITEM: "InactiveEndItem",
    SHIPMENTLINEITEM_POMEMO: "PoMemo",
    SHIPMENTLINEITEM_INVOICENUMBER: "InvoiceNumber",
    SHIPMENTLINEITEM_SHIPMENTTYPE_COGS_VALUE:"COGS",
    SHIPMENTLINEITEM_SHIPMENTTYPE_SAMPLE_VALUE:"Sample",
    SHIPMENTLINEITEM_SHIPMENTTYPE_TRANSFER_VALUE:"Transfer",
    SUBMITTED_STATUS_CODE: "31",
    SUBMITTED_ACCEPT_STATUS_CODE: "41"
    }


