import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes, AlertsFeature } from '../../../core/constants';
import * as SearchShipToActions from './SearchShipTo.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import uuidv4 from 'uuid/v4';

const getQueryParameter = (siteId, customerId, searchFilter,shipToId) => {
    let queryParams = "";

    queryParams = queryParams + (searchFilter.page ? "PageNo=" + searchFilter.page : "");
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
    queryParams = queryParams + (siteId ? "&siteId=" + siteId : "");
    queryParams = queryParams + (customerId ? "&customerId=" + customerId : "");
    queryParams = queryParams + (shipToId ? "&searchString=" + shipToId : "");

    return queryParams;
}



function mapShipToDataToRequest(inputData) {
    let request = {
        Name: inputData.shipToName.text ? inputData.shipToName.text : '',
        Address1: inputData.shipToAddress1.text ? inputData.shipToAddress1.text : '',
        Address2: inputData.shipToAddress2.text ? inputData.shipToAddress2.text : '',
        Address3: inputData.shipToAddress3.text ? inputData.shipToAddress3.text : '',
        CountryCode: inputData.shipToCountry.value ? inputData.shipToCountry.value : '',
        StateProvince: inputData.shipToState.text ? inputData.shipToState.text : '',
        City: inputData.shipToCity.text ? inputData.shipToCity.text : '',
        PostalCode: inputData.shipToZip.text ? inputData.shipToZip.text : '',
        Status: inputData.shipToStatus.value ? inputData.shipToStatus.value : ''
    }
    return request;
}

export function* saveShipToSaga() {
    while (true) {
        try {
            const { siteId, customerId, request } = yield take(SearchShipToActions.SAVE_SHIPTO);
            let requestData = JSON.stringify(mapShipToDataToRequest(request));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/shipto`, method: "POST", data: requestData }, IsPanelTask: true });
            if (response.status === 201) {
                yield put(SearchShipToActions.saveShipToOperationCompletionStatus({ status: true }));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Ship To Details Saved Successfully"] }));
            }
            else if (response.status === 400) {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to Create Ship To!"] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred. Failed to save Ship To."] }));
        }
    }
}

export function* searchShipToSaga() {
    while (true) {
        try {
            const { aradsiteId, customerId,shipToId, searchFilter } = yield take(SearchShipToActions.SEARCH_SHIPTO);

            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${aradsiteId}/customer/${customerId}/shipto?${getQueryParameter(aradsiteId, customerId, searchFilter,shipToId)}`, method: 'GET' } });
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(SearchShipToActions.setSearchShipToResult(data));
            } else {
                yield put(SearchShipToActions.setSearchShipToResult(null));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to get ShipTo."] }));
        }
    }
}


export function* updateShipToSaga() {
    while (true) {
        try {
            const { siteId, customerId, customerSiteId, request } = yield take(SearchShipToActions.UPDATE_SHIPTO);
            let requestData = JSON.stringify(mapShipToDataToRequest(request));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/shipto/${customerSiteId}`, method: "PUT", data: requestData }, IsPanelTask: true  });
            if (response.status === 200) {
                yield put(SearchShipToActions.saveShipToOperationCompletionStatus({ status: true }));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature: AlertsFeature.ORION_PANEL_ALERTS, message: [`ShipTo Details Updated Successfully`] }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to update ShipTo."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to update ShipTo."] }));
        }
    }
}

export function* customerSiteDetailsSaga() {
    while (true) {
        try {
            const { siteId, customerId, customerSiteId } = yield take(SearchShipToActions.GET_SHIPTO_BY_CUSTOMERSITEID);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/shipto/${customerSiteId}`, method: "GET" }, IsPanelTask: true });
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(SearchShipToActions.setCustomerSiteDetails(data));
            }else if (response.status === 404) {
                yield put(showAlert({ type: AlertsTypes.WARNING, feature:AlertsFeature.ORION_PANEL_ALERTS, message: [`Ship To ID "${customerSiteId}" does not exist`] }));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to get Ship To Details."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to fetch Ship To Details."] }));
        }
    }
}
