import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as searchVerifyLicenseActions from './SearchVerifyLicense.actions';

export const verifyLicenseSearchResult=createReducer(null,{
  [searchVerifyLicenseActions.SET_SEARCH_VERIFY_LICENSE_RESULT](state,{verifyLicenseSearchResult}){
    return fromJS(verifyLicenseSearchResult)
  }
})

export const verifyLicenseEndItemsSearchText=createReducer(null,{
  [searchVerifyLicenseActions.SET_VERIFY_LICENSE_ENDITEM_LIST](state,{verifyLicenseEndItemsSearchText}){
    return fromJS(verifyLicenseEndItemsSearchText)
  }
})