import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as alertActions from './Alert.actions';
import { connect } from "react-redux";
import "./Alert.scss";

class HiddenAlert extends Component {

    render() {        
        const { id, message, dismissTime } = this.props;
        const thisRef = this;
        this.alert = { id, message };

        setTimeout(() => { thisRef.onDismiss(thisRef.alert) }, dismissTime);
        return (
            <React.Fragment>
                <div role="alert" aria-relevant="all" aria-atomic="true" aria-live="assertive" className="hidden-alert">
                   {message}
                </div>
            </React.Fragment>
        )
    }   
    
    onDismiss = (alert) => {
        this.props.actions.hideHiddenAlert(alert);
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(alertActions, dispatch),
    }
}
export default connect(
    null,
    mapDispatchToProps
)(HiddenAlert);
