import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react';

const propTypes = {
    items: PropTypes.array,
    onItemSelectionRemove: PropTypes.func
}

export class SelectedItemBadge extends Component {

    handleRemove(itemToBeRemoved){     
        this.props.onItemSelectionRemove([itemToBeRemoved]);        
    }

    handleRemoveAll = () =>{
        this.props.onItemSelectionRemove(this.props.items);        
    }

    getBadge = (item) => {
        return (
            <span className="selected-item-badge ms-md3"  key={item.key}>
               
                <span className="selected-item-badge-text"  title={(item.label ? item.label + ": " : "") + item.value}>
                {item.label && <span className="badge-label">{item.label + ": "}</span>}
					{item.value}
                </span>     
                <span className="selected-item-badge-btn">
                    <IconButton
                        className={`itemid${item.id}`}
                        iconProps={{ iconName: 'Cancel' }}
                        title={this.props.intl.formatMessage({
							id: "common.remove",
							defaultMessage: "Remove"
						})}
						aria-label={
							`${item.label ? item.label + ": " : ""} ${item.value} ` +
							this.props.intl.formatMessage({
								id: "common.SearchResult.removeSelectedItem",
								defaultMessage: ", Click enter to remove it."
							})
						}              
                        onClick={() => this.handleRemove(item)}
                    />
                </span>          
            </span>
        )
    }

    render() {
        return (
            <div className="selected-items">
                <div className="selected-items-header">Selected Items:
                    <a className="remove-all-button" onClick={()=> this.handleRemoveAll()} href="javascript:void(0);">Remove All</a>
                </div>
                {
                    this.props.items && this.props.items.map((item) => {
                        return this.getBadge(item);
                    })
                }
            </div>
        );
    }
}

SelectedItemBadge.propTypes = propTypes;

export default injectIntl(SelectedItemBadge);
