import {makeActionCreator} from '../../../core/utility';
export const GET_OEM_PARTNUMBER_MAPPING = "artools/components/OemPartNumberManagement/GET_OEM_PARTNUMBER_MAPPING";
export const getOrmPartNumberMapping = makeActionCreator(GET_OEM_PARTNUMBER_MAPPING, false, "siteId", "customerId","partNumber","searchFilter");

export const GET_PARTNUMBER_DETAILS = "artools/components/OemPartNumberManagement/GET_PARTNUMBER_DETAILS";
export const getPartNumberDetails = makeActionCreator(GET_PARTNUMBER_DETAILS, false, "siteId", "customerId", "itemId");

export const SET_OEM_PARTNUMBER_MAPPING = "artools/components/OemPartNumberManagement/SET_OEM_PARTNUMBER_MAPPING";
export const setOrmPartNumber = makeActionCreator(SET_OEM_PARTNUMBER_MAPPING, false, "oemPartNumberMapping");

export const SET_PARTNUMBER_DETAILS = "artools/components/OemPartNumberManagement/SET_PARTNUMBER_DETAILS";
export const setPartNumberDetails = makeActionCreator(SET_PARTNUMBER_DETAILS, false, "partNumberDetails");

export const SAVE_OEM_PARTNUMBER_MAPPING = "artools/components/OemPartNumberManagement/SAVE_OEM_PARTNUMBER_MAPPING";
export const saveOrmPartNumberMapping = makeActionCreator(SAVE_OEM_PARTNUMBER_MAPPING, false, "siteId", "customerId", "oemPartNumberMapping");

export const SET_SAVE_OEPRATION_COMPLETION_STATUS = "artools/components/OemPartNumberManagement/SET_SAVE_OEPRATION_COMPLETION_STATUS";
export const saveOperationCompletionStatus = makeActionCreator(SET_SAVE_OEPRATION_COMPLETION_STATUS, false, "saveOperationCompleted");

export const VALIDATE_PARTNUMBERS = "artools/components/OemPartNumberManagement/VALIDATE_PARTNUMBERS";
export const validatePartNumbers = makeActionCreator(VALIDATE_PARTNUMBERS, false, "itemIds");

export const SAVE_VALIDATE_PARTNUMBERS = "artools/components/OemPartNumberManagement/SAVE_VALIDATE_PARTNUMBERS";
export const saveValidatePartNumbers = makeActionCreator(SAVE_VALIDATE_PARTNUMBERS, false, "partNumberValidationResult");

export const EXPORT_OEM_PARTNUMBER_MAPPING = "artools/components/OemPartNumberManagement/EXPORT_OEM_PARTNUMBER_MAPPING";
export const exportOemPartNumberMapping = makeActionCreator(EXPORT_OEM_PARTNUMBER_MAPPING, false, "siteId", "customerId");
