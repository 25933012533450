import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as OrderLineItemActions from './OrderLineItem.actions';

export const orderLineItemDetails=createReducer(null,{
  [OrderLineItemActions.SET_ORDER_LINE_ITEM_DETAILS](state,{orderLineItemDetails}){
    return fromJS(orderLineItemDetails)
  }
})

export const orderLineItemOrderTypes=createReducer(null,{
  [OrderLineItemActions.SET_ORDER_LINE_ITEM_ORDERTYPE](state,{orderLineItemOrderTypes}){
    return fromJS(orderLineItemOrderTypes)
  }
})