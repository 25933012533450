import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox, nullRender } from 'office-ui-fabric-react';
import { getTpiOrgs } from './ThirdPartyList.actions';
import { tpiOrgsSelector } from './ThirdPartyList.selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ComboBoxStyle } from '../../constants';
import { TstAllowStatus } from '../../constants';

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    selectedTpi: PropTypes.string,
    errorMessage: PropTypes.string,
    customerId: PropTypes.string,
    handleSelect: PropTypes.func
}

class ThirdPartyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tpiOrgs: this.props.tpiOrgs   
        }
    }

    componentDidMount() {
        this.props.getTpiOrgs(this.props.customerId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customerId !== this.props.customerId && nextProps.customerId) {
            this.props.getTpiOrgs(nextProps.customerId);
            this.setState({ tpiOrgs: [] });
        }
        else {
            let val = nextProps.tpiOrgs ? Object.keys(nextProps.tpiOrgs).length : 0;
            val > 0 ? this.setState({ tpiOrgs: nextProps.tpiOrgs }) : this.setState({ tpiOrgs: [] });
        }
    }

    render = () => {
        if (!(this.props.customerId)) {
            return nullRender();
        }
        return (
            <ComboBox
                name="tpinstaller"
                label={this.props.label}
                required={this.props.isRequired}
                selectedKey={this.props.selectedTpi}
                errorMessage={this.props.errorMessage}
                options={this.state.tpiOrgs}
                onChange={this.props.handleSelect}
                autoComplete='off'
                useComboBoxAsMenuWidth={true} 
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
            />
        );
    }
}

const getOptions = (options) => {
    return options ? options.map(data => {
        return {
            key: data.key.toString(),
            text: data.text,
            disabled: !TstAllowStatus.includes(data.TSTStatusId.toString()),
        }
    }) : options;
}
const mapStateToProps = (state) => {
    return {
        tpiOrgs: getOptions(tpiOrgsSelector(state))
  
    };
}

const mapDispatchToProps = (dispatch) => ({
    getTpiOrgs(customerId) {
        dispatch(getTpiOrgs(customerId))
    } 
});

ThirdPartyList.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ThirdPartyList));