import { createReducer } from '../../core/utility';
import {SET_ARSITES, SET_CLAIMS, SET_NAVIGATION_MENU, SET_USER_ROLES,  SET_ROLES_MAPPING, SET_CONTACT_EMAIL} from '../actions';
import { fromJS } from 'immutable';


export const arSites = createReducer(null, {
    [SET_ARSITES](state, { sites }) {
        return fromJS(sites);
    }
});

export const userClaims = createReducer(null, {
    [SET_CLAIMS](state, { claims }) {
        return fromJS(claims);
    }
});
export const userRoles = createReducer(null, {
    [SET_USER_ROLES](state, { roles }) {
        return fromJS(roles);
    }
});
export const rolesMapping = createReducer(null, {
    [SET_ROLES_MAPPING](state, { rolesMapping }) {
        return fromJS(rolesMapping);
    }
});

export const navigationMenu = createReducer(null, {
    [SET_NAVIGATION_MENU](state, { navigationMenu }) {
        return fromJS(navigationMenu);
    }
});

export const contactEmail = createReducer(null, {
    [SET_CONTACT_EMAIL](state, { contactEmail }) {
        return fromJS(contactEmail);
    }
});