import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as SearchOrderActions from './SearchOrder.actions';

export const searchOrderResult=createReducer(null,{
  [SearchOrderActions.SET_SEARCH_ORDER_RESULT](state,{orders}){
    return fromJS(orders)
  } 
})

export const searchOrderCriteria=createReducer(null,{ 
  [SearchOrderActions.SAVE_SEARCH_ORDER_CRITERIA](state,{searchCriteria}){
    return fromJS(searchCriteria)
  }
})