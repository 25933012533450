import { take, put, call, apply } from 'redux-saga/effects';
import fileDownload  from 'react-file-download';
import {default as loadashMap} from 'lodash/map'; 
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import * as searchAgreementActions from './SearchAgreement.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';
import uuidv4 from 'uuid/v4';

const getSearchRequest = (searchFilter) => {
    let queryParams = "";   

    queryParams = queryParams + (searchFilter.IsActiveAgreement ? "&IsActiveAgreement=" + searchFilter.IsActiveAgreement : "");
    queryParams = queryParams + (searchFilter.AgreementNumber ? "&SearchString=" + searchFilter.AgreementNumber : "");
    queryParams = queryParams + (searchFilter.PageNo ? "&PageNo=" + searchFilter.PageNo : "");
    queryParams = queryParams + (searchFilter.PageSize ? "&PageSize=" + searchFilter.PageSize : "");
   
    return queryParams;
} 

const getExportQueryParam = (agreementIds) => {
    let queryParams = loadashMap(agreementIds, (item) => {
        return "AgreementIds=" + item.AgreementNo;
    }).join('&')
    return queryParams;
}

export function* searchAgreementResultSaga() {
    while (true) {
        try {
            const { agreementFilter } = yield take(searchAgreementActions.GET_SEARCH_AGREEMENT_RESULT);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl,{ Config : {url: `${config.web_api_path}/api/coa/v1/customer/${agreementFilter.CustomerId}/licenses?${getSearchRequest(agreementFilter)}`, method:'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(searchAgreementActions.setSearchAgreementResult(data));
            } 
        } catch (error) {
            yield put(throwException("SearchAgreementResultSaga agreement details error: " + error));
        }
    }
}

export function* exportAgreementResultSaga() {
    while (true) {
        try {
            const { customerId, selectedItems } = yield take(searchAgreementActions.EXPORT_AGREEMENTS_RESULT);
           
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${customerId}/licenses/report?${getExportQueryParam(selectedItems)}`, method:'GET'},SagaHandleResponse :{ HandleResponse : false }});
            if (response.status === 200) {
                response.blob().then(data => {
                    fileDownload(data, `Agreements_Report_${new Date().toDateString().split(" ").join("_")}.xlsx`);
                });
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to generate report"] }));
            }
        } catch (error) {
            console.log("exportVerifyLicenseSaga error: " + error);
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Internal Error Occurred, Failed to generate verify license results."] }));
        }
    }
}

