import { createReducer } from '../../../core/utility';
import { fromJS } from 'immutable';
import { SET_VALIDATE_SHIPMENTID, SET_CUSTOMERTYPES } from './ShipmentHeader.actions';


export const isDuplicateShipmentId = createReducer(null, {
    [SET_VALIDATE_SHIPMENTID](state, { isDuplicateShipmentId }) {
        return fromJS(isDuplicateShipmentId);
    }
});

export const customerTypes = createReducer(null, {
    [SET_CUSTOMERTYPES](state, { customerTypes }) {
        return fromJS(customerTypes);
    }
});