import React, { Component } from 'react';
import './SearchResult.scss'
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Pager } from '../../../core/components/Pagination';
import { Iterator } from '../../../core/components';
import SearchFilters from './SearchFilters';
import FilterBadge from './FilterBadge';
import { ComboBox, Link, Checkbox, Announced } from 'office-ui-fabric-react';
import { ComboBoxStyle } from '../../../../app/common/constants';
import { SelectedItemBadge } from './SelectedItemBadge';
import { TabIndexes } from '../../../common/constants';

const propTypes = {
    searchInputs: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    onItemClick: PropTypes.func,
    onItemsChange: PropTypes.func, //this enables selection and select all option
    totalCount: PropTypes.number.isRequired,
    filters: PropTypes.array,
    selected: PropTypes.object,
    sortColumns: PropTypes.array,
    ResultComponent: PropTypes.func, //this component if it knows how to render all the items 
    ResultIteratorComponent: PropTypes.func, //this component if it only knows how to render one item
    handleChange: PropTypes.func,
    handleExport: PropTypes.func,
    hideResultHeader: PropTypes.any
}

const sortTypes = [
    {
        key: "desc",
        text: "Descending"
    },
    {
        key: "asc",
        text: "Ascending"
    }
]

export class SearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterShown: this.props.filters && this.props.filters.length > 0,
            selected: this.props.selected ? this.props.selected : this.getDefaultState(),
            filters: this.props.filters
        }
        this.hideFilterInput = React.createRef();
        this.showFilterInput = React.createRef();
    }

    getDefaultState = () => {
        return {
            page: 1,
            pageSize: 10,
            sort: "desc",
            sortBy: "",
            filters: [],
            items: []
        };
    }


    componentWillReceiveProps(newProps) {
        let filters = newProps.filters;

        if (this.state.filterToSkip && newProps.selected) {
            let index = filters.findIndex(item => item.field === this.state.filterToSkip.field);
            let selectedFilter = newProps.selected.filters.find(item => item.field === this.state.filterToSkip.field);
            if (selectedFilter && selectedFilter.items.length > 0 && index > -1) {
                filters[index] = this.state.filterToSkip;
            }
        }
        this.setState({
            selected: newProps.selected ? newProps.selected : this.getDefaultState(),
            filters: filters
        });
    }

    onPageChange = (page, pageSize) => {
        if (page === this.state.selected.page && pageSize === this.state.selected.pageSize) {
            return;
        }
        this.setState({
            page: page,
            pageSize: pageSize
        });
        this.props.handleChange(this.getSearchInput(page, pageSize, this.state.selected.sort,
            this.state.selected.sortBy,
            this.state.selected.filters));
    }

    onFilterChange = (filter) => {
        let filters = this.state.selected.filters;
        var index = filters.findIndex(item => item.field === filter.field);
        if (index > -1) {
            filters[index] = filter;
        }
        else {
            filters.push(filter);
        }
        let selected = this.state.selected;
        selected.filters = filters;
        selected.page = 1;
        let filterToSkip = this.state.filters.find(item => item.field === filter.field);

        this.setState({ selected: selected, filterToSkip: filterToSkip });

        if (filters.length > 0) {
            this.props.handleChange(this.getSearchInput(selected.page,
                this.state.selected.pageSize,
                this.state.selected.sort,
                this.state.selected.sortBy, filters));
        }
    }

    onFilterBadgeChange = (filters, changedfilter) => {
        let selected = this.state.selected;
        selected.filters = filters;
        selected.page = 1;
        let filterToSkip = this.state.filters.find(item => item.field === changedfilter.field);
        this.setState({ selected: selected, filterToSkip: filterToSkip });
        if (filters.length > 0) {
            this.props.handleChange(this.getSearchInput(selected.page,
                selected.pageSize,
                selected.sort,
                selected.sortBy, selected.filters));
        }
    }

    handleExport = () => {
        this.props.handleExport(this.getSearchInput(this.state.selected.page,
            this.state.selected.pageSize,
            this.state.selected.sort,
            this.state.selected.sortBy, this.state.selected.filters));
    }

    onSortOptionChange = (sortOption) => {
        let selected = this.state.selected;
        selected.sort = sortOption.sort;
        selected.sortBy = sortOption.sortBy;
        selected.page = 1;
        this.setState({
            selected: selected
        });
        this.props.handleChange(this.getSearchInput(selected.page,
            this.state.selected.pageSize, selected.sort,
            selected.sortBy, this.state.selected.filters));
    }

    handleClearFilters = () => {
        let selected = this.state.selected;
        selected.filters = [];
        selected.page = 1;

        this.setState({ selected: selected });
        this.props.handleChange(this.getSearchInput(selected.page,
            this.state.selected.pageSize,
            this.state.selected.sort,
            this.state.selected.sortBy, selected.filters));
    }

    getSearchInput = (page, pageSize, sortType, sortBy, filters) => {
        var searchInput = {
            page: page,
            pageSize: pageSize,
            sort: sortType,
            sortBy: sortBy
        };
        var selected = [];
        filters.forEach(filter => {
            var selectedItems = filter.items.filter(item => item.isSelected);
            if (selectedItems.length > 0) {
                selected.push({
                    title: filter.title,
                    field: filter.field,
                    items: selectedItems
                })
            }
        })
        searchInput.filters = selected;
        return searchInput;
    }

    mapSelectedFilters = (filters, selectedFilters) => {
        filters.forEach(filter => {
            let selected = selectedFilters && selectedFilters.find(item => item.field === filter.field);
            if (selected) {
                filter.items.forEach(item => {
                    let selectedFilterItem = selected.items.find(x => x.value === item.value);
                    item.isSelected = selectedFilterItem ? true : false;
                })
            }
            else {
                filter.items.forEach(item => {
                    item.isSelected = false;
                })
            }
        });
        return filters;
    }

    getClassNameForSearchResult = (hasFilter) => {
        return hasFilter ? "ms-Grid-col ms-md9" : "ms-Grid-col ms-md12";
    }

    getClassNameForSearchResultStatus = (noOfRecords) => {
        return noOfRecords > 0 ? "search-result-status" : "no-search-result-status";
    }

    appendSearchCriteria = (key, label, value) => {
        return <span role="alert" aria-live='assertive' key={key}>
            {
                key !== 0 ? <span className="label">{", "}</span> : null
            }
            <span className="label">{label + ": "}</span>
            <span className="criteria-value">{value}</span>
        </span>
    }

    onHideFilter = () => {
      this.setState({
            isFilterShown: !this.state.isFilterShown
        }, this.handlefliterfocus(!this.state.isFilterShown)
        )
    }

    handlefliterfocus = (isFilterShown) => {
        setTimeout(()=>{
        if (isFilterShown) {
            this.hideFilterInput && this.hideFilterInput.current && this.hideFilterInput.current.focus();
        }
        else {
            this.showFilterInput && this.showFilterInput.current && this.showFilterInput.current.focus();
        }
    },50)
    }


    getSortOptions = (sortColumns) => {
        let sortOptions = [];
        sortColumns.forEach(column => {
            sortTypes.forEach(sortType => {
                let sortOption = {
                    key: column.key + sortType.key,
                    text: column.text + ": " + sortType.text,
                    sortBy: column.key,
                    sort: sortType.key
                }
                sortOptions.push(sortOption);
            })

        });
        return sortOptions;
    }

    onItemSelectionRemove = (items) => {
        items.forEach(item => {
            item.selected = !item.selected;
        })
        this.props.onItemsChange(this.getSelectedItems(items));
    }

    onSelectAllChange = (e, checked) => {
        let items = this.props.items;
        items.forEach(item => {
            item.selected = checked;
        })
        this.props.onItemsChange(this.getSelectedItems(items));
    }

    getSelectedItems = (itemsToUpdate) => {
        let items = this.props.selected.items;

        let itemsToRemove = itemsToUpdate.filter(item => !item.selected);
        itemsToRemove.forEach(itemToRemove => {
            items = items.filter(item => item.key !== itemToRemove.key);
        });

        let itemsToAdd = itemsToUpdate.filter(item => item.selected);
        itemsToAdd.forEach(item => {
            if (!items.find(existing => existing.key === item.key)) {
                items.push(item);
            }
        });
        return items;
    }

    renderAnnounced = (message) => {             
        return (
            <Announced message={message} />
        );
    }

    render() {
        const { ResultComponent } = this.props;
        return (
            <div className="search-result">
                <div className="ms-Grid-row">
                    {
                        this.props.hideResultHeader ? null :
                            <div className="ms-Grid-col ms-md12 ms-sm12">
                                <div className="search-result-status" role="presentation" aria-live={this.props.totalCount === 0 ?"assertive":"off"} aria-label={this.props.totalCount + " Results Found"}> {this.props.totalCount + " Results Found"} </div>
                                {/* {this.renderAnnounced(this.props.totalCount + " Results Found from render")} */}
                            </div>
                    }
                    {
                        this.props.searchInputs && this.props.searchInputs.length > 0 ?
                            <div className="ms-Grid-col ms-md12 ms-sm12">
                                <div className="search-input-paramters">
                                    {
                                        this.props.searchInputs && this.props.searchInputs.map((item, index) => {
                                            return this.appendSearchCriteria(index, item.label, item.value);
                                        })
                                    }
                                </div>
                            </div> : null
                    }
                </div>
                {
                    this.state.selected.filters && this.state.selected.filters.length > 0 ?
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-md12 ms-sm12">
                                <FilterBadge filters={this.state.selected.filters} onFilterBadgeChange={this.onFilterBadgeChange}></FilterBadge>
                            </div>
                        </div> : null
                }
                {
                    this.state.selected.items && this.state.selected.items.length > 0 ?
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-md12 ms-sm12">
                                <SelectedItemBadge items={this.state.selected.items} onItemSelectionRemove={this.onItemSelectionRemove}></SelectedItemBadge>
                            </div>
                        </div> : null
                }
                {
                    this.props.totalCount > 0 ?
                        <div className="ms-Grid-row">
                            {
                                this.state.isFilterShown ?
                                    <div className="ms-Grid-col ms-md3 ms-sm12">
                                        <div className="search-filters">
                                            <div className="search-filters-header">
                                                <div className="search-filters-header-text">
                                                    <Link componentRef={this.hideFilterInput} title={this.props.intl.formatMessage({ id: 'common.hideFilters', defaultMessage: 'Hide Filters' })} className="hover-content" onClick={this.onHideFilter}>
                                                        <i className="ms-Icon ms-Icon--Filter"></i>
                                                        {this.props.intl.formatMessage({ id: 'common.Filters', defaultMessage: 'Filters' })}
                                                    </Link>
                                                </div>
                                                <div className="search-filters-header-actions">
                                                    <a href="javascript:void(0);" role="button" aria-label="Clear button. Click to remove the selected filters."
                                                        onClick={this.handleClearFilters}>
                                                        {this.props.intl.formatMessage({ id: 'common.Clear', defaultMessage: 'Clear' })}
                                                    </a>
                                                </div>
                                            </div>
                                            <SearchFilters filters={this.mapSelectedFilters(this.state.filters, this.state.selected.filters)}
                                                onFilterChange={this.onFilterChange}></SearchFilters>
                                        </div>
                                    </div> : null
                            }

                            <div className={this.getClassNameForSearchResult(this.state.isFilterShown)}>
                                <div className="action-buttons">
                                    {
                                        this.props.filters && this.props.filters.length > 0 ?
                                            this.state.isFilterShown ? null :
                                                <div className="search-filters-show-header">
                                                    <div className="search-filters-header-text">
                                                        <Link componentRef={this.showFilterInput} title={this.props.intl.formatMessage({ id: 'common.showFilters', defaultMessage: 'Show Filters' })} className="hover-content" onClick={this.onHideFilter}>
                                                            <i className="ms-Icon ms-Icon--Filter"></i>
                                                            {this.props.intl.formatMessage({ id: 'common.Filters', defaultMessage: 'Filters' })}
                                                        </Link>
                                                    </div>
                                                </div> : null
                                    }
                                    <div className="action-buttons-right ms-Grid-col ms-sm12">
                                        {
                                            this.props.handleExport ? this.props.isDisable ?  <a
                                            disabled = "true" 
                                            role="button"
                                            tabIndex = {TabIndexes.InOrder}
                                            aria-label="Export disabled"                                                  
                                            className='ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 zero-padding'>
                                            <i className="ms-Icon ms-Icon--Download"></i>
                                            {this.props.intl.formatMessage({ id: 'common.Export', defaultMessage: 'Export' })}
                                        </a>:
                                                <a tabIndex={TabIndexes.InOrder}
                                                    role="button"
                                                    onClick={this.handleExport}                                                    
                                                    className='ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 zero-padding'>
                                                    <i className="ms-Icon ms-Icon--Download"></i>
                                                    {this.props.intl.formatMessage({ id: 'common.Export', defaultMessage: 'Export' })}
                                                </a> : null
                                        }
                                        {
                                            this.props.sortColumns ?
                                                <ComboBox
                                                    name="arOrderNumberSearchType"
                                                    aria-label="SortBy"
                                                    label="SortBy"
                                                    required={!this.state.isAdvanceSearch}
                                                    selectedKey={this.state.selected.sortBy + this.state.selected.sort}
                                                    options={this.getSortOptions(this.props.sortColumns)}
                                                    autoComplete='off'
                                                    className='ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 zero-padding'
                                                    onChange={(event, data) => this.onSortOptionChange(data)} /> : null
                                        }
                                    </div>
                                </div>


                                <div className="search-result-data">
                                    {
                                        this.props.onItemsChange ?
                                            <div>
                                                <Checkbox onChange={this.onSelectAllChange}
                                                    label={this.props.intl.formatMessage({ id: 'common.SelectAll', defaultMessage: 'Select All' })} />
                                            </div> : null
                                    }
                                    {
                                        this.props.ResultComponent ?
                                            <ResultComponent onItemClick={this.props.onItemClick} items={this.props.items}></ResultComponent> : null
                                    }
                                    {
                                        this.props.ResultIteratorComponent ?
                                            <Iterator onItemClick={this.props.onItemClick} Component={this.props.ResultIteratorComponent} items={this.props.items}></Iterator> : null
                                    }
                                    <div className="ms-Grid-col ms-md12 ms-sm12 pager-data">
                                        <Pager pageNo={this.state.selected.page} itemsCount={this.props.totalCount} pageSize={this.state.selected.pageSize}
                                            onPageChange={this.onPageChange} />
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        );
    }
}

SearchResult.propTypes = propTypes;

export default injectIntl(SearchResult);
