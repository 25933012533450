export const ComboBoxStyle ={
    OptionStyle:{
        optionText: {
        wordBreak: 'break-word',
        whiteSpace: 'normal', 
    }
},
   errorStyle:{
    errorMessage: {
   fontSize:"12px",
   }
  }
}