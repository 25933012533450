import {makeActionCreator} from '../../../core/utility';

export const GET_BARCODE_DESCRIPTIONS = "artools/components/Barcode/GET_BARCODE_DESCRIPTIONS";
export const getBarcodeDescriptions = makeActionCreator(GET_BARCODE_DESCRIPTIONS, false);


export const SET_BARCODE_DESCRIPTIONS = "artools/components/Barcode/SET_BARCODE_DESCRIPTIONS";
export const setBarcodeDescriptions = makeActionCreator(SET_BARCODE_DESCRIPTIONS, false,'barcodeDescriptions');


export const GET_BARCODE_MAPPING = "artools/components/Barcode/GET_BARCODE_MAPPING";
export const getBarcodeMapping = makeActionCreator(GET_BARCODE_MAPPING, false,'siteId','customerId');


export const SET_BARCODE_MAPPING = "artools/components/Barcode/SET_BARCODE_MAPPING";
export const setBarcodeMapping = makeActionCreator(SET_BARCODE_MAPPING, false,'barcodeMapping');


export const SAVE_BARCODE_MAPPING = "artools/components/Barcode/SAVE_BARCODE_MAPPING";
export const saveBarcodeMapping = makeActionCreator(SAVE_BARCODE_MAPPING, false ,'arSiteId','customerId','barcodeId');


