import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as oemPartNumberManagementAction from './OemPartNumberManagement.actions';

export const oemPartNumberMapping=createReducer(null,{
  [oemPartNumberManagementAction.SET_OEM_PARTNUMBER_MAPPING](state,{oemPartNumberMapping}){
    return fromJS(oemPartNumberMapping)
  }
})

export const partNumberDetails=createReducer(null,{
  [oemPartNumberManagementAction.SET_PARTNUMBER_DETAILS](state,{partNumberDetails}){
    return fromJS(partNumberDetails)
  }
})

export const saveOperationCompleted=createReducer(null,{
  [oemPartNumberManagementAction.SET_SAVE_OEPRATION_COMPLETION_STATUS](state,{saveOperationCompleted}){
    return fromJS(saveOperationCompleted)
  }
})

export const partNumberValidationResult=createReducer(null,{
  [oemPartNumberManagementAction.SAVE_VALIDATE_PARTNUMBERS](state,{partNumberValidationResult}){
    return fromJS(partNumberValidationResult)
  }
})
