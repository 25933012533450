import { makeActionCreator } from "../../../core/utility";

export const SET_ORDER_LINE_ITEM_DETAILS = "order/components/OrderLineItem/SET_ORDER_LINE_ITEM_DETAILS";
export const setOrderLineItemDetails = makeActionCreator(SET_ORDER_LINE_ITEM_DETAILS, false, "orderLineItemDetails");

export const GET_ORDER_LINE_ITEM_ORDERTYPE ="order/components/OrderLineItem/GET_ORDER_LINE_ITEM_ORDERTYPE";
export const getOrderLineItemOrderType = makeActionCreator(GET_ORDER_LINE_ITEM_ORDERTYPE, false,"SoldToType");
export const SET_ORDER_LINE_ITEM_ORDERTYPE = "order/components/OrderLineItem/SET_ORDER_LINE_ITEM_ORDERTYPE";
export const setOrderLineItemOrderType = makeActionCreator(SET_ORDER_LINE_ITEM_ORDERTYPE, false, "orderLineItemOrderTypes");

