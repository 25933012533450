import { fromJS } from 'immutable';
import { createReducer } from '../../utility';
import { SET_USER_INFO, SET_AUTH_PROVIDER } from './authz.actions';

export const userInfo = createReducer(null, {
    [SET_USER_INFO](state, { userInfo }) {
        return fromJS(userInfo);
    }
});

export const authProvider = createReducer(null, {
    [SET_AUTH_PROVIDER](state, { authProvider }) {
        return fromJS(authProvider);
    }
});
