import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from '../../../core/components/AutoComplete/AutoComplete'
import { getSoldToOrgs, setSoldToOrgs } from './SoldToOrgControl.actions';
import { soldToOrgsSelector } from './SoldToOrgControl.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { OrderConstants } from '../../../../app/order/constants';

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    selectedText: PropTypes.string,
    errorMessage: PropTypes.string,
    orderType: PropTypes.string,
    soldToType: PropTypes.string,
    onSelect: PropTypes.func   
}

class SoldToOrgControl extends Component { 

    constructor(props) {
        super(props);        

        this.state = {
            selectedText: this.props.selectedText
        }
        if(!this.props.selectedText){
            let defaultValue = this.getDefaultSoldTo(this.props.orderType, this.props.soldToType);
            if(defaultValue){
                this.props.onSelect(null);
            }            
        }
    }

    componentWillReceiveProps(nextProps) {   
        let selectedText = nextProps.selectedText;
        if(!selectedText){
            let defaultValue = this.getDefaultSoldTo(nextProps.orderType, nextProps.soldToType);
            if(defaultValue){
                this.props.setSoldToOrgs([defaultValue]);
                this.props.onSelect(this.getDefaultSoldTo(nextProps.orderType, nextProps.soldToType));    
            }  
            selectedText = defaultValue? defaultValue.displayText: "";
        }
        this.setState({
            selectedText: selectedText
        })
    }

    fetchData = async (searchText) => {      
        this.props.getSoldToOrgs(this.props.soldToType, searchText);     
    }

    getDefaultSoldTo = (orderType, soldToType) =>{
        let defaultSoldTo=null;
        if (orderType === OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE) {
            if (soldToType === OrderConstants.SOLDTOTYPE_DSP_VALUE) {               
                defaultSoldTo={
                    key: "4225",
                    displayText: "MICROSOFT CORPORATION DSP [0000055543]",
                    CustomerId: "0000055543",
                    IsCustomerOnHold: false        
                };
            }
            else if (soldToType === OrderConstants.SOLDTOTYPE_EMB_VALUE) {
                defaultSoldTo={
                    key: "6775",
                    displayText: "MSFT EMBEDDED DISTRIBUTOR [0000063943]",
                    CustomerId: "0000063943",
                    IsCustomerOnHold: false
                };
            }           
        }
        return defaultSoldTo;
    }

    render = () => {     
       
        return (
            <AutoComplete
                tabindex="0" 
                name="SoldToId"
                aria-label="SoldToId"
                label={this.props.label}
                isRequired={this.props.isRequired}
                placeholder={this.props.placeholder}
                defaultDisplayText={this.state.selectedText}
                errorMessage={this.props.errorMessage}
                onSearch={(searchText) => { this.fetchData(searchText) }}
                items={this.props.soldToOrgs ? this.props.soldToOrgs : []}
                onSelect={this.props.onSelect}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        soldToOrgs: soldToOrgsSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getSoldToOrgs(channelType, searchText) {
        dispatch(getSoldToOrgs(channelType, searchText))
    },
    setSoldToOrgs(items) {
        dispatch(setSoldToOrgs(items))
    }
});

SoldToOrgControl.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SoldToOrgControl));