import { makeActionCreator } from "../../../core/utility";

export const GET_SEARCH_AGREEMENT_RESULT ="license/components/SearchAgreement/GET_SEARCH_AGREEMENT_RESULT";
export const getSearchAgreementResult = makeActionCreator(GET_SEARCH_AGREEMENT_RESULT, false,"agreementFilter");

export const SET_SEARCH_AGREEMENT_RESULT = "license/components/SearchAgreement/SET_SEARCH_AGREEMENT_RESULT";
export const setSearchAgreementResult = makeActionCreator(SET_SEARCH_AGREEMENT_RESULT, false, "agreements");

export const EXPORT_AGREEMENTS_RESULT = "license/components/SearchAgreement/EXPORT_AGREEMENTS_RESULT";
export const exportAgreementResult = makeActionCreator(EXPORT_AGREEMENTS_RESULT, false, "customerId", "selectedItems");

export const SAVE_AGREEMENT_CRITERIA = "license/components/SearchAgreement/SAVE_AGREEMENT_CRITERIA";
export const saveAgreementCriteria = makeActionCreator(SAVE_AGREEMENT_CRITERIA, false, "agreementCriteria");



