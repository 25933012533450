import * as Msal from "@azure/msal-browser";
import { Logger } from "./Logger";
import ReactAI from "react-appinsights";
import { MsalAccountInfo } from "../Auth.models";

const appInsights = ReactAI.ai();
const hackToSupportLive = "Renew token Expected state:";
const SELECT_ACCOUNT = "select_account";

export class MsalAuthProvider {
	constructor(authProviderConfig) {
		this.config = authProviderConfig;
		this.clientApplication = new Msal.PublicClientApplication({
           auth : {
			   clientId : authProviderConfig.clientID,
			   authority: authProviderConfig.authority ? authProviderConfig.authority : null,
			   redirectUri:  authProviderConfig.redirectUri,
			   navigateToLoginRequestUrl: true,
		   },
		   cache:{
			cacheLocation: authProviderConfig.persistLoginPastSession ? Msal.BrowserCacheLocation.LocalStorage : Msal.BrowserCacheLocation.SessionStorage,
			storeAuthStateInCookie: true,
		   },
		   system: {
			   loggerOptions :	{
				loggerCallback: 
					(level, message, containsPii) => {
				   appInsights.trackTrace(`msal Info:  ${message}`);	
				   const index = message.indexOf(hackToSupportLive);
				   if (index > -1) {
					   const neededState = message.substring(index + 28);
					   appInsights.trackTrace(`msal Info:  ${neededState}`);
				   }
				},
				logLevel: Msal.LogLevel.Verbose, 
				piiLoggingEnabled: true 
			   }
		   }
		 }
		);
		this.cacheLocation = this.clientApplication.config.cache.cacheLocation; 
	}

	handleRedirectPromise = async () => {
	  let authResult =   await this.clientApplication.handleRedirectPromise();
	  return authResult;
   }

	handleRedirect = (authResult) => {
		if(authResult){
			this.saveUserInfo(authResult.accessToken, authResult.idToken, authResult.account);
			this.clientApplication.setActiveAccount(authResult);
			return authResult.accessToken;
		}
		let account = this.getAccount();
			if(account){
				this.clientApplication.setActiveAccount(account);
			    this.acquireTokens(account);
	          }	
    	}
   
	login = async () => {
		 if (!this.getAccount()) {
			await this.clientApplication.loginRedirect({scopes: this.config.scopes, prompt: SELECT_ACCOUNT});
	     }
	}

	logout = () => {
		const account = this.clientApplication.getAccountByUsername(this.userInfo.user.username);
		this.clientApplication.logoutRedirect({account: account});
	};


	getUserInfo = () => {
		return this.userInfo;
	};

	getTokenWithRefresh = () => {
		let account = this.getAccount();
		if(account){
	  	return	this.clientApplication.acquireTokenByRefreshToken({account: account}).then((result) => {
				if(result){
				    return result.idToken;
				}
			}).catch((error) => {
				Logger.error(`refresh token error; ${error}`);
				this.acquireTokens(account);
			 });
		}
	};

	acquireTokens = (account) => {
		let _this = this;
		if(account){
		_this.clientApplication.acquireTokenSilent({
			scopes : _this.config.scopes, account: account }).then((authResult) => {
				 if(authResult != null){
				       _this.saveUserInfo(authResult.accessToken, authResult.idToken, authResult.account);
					   _this.clientApplication.setActiveAccount(authResult);
			        	return authResult.idToken;
				 }
			 }).catch((tokenSilentError) => {
				Logger.error(`token silent error acquireTokenSilent; ${tokenSilentError}`);
				_this.clientApplication.acquireTokenRedirect({scopes : _this.config.scopes,  prompt: SELECT_ACCOUNT}); 
			 }
		);
	};
 }

	saveUserInfo = (accessToken, idToken, msalUser) => {
		const userDetails = {
			jwtAccessToken: accessToken,
			jwtIdToken: idToken,
			user:  new MsalAccountInfo(msalUser),
		};

		this.userInfo = userDetails;
		if (this.userInfoChangedCallback) {
			this.userInfoChangedCallback(userDetails);
		}
	};

	getAccount = () => {
		let allAccounts = this.clientApplication.getAllAccounts();
		let activeAccount  = this.clientApplication.getActiveAccount()  
		                       ? this.clientApplication.getActiveAccount() 
							   :  allAccounts.length > 0 ? allAccounts[0] : null;
		return activeAccount;
	}
}