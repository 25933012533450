
import { createReducer } from '../../../core/utility';
import { fromJS } from 'immutable';
import {
    SET_SHIPMENT_DASHBOARD_DATA
  } from './ShipmentDashboard.actions';

export const shipmentDashboardData = createReducer(null, {
    [SET_SHIPMENT_DASHBOARD_DATA](state, { shipmentDashboardData }) {
      return fromJS(shipmentDashboardData);
    },
  });
  