import { createSelector } from 'reselect';


export const userInfoSelector = createSelector(    
    state => state.app.get("userInfo"),
    userInfo=> userInfo ? userInfo.toJS() : userInfo
)

export const authProviderSelector = createSelector(    
    state => state.app.get("authProvider"),
    authProvider=> authProvider 
)

