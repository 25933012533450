import { createSelector } from 'reselect';


export const orderWizardDataSelector = createSelector(
    state => state.app.get("orderWizardData"),
    orderWizardData => orderWizardData? orderWizardData.toJS(): null
);

export const createdOrderSelector = createSelector(
    state => state.app.get("createdOrder"),
    createdOrder => createdOrder? createdOrder.toJS(): null
);


export const orderStepSelector = (orderStepKey) => createSelector(
    state => state.app.get("orderWizardData"),
    orderWizardData => {
        return orderWizardData ? orderWizardData.toJS().find(item=> item.key===orderStepKey) : orderWizardData;            
    }   
);