export const OrderLineItemDefaultDetails = (lineItemId,displayId) => {
  return {
    LineItemId: { value: lineItemId, text: '', error: '' },
    EndItem: { value: '', text: '', error: '' },
    ShipmentType: { value: '1', text:'COGS', error: '' },
    Quantity: { value: '', text: '', error: '' },
    ArlineItemNumber: { value: '', text:'', error: '' },
    LicensableItem: { value: '', text: '', error: '' },
    InactiveEndItem: { value: '', text:'', error: '' },
    LicenseId: { value: '', text: '', error: ''},
    IsCoaPrinted: {value: false, text: '', error: ''},
    isEditMode:false,
    ClientTransactionType: 0,
    DisplayId : displayId,
    FirstElementRef: null
  };
}
