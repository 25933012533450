import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as searchLicensablesActions from './SearchLicensables.actions';

export const searchLicensablesResult = createReducer(null,{
  [searchLicensablesActions.SET_SEARCH_LICENSABLES_RESULT](state,{licensables}){
    return fromJS(licensables)
  }
})

