import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as LineItemActions from './Licensable.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { throwException } from '../../../core/components/Logger/Logger.actions';

const mapLicensableData = (data) => {
    if (!data)
        return data;
    let options = [];
    data && data.forEach(result => {
        options.push({
            key: result.LicenseId + result.LicensableId,
            text: result.LicenseId ? "(" + result.LicenseId + ")[" + result.LicensableId + "]" + result.ItemName : "[" + result.LicensableId + "]" + result.ItemName,
            licenseId: result.LicenseId,
            licensableItemId: result.LicensableId
        })
    })
    return options;
}

const getFilterUrl = (customerId, endItemId, lineItemType, compareDate, ChannelType) => {
    let filterUrl;
    if (customerId) {
        filterUrl = filterUrl ? filterUrl + "&customerId=" + customerId : "customerId=" + customerId;
    }

    if (endItemId) {
        filterUrl = filterUrl ? filterUrl + "&endItemId=" + endItemId : "endItemId=" + endItemId;
    }

    if (lineItemType) {
        filterUrl = filterUrl ? filterUrl + "&lineItemType=" + lineItemType : "lineItemType=" + lineItemType;
    }

    if (compareDate) {
        filterUrl = filterUrl ? filterUrl + "&compareDate=" + compareDate : "compareDate=" + compareDate;
    }

    if (ChannelType) {
        filterUrl = filterUrl ? filterUrl + "&ChannelType=" + ChannelType : "ChannelType=" + ChannelType;        
    }

    return filterUrl;
}

export function* lineItemLicensableSaga() {
    while (true) {
        try {
            const { customerId, endItemId, lineItemType, compareDate, ChannelType } = yield take(LineItemActions.GET_LINE_ITEM_LICENSABLE);
            const config = yield ApplicationConfig.config;
            const filterUrl = yield getFilterUrl(customerId, endItemId, lineItemType, compareDate, ChannelType);
            if (endItemId) {
                const response = yield call(InvokeUrl,{ Config : {url: `${config.web_api_path}/api/coa/licensablesenditems?${filterUrl}`, method:'GET'}});
                if (response.status === 200) {
                    const data = yield apply(response, response.json);
                    yield put(LineItemActions.setLineItemLicensable(`${customerId}${endItemId}`, mapLicensableData(data)));
                }
            }
        } catch (error) {
            yield put(throwException("Customers Saga error:" + error));
        }
    }
}