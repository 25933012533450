import { take, takeLatest, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as searchProductActions from './SearchProduct.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'
import uuidv4 from 'uuid/v4';

export function* productMasterInfoSaga() {
    while (true) {
        try {
            yield take(searchProductActions.GET_PRODUCT_MASTER_INFO);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/productmasterinfo`, method: 'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                var productMasterInfo = data && data.map((d) => {
                    return {
                        key: d.Key,
                        value: d.Value.map((i) => {
                            return {
                                key: i.Code,
                                text: i.Description+"("+ i.Code + ")"
                            };
                        })
                    };
                });
                yield put(searchProductActions.setProductMasterInfo(productMasterInfo));
            }
        } catch (error) {
            yield put(throwException("productMasterInfoSaga product details error: " + error));
        }
    }
}
const getSearchRequest = (productFilters) => {
    let queryParams = "";

    queryParams = queryParams + (productFilters.ProductType ? "ProductType=" + productFilters.ProductType : "");
    queryParams = queryParams + (productFilters.ProductNumber ? "&ProductNumber=" + productFilters.ProductNumber : "");
    queryParams = queryParams + (productFilters.ProductFamily ? "&ProductFamilyCode=" + productFilters.ProductFamily : "");
    queryParams = queryParams + (productFilters.Description ? "&Description=" + productFilters.Description : "");
    queryParams = queryParams + (productFilters.Language ? "&Language=" + productFilters.Language : "");
    queryParams = queryParams + (productFilters.OperatingSystem ? "&OperatingSystem=" + productFilters.OperatingSystem : "");
    queryParams = queryParams + (productFilters.DistributionType ? "&DistributionType=" + productFilters.DistributionType : "");
    queryParams = queryParams + (productFilters.Version ? "&Version=" + productFilters.Version : "");
    queryParams = queryParams + (productFilters.Status ? "&Status=" + productFilters.Status : "");
    queryParams = queryParams + (productFilters.PageNo ? "&PageNo=" + productFilters.PageNo : "");
    queryParams = queryParams + (productFilters.PageSize ? "&PageSize=" + productFilters.PageSize : "");
    return queryParams;
}
export function* productResultSaga() {
    while (true) {
        try {
            const { productFilters } = yield take(searchProductActions.GET_SEARCH_PRODUCT_RESULTS);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${productFilters.CustomerId}/enditems?` + getSearchRequest(productFilters), method :'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(searchProductActions.setSearchProductResults(data));
            } 
        } catch (error) {
            yield put(throwException("productResultSaga product details error: " + error))
        }
    }
}
export function* productFamilyValuesListener() {
    yield takeLatest(searchProductActions.GET_PRODUCT_FAMILY_VALUES, productFamilyValuesSaga);
}

function* productFamilyValuesSaga(input) {
    try {
        const { searchString } = yield (input);
        const config = yield ApplicationConfig.config;
        const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/productfamilycode?searchString=${searchString}`, method: 'GET'}});
        if (responseC.status === 200) {
            const data = yield apply(responseC, responseC.json);
            var productFamilyValues = data && data.map((d) => {
                return {
                    key: d.Key,
                    displayText: d.Value +"("+ d.Key + ")",
                };
            });
            yield put(searchProductActions.setProductFamilyValues(productFamilyValues));
        }
    } catch (error) {
        yield put(throwException("productFamilyValuesSaga product family error: " + error))
    }
}


