import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    title: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    onItemClick: PropTypes.func
}

const WizardNavItem = ({ title, status, onItemClick }) => {
    const getItemStyle = () => {
        switch (status) {
            case 1:
                return "not-started";
            case 2:
                return "in-progress";
            case 3:
                return "completed";
            case 4:
                return "error";
            default: 
                return "disabled";
        }
    }

    return (
        <div className={"wizard-nav-item-container " + getItemStyle()} aria-hidden ={!(status === 3 || status === 2)}>
            <div className="wizard-nav-item">
                <div className="nav-item-status">
                    {title}
                    {(status === 3 || status === 2) ?  <span className="hideProgress" aria-label = {` ${getItemStyle()}`}>{` ${getItemStyle()}`}</span> : null}
                </div>
            </div>
            <div className="status-container">
                <div className="round">
                    { status === 3 ? <i className="ms-Icon ms-Icon--Accept" ></i>: null} 
                </div>
            </div>
        </div>
    )
}

WizardNavItem.propTypes = propTypes;
export default WizardNavItem;