import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SearchFilter } from './SearchFilter';

const propTypes = {
    filters: PropTypes.array,
    onFilterChange: PropTypes.func
}

export class SearchFilters extends Component {  
          
    render() {        
        return (
            <React.Fragment>
                {
                    this.props.filters && this.props.filters.map((filter, index)=>{
                        return (
                            <SearchFilter tabIndex={index} key={filter.field} onFilterChange={this.props.onFilterChange} {...filter} />
                        )
                    })
                }
            </React.Fragment>           
        );
    }
}

SearchFilters.propTypes = propTypes;

export default injectIntl(SearchFilters);
