import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from '../../../core/components/AutoComplete/AutoComplete'
import { getCustomers } from './Customer.actions';
import { customerDataSelector } from './Customer.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    selectedText: PropTypes.string,
    errorMessage: PropTypes.string,
    onSelect: PropTypes.func 
}

class Customer extends Component { 

    fetchData = async (searchText) => {      
        this.props.getCustomers(searchText);     
    }

    render = () => {       
        return (
            <AutoComplete
                name="customer"
                label={this.props.label}
                disabled={this.props.disabled || false}
                isRequired={this.props.isRequired && !(this.props.disabled || false)}
                placeholder={this.props.placeholder}
                defaultDisplayText={this.props.selectedText}
                errorMessage={this.props.errorMessage}
                onSearch={(searchText) => { this.fetchData(searchText) }}
                items={this.props.customers ? this.props.customers : []}
                onSelect={this.props.onSelect}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        customers: customerDataSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getCustomers(searchText) {
        dispatch(getCustomers(searchText))
    }
});

Customer.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Customer));