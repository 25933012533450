import React from 'react';
import { injectIntl } from 'react-intl';
import { Label } from 'office-ui-fabric-react';
import OrderHeaderInfo from '../OrderHeader/OrderHeaderInfo';
import OrderLineItemsInfo from '../OrderLineItem/OrderLineItemsInfo';
import { TabIndexes } from '../../../common/constants';

const OrderDetailsInfo = (props) => {
    return (
        <div className='order-details-info'>     
            <div className="order-details-header">
                <OrderHeaderInfo {...props} />  
            </div>   
            <div className="order-details-lineitem">
                <OrderLineItemsInfo {...props} />   
            </div>           
            {props.Remark?
                <div className="order-details-section-title">
                    <h3>
                        {props.intl.formatMessage({ id: 'orderReview.Comments', defaultMessage: 'Comments' })}
                    </h3>
                    <div>
                        <Label name="CommentsValue" id="CommentsValue" className="labelValue">{props.Remark.value}</Label>
                    </div>
                </div> : null
            }

        </div>
    );
}

export default injectIntl(OrderDetailsInfo);