import * as React from "react";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { DayOfWeek } from "office-ui-fabric-react";
import { PropTypes } from "prop-types";
import { injectIntl } from "react-intl";
import { DatePicker } from "@fluentui/react";

const propTypes = {
	label: PropTypes.string,
	ariaLabel: PropTypes.string,
	isRequired: PropTypes.bool,
	allowTextInput: PropTypes.bool,
	selectedDate: PropTypes.instanceOf(Date),
	minDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	isOutOfBoundsErrorMessage: PropTypes.string,
	isRequiredErrorMessage: PropTypes.string,
	onSelectDate: PropTypes.func,
	disabled: PropTypes.bool
};

class DatePickerWithBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.onSelectDate = this.onSelectDate.bind(this);
		this.onFormatDate = this.onFormatDate.bind(this);
		this.state = {
			selectedDate: this.props.selectedDate,
			firstDayOfWeek: DayOfWeek.Monday
		};
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ selectedDate: nextProps.selectedDate });
	}

	onSelectDate = (date) => {
		this.setState({ selectedDate: date });
		this.props.onSelectDate(date);
	};

	onFormatDate = (date) => {
		return this.props.intl.formatDate(new Date(date));
	};
	onIconClick = (e) =>{
		let tds = document.getElementsByClassName("ms-DatePicker-day")
		tds && Object.keys(tds).forEach(key =>{
			tds[key] && tds[key].setAttribute("role", "presentation")
		})
	}
	render() {
		const {
			label,
			ariaLabel,
			isRequired,
			minDate,
			maxDate,
			isOutOfBoundsErrorMessage,
			invalidInputErrorMessage,
			isRequiredErrorMessage,
			allowTextInput,
			id,
			disabled,
			disableAutoFocus
		} = this.props;
		const { selectedDate, firstDayOfWeek } = this.state;

		const DayPickerStrings = {
			months: [
				this.props.intl.formatMessage({ id: "common.datePicker.monthsJan", defaultMessage: "January" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsFeb", defaultMessage: "February" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsMar", defaultMessage: "March" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsApr", defaultMessage: "April" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsMay", defaultMessage: "May" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsJun", defaultMessage: "June" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsJul", defaultMessage: "July" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsAug", defaultMessage: "August" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsSep", defaultMessage: "September" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsOct", defaultMessage: "October" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsNov", defaultMessage: "November" }),
				this.props.intl.formatMessage({ id: "common.datePicker.monthsDec", defaultMessage: "December" })
			],

			shortMonths: [
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsJan", defaultMessage: "Jan" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsFeb", defaultMessage: "Feb" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsMar", defaultMessage: "Mar" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsApr", defaultMessage: "Apr" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsMay", defaultMessage: "May" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsJun", defaultMessage: "Jun" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsJul", defaultMessage: "Jul" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsAug", defaultMessage: "Aug" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsSep", defaultMessage: "Sep" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsOct", defaultMessage: "Oct" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsNov", defaultMessage: "Nov" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortMonthsDec", defaultMessage: "Dec" })
			],

			days: [
				this.props.intl.formatMessage({ id: "common.datePicker.daysSun", defaultMessage: "Sunday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysMon", defaultMessage: "Monday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysTue", defaultMessage: "Tuesday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysWed", defaultMessage: "Wednesday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysThu", defaultMessage: "Thursday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysFri", defaultMessage: "Friday" }),
				this.props.intl.formatMessage({ id: "common.datePicker.daysSat", defaultMessage: "Saturday" })
			],

			shortDays: [
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysS", defaultMessage: "S" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysM", defaultMessage: "M" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysT", defaultMessage: "T" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysW", defaultMessage: "W" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysT", defaultMessage: "T" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysF", defaultMessage: "F" }),
				this.props.intl.formatMessage({ id: "common.datePicker.shortDaysS", defaultMessage: "S" })
			],

			goToToday: this.props.intl.formatMessage({ id: "common.datePicker.goToToday", defaultMessage: "Go to today" }),
			prevMonthAriaLabel: this.props.intl.formatMessage({ id: "common.datePicker.prevMonthAriaLabel", defaultMessage: "Go to previous month" }),
			nextMonthAriaLabel: this.props.intl.formatMessage({ id: "common.datePicker.nextMonthAriaLabel", defaultMessage: "Go to next month" }),
			prevYearAriaLabel: this.props.intl.formatMessage({ id: "common.datePicker.prevYearAriaLabel", defaultMessage: "Go to previous year" }),
			nextYearAriaLabel: this.props.intl.formatMessage({ id: "common.datePicker.nextYearAriaLabel", defaultMessage: "Go to next year" }),
			prevYearRangeAriaLabel: this.props.intl.formatMessage({
				id: "common.datePicker.prevYearRangeAriaLabel",
				defaultMessage: "On click of previous, Year Range will be "
			}),
			nextYearRangeAriaLabel: this.props.intl.formatMessage({
				id: "common.datePicker.nextYearRangeAriaLabel",
				defaultMessage: "On click of next, Year Range will be "
			}),

			isRequiredErrorMessage: this.props.intl.formatMessage({ id: "common.datePicker.isRequiredErrorMessage", defaultMessage: "Field is required." }),

			invalidInputErrorMessage: this.props.intl.formatMessage({
				id: "common.datePicker.invalidInputErrorMessage",
				defaultMessage: "Invalid date format."
			})
		};
		let DayPickers = { ...DayPickerStrings };
		if (isOutOfBoundsErrorMessage) DayPickers.isOutOfBoundsErrorMessage = isOutOfBoundsErrorMessage;
		if (isRequiredErrorMessage) DayPickers.isRequiredErrorMessage = isRequiredErrorMessage;
		if (invalidInputErrorMessage) DayPickers.invalidInputErrorMessage = invalidInputErrorMessage;

		return (
			<div className="datePickerWithBoundary">
				<DatePicker
					id={id}
					label={label}
					ariaLabel={ariaLabel}
					isRequired={isRequired}
					allowTextInput={allowTextInput}
					onSelectDate={this.onSelectDate}
					value={selectedDate}
					firstDayOfWeek={firstDayOfWeek}
					strings={DayPickers}
					placeholder={this.props.intl.formatMessage({
						id: "common.datePicker.selectDatePlaceHolder",
						defaultMessage: "Select a date..."
					})}
					onFocus={this.onIconClick}
					minDate={minDate}
					maxDate={maxDate}
					formatDate={this.onFormatDate}
					disabled={disabled}
					disableAutoFocus={disableAutoFocus}
				/>
			</div>
		);
	}
}

DatePickerWithBoundary.propTypes = propTypes;

export default injectIntl(DatePickerWithBoundary);
