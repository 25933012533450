import * as React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from 'office-ui-fabric-react';
import './AutoComplete.scss';
import Debounce from 'lodash.debounce';
import ReactDOM from 'react-dom';

const propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.array,
    onSearch: PropTypes.func,
    defaultDisplayText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string
}
const listExpandCollapseMessage = {
    ExpandMsg: 'List item expanded, use up and down arrow key to navigate',
    CollapseMsg: 'List Item collapsed',
    SearchResultNotFoundMsg: 'Search result not found, change search criteria to get result'
}
export class AutoComplete extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: this.props.items,
            processing: false,
            isDropdownHovered: false,
            lstExpandMsg: '',
            focusItemId: ''
        };
        this.textInput = React.createRef();

        let selectedItem = this.getSelectedItem(this.props.items, this.props.defaultDisplayText);
        if (selectedItem) {
            this.state.selectedItem = selectedItem;
            this.state.searchText = selectedItem.displayText;
        }

        this.lazySearch = Debounce(this.onChangeText, 500, true);
    }

    getSelectedItem = (items, value) => {
        return items && items.find(item => item.displayText == value);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.defaultDisplayText !== newProps.defaultDisplayText) {
            let selectedItem = this.getSelectedItem(newProps.items, newProps.defaultDisplayText);
            this.setState({ items: newProps.items, selectedItem: selectedItem, searchText: newProps.defaultDisplayText });
        }
        else if (this.state.selectedItem && this.state.selectedItem.displayText !== newProps.defaultDisplayText) {
            this.setState({ items: newProps.items, selectedItem: null, searchText: newProps.defaultDisplayText });
        }
        else {
            if (newProps.items !== this.props.items) {
                let msgExpandlst = (newProps.items && newProps.items.length > 0)
                    ? listExpandCollapseMessage.ExpandMsg : '';
                if (msgExpandlst != '' && this.state.lstExpandMsg == msgExpandlst) {
                    msgExpandlst += '.';
                }
                else if ((this.props.items && this.props.items.length > 0) && (newProps.items && newProps.items.length == 0) 
                           && this.state.searchText != '' ) {
                    msgExpandlst = listExpandCollapseMessage.SearchResultNotFoundMsg;
                }
                this.setState({ items: newProps.items, lstExpandMsg: msgExpandlst });
            }
        }
    }

    onItemClick = (item) => {

        this.setState({
            processing: false, selectedItem: item, searchText: item.displayText,
            lstExpandMsg: listExpandCollapseMessage.CollapseMsg,
            focusItemId: ''
        });
        this.props.onSelect(item);
    }
    componentDidUpdate() {
        let listItemsContainer = ReactDOM.findDOMNode(this.refs.listItemsContainer);
        if (listItemsContainer != undefined) {
            let selectedLi = listItemsContainer.getElementsByClassName('auto-complete-active');

            if (selectedLi.length > 0 && selectedLi != undefined) {
                if (selectedLi[0].offsetTop + selectedLi[0].offsetHeight > listItemsContainer.scrollTop + listItemsContainer.offsetHeight) {
                    const liItemPosition = selectedLi[0].offsetTop + selectedLi[0].offsetHeight
                    listItemsContainer.scrollTop = liItemPosition - listItemsContainer.offsetTop;
                }
                else if (selectedLi[0].offsetTop < listItemsContainer.scrollTop) {
                    const liItemPosition = selectedLi[0].offsetTop
                    listItemsContainer.scrollTop = liItemPosition - listItemsContainer.offsetTop;
                }
            }
            else {
                listItemsContainer.scrollTop = 0;
            }
        }
    }

    handleKeyPress = (e) => {

        if (!this.state.items || this.state.items.length == 0)
            return;
        if (e.keyCode == 40) {
            this.setNextActiveItem();
        }
        else if (e.keyCode == 38) {
            this.setPreviousActiveItem();
        }
        else if (e.keyCode == 13) {
            e.preventDefault();
            let item = this.state.items.find(item => item.isActive);
            if (item) {
                this.setState({
                    processing: false, selectedItem: item, searchText: item.displayText,
                    lstExpandMsg: listExpandCollapseMessage.CollapseMsg,
                    focusItemId: ''
                });
                this.props.onSelect(item);
            }
        }
    }

    setNextActiveItem = () => {
        let items = this.state.items;
        let index = items.findIndex(x => x.isActive);
        if (index > -1) {
            if (index < items.length) {
                let nextItem = items[index + 1];
                if (nextItem != undefined) { // validate next item exist or not to move focus
                    items[index].isActive = false;
                    nextItem.isActive = true;
                    this.setState({
                        items: items,
                        focusItemId: nextItem.key
                    });
                }
            }
        }
        else {
            this.state.items[0].isActive = true;
            this.setState({
                items: items,
                focusItemId: this.state.items[0].key
            });
        }
    }

    setPreviousActiveItem = () => {
        let items = this.state.items;
        let index = items.findIndex(x => x.isActive);
        if (index > -1) {
            if (index > 0) {
                let previousItem = items[index - 1];
                if (previousItem != undefined) {
                    items[index].isActive = false;
                    previousItem.isActive = true;
                    this.setState({
                        items: items,
                        focusItemId: previousItem.key
                    });
                }
            }
        }
    }

    onChangeText = (ev, text) => {
        this.setState({ searchText: text, processing: true, items: [] });
        this.props.onSelect(null);
        if (text !== '' && this.props.onSearch) {
            this.props.onSearch(text);
        }
    };

    onBlur = (event) => {
        if (!this.state.isDropdownHovered)
            this.setState({ processing: false });
        this.setState({ focusItemId: '' });
        event.preventDefault();
    }


    getActiveItemClass = (item) => {
        return item.isActive ? "auto-complete-active" : "";
    }

    onMouseEnter = (event) => {
        this.setState({ isDropdownHovered: true });
        event.preventDefault();
    }
    onMouseLeave = (event) => {
        this.textInput.focus();
        this.setState({ isDropdownHovered: false });
        event.preventDefault();
    }
    setReference = (ref) => {
        this.textInput = ref;
    }

    render() {
        const { items, searchText, processing, lstExpandMsg, focusItemId } = this.state;
        const listConatinerId = 'list-' + this.props.label;
        return (

            <div className='auto-complete'>
                <TextField value={searchText}
                    disabled={this.props.disabled || false}
                    componentRef={this.setReference}
                    placeholder={this.props.placeholder}
                    label={this.props.label}
                    ariaLabel={this.props.ariaLabel}
                    required={this.props.isRequired}
                    onChange={this.lazySearch}
                    errorMessage={!processing && this.props.errorMessage}
                    onKeyDown={this.handleKeyPress} onBlur={this.onBlur}
                    aria-autocomplete="list"
                    aria-activedescendant={focusItemId}
                />

                {processing && items && items.length > 0 ?

                    <div ref='listItemsContainer' id={listConatinerId} title={`${this.props.label} "listbox"`} role="listbox" className='auto-complete-items' onMouseEnter={this.onMouseEnter} tabIndex={0}>
                        {items && items.map((item, index) => {
                            return <div role="option" className={this.getActiveItemClass(item)} id={item.key} key={index} onMouseDown={(event) => this.onItemClick(item)}>
                                {
                                    <React.Fragment>
                                        {item.displayText}
                                    </React.Fragment>
                                }
                            </div>
                        })
                        }
                    </div>
                    : null
                }
                <div className="offscreen" aria-live="assertive" >{lstExpandMsg}</div>
            </div>
        );
    }
}

AutoComplete.propTypes = propTypes;