import * as React from 'react';
import {  Label } from 'office-ui-fabric-react';
import { PropTypes } from 'prop-types';
import './OptionsButton.scss';

const propTypes = {
  defaultSelectedKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionButtonStyle: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  isDisabled: PropTypes.bool
}

export class OptionsButton extends React.Component {
  constructor(props) {
    super(props);
    this.changeSelection = this.changeSelection.bind(this);
    this.state = { selectedKey: this.props.defaultSelectedKey };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ selectedKey: nextProps.defaultSelectedKey });
  }

  changeSelection(selectedOption) {
    this.setState({ selectedKey: selectedOption.key });
    this.props.onSelect(selectedOption);
  }
  render() {
    const { options, label, id } = this.props;
    var selectedKey = this.state.selectedKey;
    var _this = this;
    return (
      <div className='option-button radio-group' role="listbox" aria-required={_this.props.isRequired} required={_this.props.isRequired} aria-labelledby={id}>
      <div className="option-Label">
           <Label required={_this.props.isRequired}  name={label} id={id}>{label}</Label>
       </div>
       <React.Fragment>
         {options.map(function (option, index) {
           return (
             <div key={`${id}-${option.key}`} className="custom-radio custom-control-inline switch-radio">
              <input 
              key={`${id}-${option.key}`}
              type="radio" 
              role="radio"
              className="custom-control-input" 
              disabled={_this.props.isDisabled} 
              aria-label={"Radio Button " + option.text + (option.key === selectedKey ? " selected" : " not selected")}
							//aria-selected={option.key === selectedKey}
              checked={option.key === selectedKey} 
              id={`${id}-${option.key}`}
              onChange={() => _this.changeSelection(option)} 
              name={id} 
              />
              <label aria-hidden="true" className={_this.props.errorMsg ? 'button button-error' : 'button'} htmlFor={`${id}-${option.key}`} >{option.text}</label>
           </div>
           );
         })}
       </React.Fragment>
       <div className="option-error">
           {this.props.errorMsg ? <Label role="alert"  className="option-error-msg" name='OptionsButtonError' id='OptionsButtonError'>{this.props.errorMsg}</Label> : null}
         </div>
        
      </div>
    );
  }
}

OptionsButton.propTypes = propTypes;
