import { take, put, call, apply } from 'redux-saga/effects';
import fileDownload  from 'react-file-download';
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes, AlertsFeature } from '../../../core/constants';
import * as oemPartNumberManagementAction from './OemPartNumberManagement.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'
import uuidv4 from 'uuid/v4';

const getQueryParameter = (searchFilter,partNumber) => {
    let queryParams = "";  

    queryParams = queryParams + (searchFilter.page ? "PageNo=" + searchFilter.page : "");
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
    queryParams = queryParams + (partNumber ? "&searchString=" + partNumber : "");

    return queryParams;
}


export function* oemPartNumberMappingSaga() {
    while (true) {
        try {
            const { siteId, customerId,partNumber, searchFilter } = yield take(oemPartNumberManagementAction.GET_OEM_PARTNUMBER_MAPPING);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/OemPartnumber?${getQueryParameter(searchFilter,partNumber)}`, method: 'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(oemPartNumberManagementAction.setOrmPartNumber(data));
            } 
           
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to get Customer PartNumber Mapping."] }));
        }
    }
}


export function* partNumberDetailsSaga() {
    while (true) {
        try {
            const { siteId, customerId, itemId } = yield take(oemPartNumberManagementAction.GET_PARTNUMBER_DETAILS);
            const config = yield ApplicationConfig.config;

            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/item/${itemId}`, method: 'GET'}, SagaHandleResponse :{ HandleResponse : false }, IsPanelTask: true});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(oemPartNumberManagementAction.setPartNumberDetails(data));
            } else if (response.status === 404) {
                yield put(showAlert({ type: AlertsTypes.WARNING, feature:AlertsFeature.ORION_PANEL_ALERTS, message: [`Part Number "${itemId}" does not exist`] }));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to get part number details."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to get part number details."] }));
        }
    }
}

export function* partNumberValidationSaga() {
    while (true) {
        try {
            const { itemIds } = yield take(oemPartNumberManagementAction.VALIDATE_PARTNUMBERS);
            const config = yield ApplicationConfig.config;
            let requestData = JSON.stringify(itemIds);

            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/items/validate`, method: 'POST', data: requestData}, SagaHandleResponse :{ HandleResponse : false }, IsPanelTask: true});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(oemPartNumberManagementAction.saveValidatePartNumbers(data));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to validate part number details."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to validate part number details."] }));
        }
    }
}

export function* saveOemPartNumberMappingSaga() {
    while (true) {
        try {
            const { siteId, customerId, oemPartNumberMapping } = yield take(oemPartNumberManagementAction.SAVE_OEM_PARTNUMBER_MAPPING);
            let requestData = JSON.stringify(oemPartNumberMapping);

            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/OemPartNumber`, method: 'POST',data: requestData}, SagaHandleResponse :{ HandleResponse : false }, IsPanelTask: true});
            if (response.status === 200) {
                yield put(oemPartNumberManagementAction.saveOperationCompletionStatus({status: true}));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Part Number mapping saved successfully"] }));
            } else if (response.status === 400){
                const data = yield apply(response, response.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature:AlertsFeature.ORION_PANEL_ALERTS, message: [ data && typeof data === "string" ? data : "Failed to save Customer Part Number mapping."] }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to save Customer Part Number mapping."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR,feature:AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to save customer part number mapping."] }));
        }
    }
}

export function* exportOemPartNumberMappingSaga() {
    while (true) {
        try {
            const { siteId, customerId } = yield take(oemPartNumberManagementAction.EXPORT_OEM_PARTNUMBER_MAPPING);
           
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}//api/coa/v1/site/${siteId}/customer/${customerId}/OemPartnumber/report`, method: 'GET'}, SagaHandleResponse :{ HandleResponse : false }});
            if (response.status === 200) {
                response.blob().then(data => {
                    fileDownload(data, `OemPartNumberMapping_Report_${new Date().toDateString().split(" ").join("_")}.csv`);
                });
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
            }else
            {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Failed to generate report"] }));
            }
        } catch (error) {
            yield put(throwException("exportOemPartNumberMappingSaga details error: " + error));
        }
    }
}