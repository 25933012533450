import { call, put, cancelled, select } from "redux-saga/effects";
import { fetchUtil } from './fetchUtil'
import { incrementPendingAPICalls, decrementPendingAPICalls, incrementPendingAPICallsPanel, decrementPendingAPICallsPanel } from "../actions";
// import { Auth } from '../constants/CoreConstants';
import cache from 'memory-cache';
import { showAlert } from '../../core/components';
import { AlertsTypes, AlertsFeature } from '../../core/constants';
import { throwException } from '../../core/components/Logger/Logger.actions';
import { authProviderSelector } from "../selectors";

let mCache = new cache.Cache();
export function* InvokeUrl(requestData) {
    var {Config,SagaHandleResponse,IsbackGroundTask, IsPanelTask}  =  requestData;

    Config.isCache = Config.isCache || false;
    IsbackGroundTask = IsbackGroundTask || false;
    IsPanelTask = IsPanelTask || false;
     
    try {
        if (!IsbackGroundTask && !IsPanelTask)
            yield put(incrementPendingAPICalls());

        if (IsPanelTask)
            yield put(incrementPendingAPICallsPanel());

        var reqHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        if (!Config.isCache) {
            reqHeaders['Pragma'] = 'no-cache';
            reqHeaders['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        }

        const token = yield* getTokenFromAuth();
        if (token) {
            reqHeaders.Authorization = `Bearer ${token}`;
        }

        if (Config.config != null && Config.config !== undefined) {
            reqHeaders = Object.assign(reqHeaders, Config.config);
        }

        const { response, error } = yield call(fetchUtil, Config.url, {
            method: Config.method,
            headers: reqHeaders,
            body: Config.data,
            credentials: 'same-origin'
        });
        
        if (!IsbackGroundTask && !IsPanelTask)
            yield put(decrementPendingAPICalls());

        if (IsPanelTask)
            yield put(decrementPendingAPICallsPanel());
            
        if (error || (response.status >= 400 && (SagaHandleResponse == undefined || (SagaHandleResponse.HandleResponse && (SagaHandleResponse.ExcludedResponses == undefined || !SagaHandleResponse.ExcludedResponses.includes(response.status)))))) {
            yield put(throwException("Tried calling " + Config.url + " because of " + (response ? response.status : error) + " as response"));
            yield put(showAlert({ type: AlertsTypes.ERROR, isGeneric: true, feature: AlertsFeature.GLOBAL_ALERTS, message: ["Something went wrong. Please contact OEM helpdesk for more assistance."] }));
        }
        return response ? response : error;

    }
    finally {
        if (yield cancelled()) {
            if (!IsbackGroundTask && !IsPanelTask)
                yield put(decrementPendingAPICalls());

            if (IsPanelTask)
                yield put(decrementPendingAPICallsPanel());
        }
            
    }

}

export function* InvokeCachedUrl(url, method, data) {

    let cachedResponse = mCache.get(url);

    if (cachedResponse) {
        return cachedResponse;
    } else {

        var responseC = yield call(InvokeUrl, url, method, data);
        mCache.put(url, responseC, 5000 * 1000);
        return responseC;
    }
}

function* getTokenFromAuth() {
	let authProvider = yield select(authProviderSelector);
	let token = yield authProvider.getTokenWithRefresh();
	// const token = localStorage.getItem(Auth.IDTokenKey);
	return token;
}