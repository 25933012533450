import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {    
    GET_TPI_ORGS, setTpiOrgs
} from './ThirdPartyList.actions';
import {throwException } from '../../../core/components/Logger/Logger.actions';

export function* tpiOrgsSaga() {

    while (true) {
        try {
            const { customerId } = yield take(GET_TPI_ORGS);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/thirdpartyinstaller?CustomerId=${customerId}`,method: 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var tpiOrgsData = data.map((d) => {
                    return {                        
                        key: d.InstallerId.toString(),
                        text: d.DisplayName,
                        CountryCode: d.InstallerCountry,
                        CountryName: d.InstallerCountryName,
                        City: d.InstallerCity,
                        CustomerId: d.CustomerId,
                        TSTStatusId: d.TSTStatusId
                    };
                });

               
                yield put(setTpiOrgs(tpiOrgsData));

            } 

        } catch (error) {
            yield put(throwException("downloadDocumentSaga error: " + error));
        }
    }
}
