import React, { Component } from 'react';
import './OrderLineItem.scss'
import { injectIntl } from 'react-intl';
import OrderLineItemInfo from '../OrderLineItem/OrderLineItemInfo';
import { Label } from 'office-ui-fabric-react';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';
import { TabIndexes } from '../../../common/constants';
import { IconButton } from 'office-ui-fabric-react';

class OrderLineItemsInfo extends Component {
    constructor(props) {
        super(props);
        let lineItems = this.props.LineItems;
        lineItems.forEach((item) => {
            item.isCollapsed = true;
        });
        this.state = {
            isCollapsed: true,
            lineItems: lineItems
        }
    }

    componentWillReceiveProps(newProps) {
        let lineItems = newProps.LineItems;
        lineItems.forEach((item) => {
            item.isCollapsed = true;
        });
        this.setState({
            lineItems: lineItems
        })
    }

    onOrderLineItemsToggle = () => {
        let isCollapsed = !this.state.isCollapsed;
        let lineItems = this.state.lineItems;
        lineItems.forEach((item) => {
            item.isCollapsed = isCollapsed
        })

        this.setState({
            lineItems: lineItems,
            isCollapsed: isCollapsed,
        });
    }

    handleChange = (item) => {
        let lineItems = this.state.lineItems;
        let lineItem = lineItems.find(info => info.DisplayId === item.DisplayId);
        if (lineItem) {
            lineItem.isCollapsed = !lineItem.isCollapsed;
            this.setState({
                lineItems: lineItems
            })
        }
    }

    renderOrderLineItem = (item) => {
        return <OrderLineItemInfo key={item.DisplayId} {...item}
            handleChange={this.handleChange} > </OrderLineItemInfo>
    }

    render() {
        return (
            <div className='order-line-items-info'>
                <Accordion>
                    <AccordionHeader accordionheader={this.props.intl.formatMessage({ id: 'orderLineItems.HeaderTitle', defaultMessage: 'Order Line Items' })}>
                        <div className="header-text">
                            <h3>
                                {this.props.intl.formatMessage({ id: 'orderLineItems.HeaderTitle', defaultMessage: 'Order Line Items' })}
                            </h3>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        {
                            this.state.lineItems && this.state.lineItems.length > 0 ?
                                <div>
                                    <div className="order-type-option">
                                        <Label name="OrderTypeLabel" id="OrderTypeLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'order.OrderTypeTitle', defaultMessage: 'Order Type' })}</Label>
                                        <Label name="OrderTypeValue" id="OrderTypeValue" className="labelValue">{typeof (this.state.lineItems[0].ShipmentType) === 'string' ? this.state.lineItems[0].ShipmentType : this.state.lineItems[0].ShipmentType.text}</Label>
                                    </div>
                                    <div className="order-lineitems-collapse-section">
                                        <div className="order-lineitems-collapse">
                                            <span aria-expanded={!this.state.isCollapsed} role="alert" className="hover-content" onClick={() => this.onOrderLineItemsToggle()}>
                                                {
                                                    this.state.isCollapsed ?
                                                        <IconButton iconProps={{ iconName: 'ChevronDown' }}
                                                            aria-label="Add New Line Items hide section collapsed"
                                                            aria-expanded="false"
                                                            className="line-items-toggle-icon ms-slideUpIn10" /> :

                                                        <IconButton iconProps={{ iconName: 'ChevronUp' }}
                                                            aria-label="Add New Line Items show section expanded"
                                                            aria-expanded="true"
                                                            className="line-items-toggle-icon ms-slideDownIn10" />


                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            this.state.lineItems.map((item, index) => {
                                return this.renderOrderLineItem(item, index)
                            })
                        }

                    </AccordionBody>
                </Accordion>
            </div>
        )
    }
}

export default injectIntl(OrderLineItemsInfo);