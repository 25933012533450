import React, { Component } from 'react';
import { connect } from "react-redux";
import Alert from './Alert';
import { alertsSelector } from './Alert.selectors';
import { hideFeatureAlert } from './Alert.actions';


class FeatureAlerts extends Component {
    componentWillUnmount() {
        this.props.hideAllFeatureAlerts(this.props.feature);
    }
    render() {
        let { alerts, timeOut } = this.props;
        if (!alerts) return null;
        if (!timeOut) timeOut = 5000;
        return (
            <React.Fragment>
                 <div  className="alertList" role="alert" aria-live="assertive">
                    {alerts && alerts.length > 0 && alerts.map((alert, id) => <Alert key={alert.id} {...alert} dismissTime={timeOut} />)}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    var alerts = alertsSelector(ownProps.feature)(state);
    return { alerts: alerts }
};

const mapDispatchToProps = (dispatch) => ({
    hideAllFeatureAlerts(feature) {
        dispatch(hideFeatureAlert(feature));
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(FeatureAlerts);