
import { createReducer } from '../../../core/utility';
import { SET_SHIPMENT_DETAILS } from './ShipmentDetails.actions';
import { fromJS } from 'immutable';

export const shipmentInfo = createReducer(null, {
  [SET_SHIPMENT_DETAILS](state, { shipmentInfo }) {
    return fromJS(shipmentInfo);
  }  
});
