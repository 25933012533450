import { makeActionCreator } from "../../utility";

export const INITIALIZE_AUTH = "INITIALIZE_AUTH";
export const initializeAuth = makeActionCreator(INITIALIZE_AUTH,false,"msal_config","userInfoCallback");

export const SET_USER_INFO = "SET_USER_INFO";
export const setUserInfo = makeActionCreator(SET_USER_INFO,false,"userInfo");

export const SET_AUTH_PROVIDER = "SET_AUTH_PROVIDER";
export const setAuthProvider = makeActionCreator(SET_AUTH_PROVIDER,false,"authProvider");

export const TRIGGER_LOGIN = "TRIGGER_LOGIN";
export const triggerLogin = makeActionCreator(TRIGGER_LOGIN,false);

export const TRIGGER_LOGOUT = "TRIGGER_LOGOUT";
export const triggerLogout = makeActionCreator(TRIGGER_LOGOUT,false);
