import { makeActionCreator } from "../../../core/utility";

export const GET_PRODUCT_MASTER_INFO ="license/components/SearchProduct/GET_PRODUCT_MASTER_INFO";
export const getProductMasterInfo = makeActionCreator(GET_PRODUCT_MASTER_INFO, false);
export const SET_PRODUCT_MASTER_INFO = "license/components/SearchProduct/SET_PRODUCT_MASTER_INFO";
export const setProductMasterInfo = makeActionCreator(SET_PRODUCT_MASTER_INFO, false, "productMasterInfo");

export const GET_SEARCH_PRODUCT_RESULTS ="license/components/SearchProduct/GET_SEARCH_PRODUCT_RESULTS";
export const getSearchProductResults = makeActionCreator(GET_SEARCH_PRODUCT_RESULTS, false,"productFilters");
export const SET_SEARCH_PRODUCT_RESULTS = "license/components/SearchProduct/SET_SEARCH_PRODUCT_RESULTS";
export const setSearchProductResults = makeActionCreator(SET_SEARCH_PRODUCT_RESULTS, false, "searchProductResults");

export const GET_PRODUCT_FAMILY_VALUES ="license/components/SearchProduct/GET_PRODUCT_FAMILY_VALUES";
export const getProductFamilyValues = makeActionCreator(GET_PRODUCT_FAMILY_VALUES, false,"searchString");
export const SET_PRODUCT_FAMILY_VALUES = "license/components/SearchProduct/SET_PRODUCT_FAMILY_VALUES";
export const setProductFamilyValues = makeActionCreator(SET_PRODUCT_FAMILY_VALUES, false, "productFamilyValues");

export const SAVE_SEARCH_PRODUT_CRITERIA ="license/components/SearchProduct/SAVE_SEARCH_PRODUT_CRITERIA";
export const saveSearchProductCriteria = makeActionCreator(SAVE_SEARCH_PRODUT_CRITERIA, false,"searchProductCriteria");
