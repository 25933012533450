import React from 'react';
import './EditOrder.scss'
import { injectIntl } from 'react-intl';
import { TextField, DefaultButton } from 'office-ui-fabric-react';
import { WizardStep, WizardStepFooter } from '../../../core/components';
import OrderHeaderInfo from '../OrderHeader/OrderHeaderInfo';
import OrderLineItemsInfo from '../OrderLineItem/OrderLineItemsInfo';

export const editOrderReview = (props) => {
    return (
        <div className="edit-order-info">     
            <div className="edit-order-header-info">
                <OrderHeaderInfo {...props.order} /> 
            </div>   
            <div className="edit-order-lineitems">
                <OrderLineItemsInfo {...props.order} />   
            </div> 
            <div className="commentsArea">
                <TextField name="OrderComments"
                    id="txt_Comments"
                    label={props.intl.formatMessage({ id: 'orderReview.Comments', defaultMessage: 'Comments' })}
                    multiline rows={4}
                    value={props.order.Remark.value}
                    onChanged={(value) => props.handleChange([{
                        field: "Remark",
                        value: value,
                        text: value
                    }])}>
                </TextField> 
            </div>
            <div>
                <WizardStep>
                    <WizardStepFooter>
                        <DefaultButton
                        className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={props.intl.formatMessage({
                                id: 'app.previous',
                                defaultMessage: 'Previous',
                            })}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                            onClick={props.previousPage}
                            allowDisabledFocus={true} />
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={props.intl.formatMessage({
                                id: 'app.cancel',
                                defaultMessage: 'Cancel',
                            })}
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={props.cancelPage}
                            allowDisabledFocus={true} />
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={props.intl.formatMessage({
                                id: 'app.submit',
                                defaultMessage: 'Submit',
                            })}
                            iconProps={{ iconName: 'Save' }}
                            onClick={props.save}
                            allowDisabledFocus={true} />
                    </WizardStepFooter>
                </WizardStep>
            </div>
        </div>
    );
}

export default injectIntl(editOrderReview);