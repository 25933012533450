import { put, take, call, apply, select } from 'redux-saga/effects';
import { INITIALIZE_APP, getArSites, getClaims, getNavigationMenu, GET_NAVIGATION_MENU, setNavigationMenu, INIT_APPINSIGHTS, initAppInsights, getRolesMapping, GET_ROLES_MAPPING, setRolesMapping, getContactEmail, GET_CONTACT_EMAIL, setContactEmail } from '../actions';
import ApplicationConfig from '../../core/utility/applicationConfig';
import { InvokeUrl } from '../../core/utility';
import history from '../../../history';
import ReactAI from 'react-appinsights';
import { RedirectPath, TstAllowStatus,TSTStatusKey,IsActiveAgreementKey } from '../constants';
import { throwException } from '../../core/components/Logger/Logger.actions';
import {userEmailSelector,userClaimsSelector } from '../selectors/authz.selectors'
export function* appStartSaga() {

    while (true) {
        yield take(INITIALIZE_APP);
        yield put(initAppInsights());
        yield put(getClaims());
        yield put(getRolesMapping());
        yield put(getArSites());
        yield put(getNavigationMenu());
        yield put(getContactEmail())
    }
}

function getPlatformMap(mapArray) {
    return mapArray.reduce((map, item) => { map[item.Key] = item.Value; return map; }, {});
}

function getNavigationItemInMenuFormat(menuItems) {
    const platformsMap = getPlatformMap(menuItems.PlatformServerMap);
    var updatedMenu = menuItems
        .Items
        .map(function (d) {
            var level1ItemsArray = d
                .Items
                .map(function (level1InnerItem) {
                    var level2ItemsArray = level1InnerItem
                        .Items
                        .map(function (level2InnerItem) {
                            var level2ItemsArray = level2InnerItem
                                .Items
                                .map(function (level3InnerItem) {

                                    if (!level3InnerItem.IsNavigable) {
                                        if (level3InnerItem.Paltform === "OAPlatform" || level3InnerItem.Paltform === "OSPPlatform" || level3InnerItem.Paltform === "HAWKPlatform")
                                            return { key: level3InnerItem.ItemKey, href: platformsMap[level3InnerItem.Paltform] + "/#" + level3InnerItem.NaviationUri, name: level3InnerItem.DisplayText };
                                        else if (level3InnerItem.Paltform === "ORNPlatform")
                                            return { key: level3InnerItem.ItemKey, onClick: () => history.push(RedirectPath + level3InnerItem.NaviationUri), name: level3InnerItem.DisplayText };
                                        return { key: level3InnerItem.ItemKey, href: platformsMap[level3InnerItem.Paltform] + level3InnerItem.NaviationUri, name: level3InnerItem.DisplayText };
                                    }
                                    else
                                        return null;
                                });

                            var level2ItemsArrayFiltered = level2ItemsArray.filter((d) => {
                                return (d != null);
                            })
                            return { key: level2InnerItem.ItemKey, name: level2InnerItem.DisplayText, subMenuProps: { items: level2ItemsArrayFiltered } };
                        });

                    var level2ItemsArrayFiltered = level2ItemsArray.filter((d) => {
                        return d.subMenuProps.items.length > 0
                    })
                    return { key: level1InnerItem.ItemKey, name: level1InnerItem.DisplayText, subMenuProps: { items: level2ItemsArrayFiltered } };
                });

            var level1ItemsArrayFiltered = level1ItemsArray.filter((d) => {
                return d.subMenuProps.items.length > 0
            })
            return { key: d.ItemKey, name: d.DisplayText, subMenuProps: { items: level1ItemsArrayFiltered } };
        });
    updatedMenu[0].subMenuProps.items.unshift({ name: 'Home', href: platformsMap[menuItems.Items[0].Paltform], key: 'home' });
    return updatedMenu[0].subMenuProps.items;
}
export function* NavigationMenuSaga() {
    while (true) {
        try {
            yield take(GET_NAVIGATION_MENU);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/menu`, method: 'GET' } });

            if (responseC.status === 200) {
                let navigationMenu = yield apply(responseC, responseC.json);
                navigationMenu = getNavigationItemInMenuFormat(navigationMenu);
                let isValidTSTStatus=true;
                const userClaims = yield select(userClaimsSelector);
                if(userClaims && config.TSTFeature==="1"){
                    userClaims.forEach(userClaim => {
                        if (userClaim.Key===TSTStatusKey && isValidTSTStatus
                            && userClaim.Value && userClaim.Value !== "") {
                                let check = TstAllowStatus.includes(userClaim.Value);
                                if(!check){
                                    isValidTSTStatus = false;
                                }
                        }
                        if(userClaim.Key===IsActiveAgreementKey 
                            && userClaim.Value.toLowerCase()=== "true"){
                            isValidTSTStatus = true;
                        }
                    }); 
                    if(!isValidTSTStatus){
                        yield put(setNavigationMenu(""));
                    }
                }
                if(isValidTSTStatus){
                    yield put(setNavigationMenu(navigationMenu));
                }
            }
        } catch (error) {
            yield put(throwException("Navigation saga error: because of " + error));
        }
    }
}


export function* contactEmailSaga() {
    while (true) {
        try {
            yield take(GET_CONTACT_EMAIL);
            const config = yield ApplicationConfig.config;
            console.log(config.contactEmail);
            yield put(setContactEmail(config.contactEmail));
        } catch (error) {
            yield put(throwException("contact email saga error: because of " + error));
        }
    }
}


export function* appinsightsSaga() {

    while (true) {
        yield take(INIT_APPINSIGHTS);
        const config = yield ApplicationConfig.config;
        const email = yield select(userEmailSelector);
        ReactAI.init({ instrumentationKey: config.appinsights_InstrumentationKey }, history);
        ReactAI.setAppContext({ urlReferrer: document.referrer});
        var appInsights = ReactAI.ai();
        appInsights.setAuthenticatedUserContext(email,null,true);
        
    }
}


export function* roleMappingSaga() {

    while (true) {
        yield take(GET_ROLES_MAPPING);
        const config = yield ApplicationConfig.config;
        yield put(setRolesMapping({ PageRoleMapping: config.PageRoleMapping, ComponentRoleMapping: config.ComponentRoleMapping }))
    }
}