
export const Statuses = {
    PENDING_SUBMISSION: { StatusCodes: [10, 11, 12], key: "PENDING_SUBMISSION" },
    AWAITING_ACCEPTANCE: { StatusCodes: [20, 21], key: "AWAITING_ACCEPTANCE" },
    REJECTED: { StatusCodes: [13], key: "REJECTED" },
    SUBMITTED: { StatusCodes: [31, 41], key: "SUBMITTED" }

}

export const isInStatus = (status, parentStatus) => {

    return Statuses[parentStatus.key].StatusCodes.includes(parseInt(status));
}

