import React, { Component } from 'react';
import './OrderReview.scss'
import {
    WizardStep, WizardStepHeader,
    WizardStepBody, WizardStepFooter
} from '../../../core/components';
import { DefaultButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { OrderConstants } from '../../constants';
import { orderStepSelector } from '../CreateOrder/CreateOrder.selectors';
import { OrderDetailsInfo } from '../../components/OrderDetails';
import { FocusWrapper } from '../../../core/components/FocusWrapper';
import {TabIndexes} from "../../../common/constants/tabIndex";

const propTypes = {
    initialState: PropTypes.object,
    onPreviousClick: PropTypes.func,
    onSubmitClick: PropTypes.func,
}

class OrderReviewStep extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.initialState;
        this.state.inputData = this.state.inputData ? this.state.inputData : {};
        this.state.inputData["Remark"] = this.state.inputData["Remark"] ?
            this.state.inputData["Remark"] : { value: "" };
        this.state.isStateValid = true;

        this.orderHeaderRef = React.createRef();
    }

    componentDidMount = () => {
        let headerDiv = this.orderHeaderRef.current;
        if (headerDiv != null) {
            let index = 0;
            let accordionHeader = headerDiv.getElementsByClassName("accordion-header-icon");
            let accordionList = accordionHeader.length > 0 ? accordionHeader[index].getElementsByClassName("ms-Button--icon") : [];
            if (accordionList.length > 0) {
                    accordionList[index].tabIndex = TabIndexes.InOrder;
                    accordionList[index].focus();
                }
            }
       };

       handleChange = (field, value) => {
        let inputData = this.state.inputData;
        inputData[field].value = value;
        inputData[field].text = value;
        this.setState({
            inputData: inputData,
            isStateValid: true
        });
    }


    render() {
        return (
            <div className="order-review-container">
                <WizardStep>
                    <WizardStepHeader title={this.props.intl.formatMessage({ id: 'orderReview.HeaderTitle', defaultMessage: "Review & Submit" })} />
                    <WizardStepBody>
                        <OrderDetailsInfo headerRef = {this.orderHeaderRef}  {...this.props.orderInfo}></OrderDetailsInfo>
                        <div className='ms-md5 commentsArea'>
                            <FocusWrapper>
                                <TextField name="OrderComments"
                                    id="txt_Comments"
                                    label={this.props.intl.formatMessage({ id: 'orderReview.Comments', defaultMessage: 'Comments' })}
                                    multiline rows={4}
                                    value={this.state.inputData.Remark.value}
                                    onChanged={(value) => this.handleChange("Remark", value)}
                                    maxLength="255">
                                </TextField>
                            </FocusWrapper>
                        </div>
                    </WizardStepBody>

                    <WizardStepFooter>
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={this.props.intl.formatMessage({ id: 'app.previous', defaultMessage: "Previous" })}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                            onClick={() => { this.props.onPreviousClick(this.state) }}
                            allowDisabledFocus={true} />
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={this.props.intl.formatMessage({ id: 'app.submit', defaultMessage: "Submit" })}
                            iconProps={{ iconName: 'Save' }}
                            onClick={() => { this.props.onSubmitClick(this.state) }}
                            allowDisabledFocus={true} />
                    </WizardStepFooter>
                </WizardStep>
            </div>
        );
    }
}
OrderReviewStep.propTypes = propTypes;

function mapStateToProps(state) {
    let headerData = orderStepSelector(OrderConstants.ORDER_HEADER)(state);
    let lineItemData = orderStepSelector(OrderConstants.ORDER_LINE_ITEM)(state);
    let orderData = headerData ? { ...headerData.inputData } : {};
    orderData.LineItems = lineItemData ? lineItemData.inputData : [];
    return {
        orderInfo: orderData ? orderData : null
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(OrderReviewStep));
