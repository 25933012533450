import React, { Component } from 'react';

class FocusWrapper extends Component {

    constructor(props){
        super(props);       
        this.FirstElementRef = React.createRef();
    }

    componentDidMount() {
        if (this.FirstElementRef && this.FirstElementRef.current) {
            this.FirstElementRef.current.focus();
        }
    }

    render() {        
        let child = this.props.children;     
        if((!child) || child.length > 1)
            return null;     
        let childComponent = React.cloneElement(child, { componentRef: this.FirstElementRef });
        return(
            <React.Fragment> 
                {childComponent}                  
            </React.Fragment>           
        )     
    }   
}

export default FocusWrapper;
