import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userInfoSelector } from './authz.selectors';
import { setUserInfo, initializeAuth } from './authz.actions';


class AzureAD extends Component {
    componentDidMount() {
        this.props.initializeAuth(this.updateState);;
    }
    updateState = (user) => {
        this.props.setUserInfo(user);
    }
    render() {
        const { userInfo, componentToRender: Component } = this.props;
        return userInfo ? <Component /> : null;
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: userInfoSelector(state)
    }
}

const mapDispatchToProps = (dispatch,ownProps) => ({
    setUserInfo(user) {
        dispatch(setUserInfo(user))
    },
    initializeAuth(callback) {
        dispatch(initializeAuth(ownProps.msal_config,callback))
    }
})

export default  connect(mapStateToProps, mapDispatchToProps)(AzureAD)