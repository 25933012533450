import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../core/utility';
import ApplicationConfig from '../../core/utility/applicationConfig';
import {
    GET_ARSITES, setArSites,
    GET_CLAIMS, setClaims, setUserRoles,setNavigationMenu} from '../actions';
import { throwException} from '../../core/components/Logger/Logger.actions';
import history from '../../../history';
import {TstAllowStatus, TSTStatusKey, IsActiveAgreementKey} from '../constants';
import { TSTStatusTypeErrorMessage, TSTStatusType } from "@ec-oem/ec.oem.oa3.ui.common/components/ErrorType/InvalidTSTStatus/InvalidTSTStatus.constants";
import { parse } from 'query-string';


export function* arSitesSaga() {

    while (true) {
        try {
            yield take(GET_ARSITES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/sites`, method:'GET' } });
            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);
                var sites = data.Result && data.Result.map((d) => {
                    return { key: d.AradSiteId.toString(), text: d.Name, IsEMEA: d.IsEMEA };
                });
                yield put(setArSites(sites));
            }
        } catch (error) {
            yield put(throwException("ArSites saga error: " + error ));
        }
    }
}

export function* userClaimsSaga() {

    while (true) {
        try {
            yield take(GET_CLAIMS);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/claims`,method: 'GET'}});

            if (responseC.status === 200) {
                const claims = yield apply(responseC, responseC.json);
                yield put(setClaims(claims));
                yield put(setUserRoles(getRolesFromClaims(claims)));
                let isValidTSTStatus=true;
                let errMsg="";
                if(config.TSTFeature==="1"){
                    claims.forEach(claim => {
                        if (claim.Key===TSTStatusKey && isValidTSTStatus && claim.Value!==""
                            && claim.Value) {
                            let check = TstAllowStatus.includes(claim.Value);
                            if(!check){
                               errMsg = config.TSTStatusMapper.PartialBlockTSTStatuses.includes(parseInt(claim.Value))
                                && TSTStatusTypeErrorMessage[parseInt(claim.Value)] 
                                ? TSTStatusTypeErrorMessage[parseInt(claim.Value)] 
                                : !config.TSTStatusMapper.PartialBlockTSTStatuses.includes(parseInt(claim.Value))
                                && TSTStatusTypeErrorMessage[parseInt(claim.Value)] 
                                ? TSTStatusTypeErrorMessage[parseInt(claim.Value)] 
                                : TSTStatusTypeErrorMessage[TSTStatusType.UnderReview]

                                isValidTSTStatus = false;
                            }
                        }
                        if(claim.Key===IsActiveAgreementKey && claim.Value.toLowerCase()=== "true")
                        {
                                isValidTSTStatus = true;
                        }
                    }); 
                }
                if(!isValidTSTStatus)
                {
                    yield put(setNavigationMenu(""));
                    history.push({
                        pathname: '/AccessDenied',
                        state:errMsg
                    })
                    //history.push('/AccessDenied');
                }
            } 

        } catch (error) {
            yield put(throwException("Claims error:" + error ));
        }
    }
}

const getRolesFromClaims = (claims) => {
    return claims.reduce((roles, claim) => {
        if (claim.Key === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") roles.push(claim.Value);
        return roles;
    }, []);
}