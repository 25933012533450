import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as brandActions from './Brand.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes, AlertsFeature } from '../../../core/constants';
import uuidv4 from 'uuid/v4';

const getQueryParameter = (siteId, customerId,searchFilter,brandName) => {
    let queryParams = "";

    queryParams = queryParams + (searchFilter.page ? "PageNo=" + searchFilter.page : "");
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
    queryParams = queryParams + (siteId ? "&siteId=" + siteId : "");
    queryParams = queryParams + (customerId ? "&customerId=" + customerId : "");
    queryParams = queryParams + (brandName ? "&searchString=" + brandName : "");

    return queryParams;
}


function mapBrandDataToRequest(brandRequest) {
    let request = {
        BrandName: brandRequest.BrandName,
        ImageName: brandRequest.ImageName,
        ImageSize: brandRequest.ImageSize,
        BrandLogo: brandRequest.BrandLogo ? brandRequest.BrandLogo : ""
    }
    return request;
}

export function* saveBrandSaga() {
    while (true) {
        try {
            const { siteId, customerId, request } = yield take(brandActions.SAVE_BRAND);
            let requestData = JSON.stringify(mapBrandDataToRequest(request));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/brand`, method: "POST", data: requestData }, IsPanelTask: true });
            if (response.status === 201) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Brand Details saved successfully"] }));
                let searchFilter = { page: 1, pageSize: 20 };
                yield put(brandActions.getSearchBrandResult(siteId, customerId,"", searchFilter));
                yield put(brandActions.saveBrandOperationCompletionStatus({ status: true }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to save brand."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to save brand."] }));
        }
    }
}

export function* searchBrandSaga() {
    while (true) {
        try {
            const { siteId, customerId, brandName ,searchFilter } = yield take(brandActions.GET_SEARCH_BRAND_RESULT);

            const config = yield ApplicationConfig.config;
             const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/brands?${getQueryParameter(siteId, customerId,searchFilter,brandName)}`, method: 'GET' } });
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(brandActions.setSearchBrandResult(data));
            } else {
                yield put(brandActions.setSearchBrandResult(null));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Internal Error Occurred, Failed to get brands."] }));
        }
    }
}



export function* UpdateBrandSaga() {
    while (true) {
        try {
            const { siteId, customerId, brandId, request } = yield take(brandActions.UPDATE_BRAND);
            let requestData = JSON.stringify(mapBrandDataToRequest(request));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/brand/${brandId}`, method: "PUT", data: requestData }, IsPanelTask: true });
            if (response.status === 200) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: [`Brand Details Updated successfully`] }));
                let searchFilter = { page: 1, pageSize: 20 };
                yield put(brandActions.getSearchBrandResult(siteId, customerId,"", searchFilter));
                yield put(brandActions.saveBrandOperationCompletionStatus({ status: true }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to update brand."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Internal Error Occurred, Failed to update brand."] }));
        }
    }
}

export function* isDuplicateBrandNameSaga() {

    while (true) {
        try {
            const { siteId, customerId, brandName } = yield take(brandActions.GET_VALIDATE_BRANDNAME);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/brand/isvalidate?&brandName=${brandName}`, method: 'GET' }, IsPanelTask: true });

            if (responseC.status === 200) {
                const isValid = yield apply(responseC, responseC.json);
                yield put(brandActions.setValidateBrandName({ brandName: brandName, status: isValid }));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: [  data && typeof data === "string" ? data : "Failed to validate Brand Name."] }));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to validate Brand Name."] }));
            }
        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_PANEL_ALERTS, message: ["Failed to validate brand Name."] }));

            console.log("isDuplicateBrandNameSaga error:" + error);
        }
    }
}
