import OnDemandLiveRegion from 'on-demand-live-region';
export const Orion_PageTitle = "Orion";

const liveRegionDelayedCall = (title) => {
    let liveRegionDelayed = new OnDemandLiveRegion({
        level: 'assertive',
        delay: 500
    });
    let text = title ? title + " - " + Orion_PageTitle : Orion_PageTitle;
    liveRegionDelayed.say(text);
};

export const setPageTitle = (title) => {
    document.title = title ? title + " - " + Orion_PageTitle : Orion_PageTitle;
    liveRegionDelayedCall(title);
}


