import { createSelector } from 'reselect';
export const orderTypesSelector = createSelector(
       state => state.app.get("orderTypes"),
       orderTypes => orderTypes ?  orderTypes.toJS() : orderTypes
);

export const soldtotypesSelector = createSelector(
    state => state.app.get("soldtotypes"),
    soldtotypes => soldtotypes ?  soldtotypes.toJS() : soldtotypes
);

export const brandsSelector = createSelector(
    state => state.app.get("brands"),
    brands => brands ?  brands.toJS() : brands
);

export const shiptotypesSelector = createSelector(
    state => state.app.get("shiptotypes"),
    shiptotypes => shiptotypes ?  shiptotypes.toJS() : shiptotypes
);

export const shipToOrgsSelector = createSelector(
    state => state.app.get("shipToOrgs"),
    shipToOrgs => shipToOrgs ?  shipToOrgs.toJS() : shipToOrgs
);

export const isDuplicateOrderIdSelector = createSelector(
    state => state.app.get("isDuplicateOrderId"),
    isDuplicateOrderId => isDuplicateOrderId ? isDuplicateOrderId.toJS() : isDuplicateOrderId
);