import { makeActionCreator } from '../../../core/utility';

export const GET_ORDER_WIZARD_DATA = "Order/Components/CreateOrder/GET_ORDER_WIZARD_DATA";
export const getOrderWizardData = makeActionCreator(GET_ORDER_WIZARD_DATA, false);

export const SET_ORDER_WIZARD_DATA = "Order/Components/CreateOrder/SET_ORDER_WIZARD_DATA";
export const setOrderWizardData = makeActionCreator(SET_ORDER_WIZARD_DATA, false, "wizardData");

export const SET_ORDER_WIZARD_STEP_DATA = "Order/Components/CreateOrder/SET_ORDER_WIZARD_STEP_DATA";
export const setOrderWizardStepData = makeActionCreator(SET_ORDER_WIZARD_STEP_DATA, false, "stepData");

export const SET_ORDER_WIZARD_CURRENT_STEP = "Order/Components/CreateOrder/SET_ORDER_WIZARD_CURRENT_STEP";
export const setOrderWizardCurrentStep = makeActionCreator(SET_ORDER_WIZARD_CURRENT_STEP, false, "stepData");

export const ORDER_WIZARD_STEP_NEXT = "Order/Components/CreateOrder/ORDER_WIZARD_STEP_NEXT";
export const orderWizardStepNext = makeActionCreator(ORDER_WIZARD_STEP_NEXT, true, "stepData");

export const ORDER_WIZARD_STEP_PREVIOUS = "Order/Components/CreateOrder/ORDER_WIZARD_STEP_PREVIOUS";
export const orderWizardStepPrevious = makeActionCreator(ORDER_WIZARD_STEP_PREVIOUS, true, "stepData");

export const ORDER_WIZARD_SET_STEP = "Order/Components/CreateOrder/ORDER_WIZARD_SET_STEP";
export const orderWizardSetStep = makeActionCreator(ORDER_WIZARD_SET_STEP, true, "stepData");

export const SAVE_ORDER ="Order/components/CreateOrder/SAVE_ORDER";
export const saveOrder = makeActionCreator(SAVE_ORDER,false, "siteId", "orderRequest");

export const SET_ORDER ="Order/components/CreateOrder/SET_ORDER";
export const setOrder = makeActionCreator(SET_ORDER,false,"order");



