import { makeActionCreator } from '../../../core/utility';

export const GET_SHIPMENT_DETAILS = "Shipment/Components/ShipmentInfo/GET_SHIPMENT_DETAILS";
export const getShipmentDetails = makeActionCreator(GET_SHIPMENT_DETAILS, false, "siteId", "msShipmentId");

export const SET_SHIPMENT_DETAILS = "Shipment/Components/ShipmentInfo/SET_SHIPMENT_DETAILS";
export const setShipmentDetails = makeActionCreator(SET_SHIPMENT_DETAILS, false, "shipmentInfo");

export const SET_SHIPMENT_APPROVAL_STATUS = "Shipment/Components/ShipmentInfo/SET_SHIPMENT_APPROVAL_STATUS";
export const setShipmentApprovalStatus = makeActionCreator(SET_SHIPMENT_APPROVAL_STATUS, false, "siteId", "msShipmentId", "status");

