import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { GET_ORDER_WIZARD_DATA, setOrderWizardData, SAVE_ORDER, setOrder } from './CreateOrder.actions';
import orderWizardData from './OrderWizardConfig.json';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';

export function* getOrderWizardDataSaga() {
    while (true) {
        try {
            yield take(GET_ORDER_WIZARD_DATA);
            yield put(setOrderWizardData(orderWizardData));
        } catch (error) {
            yield put(throwException("getOrderWizardDataSaga error: " + error));
        }
    }
}

export function* saveOrderSaga() {
    while (true) {
        try {
            const { siteId, orderRequest } = yield take(SAVE_ORDER);
            let requestData = JSON.stringify(orderRequest);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order`, method: "POST", data: requestData}});
            if (response.status === 201) {               
                const data = yield apply(response, response.json);                
                yield put(setOrder(data));
            } 
        } catch (error) {
            yield put(throwException("saveOrderSaga error: " + error));
        }
    }
}


