import { createSelector } from 'reselect';

export const orderLineItemDetailsSelector=createSelector(
    state=>state.app.get("orderLineItemDetails"),
    orderLineItemDetails=>orderLineItemDetails?orderLineItemDetails.toJS():orderLineItemDetails
);

export const orderLineItemOrderTypesSelector=createSelector(
    state=>state.app.get("orderLineItemOrderTypes"),
    orderLineItemOrderTypes=>orderLineItemOrderTypes?orderLineItemOrderTypes.toJS():orderLineItemOrderTypes
)