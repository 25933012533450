import { createSelector } from 'reselect';
import { AlertsFeature } from '../../constants';
export const alertsSelector = (feature = AlertsFeature.GLOBAL_ALERTS) => { return createSelector(
    state => state.app.getIn(["alerts",feature]),
    alerts => {
        return alerts ? alerts.toJS() : alerts;
    }
)}

export const hiddenAlertsSelector=createSelector(
    state=>state.app.get("hiddenAlerts"),
    hiddenAlerts => hiddenAlerts? hiddenAlerts.toJS(): hiddenAlerts
)
