
import { createReducer } from '../../../core/utility';
import { fromJS } from 'immutable';
import {
    SET_ORDER_DASHBOARD_DATA
  } from './OrderDashboard.actions';

export const orderDashboardData = createReducer(null, {
    [SET_ORDER_DASHBOARD_DATA](state, { dashboardData }) {
      return fromJS(dashboardData);
    },
  });
  