import React, { Component } from 'react';
import './OrderLineItem.scss'
import { Card, CardBody, CardHeader } from '../../../core/components';
import { Label, TextField, IconButton, nullRender } from 'office-ui-fabric-react';
import { injectIntl } from 'react-intl';
import EndItem from '../../../common/components/EndItem/EndItem';
import Licensable from '../../../common/components/Licensable/Licensable';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';
import { OrderConstants } from '../../constants';
import PropTypes from 'prop-types';

const today = new Date();

const propTypes = {
    orderHeader: PropTypes.object.isRequired,
    lineItem: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired
}

class OrderLineItem extends Component {
    constructor(props) {
        super(props);
        this.FirstElementRef = React.createRef();
    }

    componentWillUnmount() {
        this.FirstElementRef = null;
    }

    handleInputChange = (items) => {
        var lineItem = this.props.lineItem;
        items.forEach(item => {
            lineItem[item.field].value = item.value;
            lineItem[item.field].text = item.text;
            lineItem[item.field].error = (!item.skipValidation && item.value === '' ?
            item.field === 'EndItem' ? this.props.intl.formatMessage({ id: 'app.selectRequired', defaultMessage: "Please select" }) : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" }) : '');
            lineItem["ClientTransactionType"] = lineItem["ClientTransactionType"] === 0 ? 0 : 2;
        })
        this.props.handleChange(lineItem);
    }

    onEndItemChange(item) {
        this.handleInputChange([
            {
                field: "EndItem",
                value: item ? item.key : "",
                text: item ? item.displayText : "",
                error: item && item.key ? "" : this.props.intl.formatMessage({ id: 'app.selectRequired', defaultMessage: "Please select" })
            },
            {
                field: "LicensableItem",
                value: "",
                text: "",
                error: "",
                skipValidation: true
            }
        ]);
    }

    onLicenseEndItemChange = (licensable) => {
        this.handleInputChange([
            {
                field: "LicensableItem",
                value: licensable ? licensable.licensableItemId : "",
                text: licensable ? licensable.text : "",
                error: licensable ? "" :
                    this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" })
            },
            {
                field: "LicenseId",
                value: licensable ? licensable.licenseId : "",
                text: licensable ? licensable.licenseId : ""
            }
        ]);
    }

    handleInputCancel = (lineItemId, displayId) => {
        this.props.handleCancel(lineItemId, displayId);
    }

    onAccordionToggle = () => {
        let lineItem = this.props.lineItem;
        lineItem.isCollapsed = !lineItem.isCollapsed;
        this.props.handleChange(lineItem);
    }

    render() {
        const { lineItem, orderHeader } = this.props;
        if (!lineItem) {
            return null;
        }
        if (!lineItem.FirstElementRef && this.FirstElementRef && !lineItem.isCollapsed) {
            lineItem.FirstElementRef = this.FirstElementRef;
            this.props.setRef(lineItem);
        }

        return (
            <div id={'lineitem-card' + lineItem.DisplayId} className="lineitem-card">
                <Card>
                    <Accordion isOpen={!lineItem.isCollapsed} onAccordionToggle={this.onAccordionToggle}>
                        <AccordionHeader accordionheader={'Line Item' + lineItem.DisplayId}>
                            <CardHeader>
                                <div className="line-item-header">
                                    <span className="line-item-number">
                                        Line Item {lineItem.DisplayId}
                                        {lineItem.isCollapsed && (lineItem.EndItem.value || lineItem.Quantity.value) ? "  -" : ""}
                                    </span>
                                    {
                                        lineItem.isCollapsed && lineItem.EndItem.value &&
                                        <span className="line-item-part">
                                            {"[" + lineItem.EndItem.value + "]"}
                                        </span>
                                    }
                                    {
                                        lineItem.isCollapsed && lineItem.Quantity.value &&
                                        <span className="line-item-qty">
                                            {"Qty: " + lineItem.Quantity.value}
                                        </span>
                                    }

                                    {
                                        (this.props.isEditMode && !lineItem.isAdded) ?
                                            null :
                                            <span className="line-item-remove">
                                                <IconButton onClick={() => this.handleInputCancel(lineItem.LineItemId.value, lineItem.DisplayId)}
                                                    iconProps={{ iconName: 'Delete' }} aria-label={"Remove Line Item" + lineItem.DisplayId} />
                                            </span>
                                    }


                                </div>
                            </CardHeader>
                        </AccordionHeader>
                        <AccordionBody>
                            <CardBody>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-md6 ms-sm12">
                                        {lineItem.IsCoaPrinted && lineItem.IsCoaPrinted.value === false ?

                                            <TextField componentRef={this.FirstElementRef}
                                                label={this.props.intl.formatMessage({ id: 'orderLineItem.ARLineitemNoTitle', defaultMessage: "AR Line Item No" })}
                                                name="AR Line Item No"
                                                value={lineItem.ArlineItemNumber.value ? lineItem.ArlineItemNumber.value : ""}
                                                onChanged={(value) =>
                                                    this.handleInputChange(
                                                        [{
                                                            field: "ArlineItemNumber",
                                                            value: value,
                                                            text: value,
                                                            error: value ? value :
                                                                this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" })
                                                        }
                                                        ])} />

                                            :
                                            <div>
                                                <Label name="ARLineItemNo" id="ARLineItemNo" className="labelHeader">
                                                    {this.props.intl.formatMessage({ id: 'orderLineItem.ARLineitemNoTitle', defaultMessage: "AR Line Item No" })}
                                                </Label>
                                                <Label name="ARLineItemNoValue" id="ARLineItemNoValue" className="labelValue">{lineItem.ArlineItemNumber.value}</Label>
                                            </div>}
                                    </div>
                                    <div className="ms-Grid-col ms-md6 ms-sm12">
                                        {lineItem.IsCoaPrinted && lineItem.IsCoaPrinted.value === false ?
                                            <TextField
                                                label={this.props.intl.formatMessage({ id: 'orderLineItem.Quantity', defaultMessage: "Quantity" })}
                                                type="number" min="1" max="99999"
                                                aria-valuemin ="1"
                                                aria-valuemax="99999"
                                                required={true} name="Quantity"
                                                ariaLabel={this.props.intl.formatMessage({ id: 'orderLineItem.Quantity', defaultMessage: "Quantity" })}
                                                value={lineItem.Quantity.value ? lineItem.Quantity.value : ""}
                                                onChanged={(value) =>
                                                    this.handleInputChange(
                                                        [{
                                                            field: "Quantity",
                                                            value: value,
                                                            text: value,
                                                            error: value ? '' :
                                                            this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" })
                                                        }
                                                        ])}

                                                errorMessage={lineItem.Quantity.error ?
                                                    this.props.intl.formatMessage({ id: 'orderLineItem.Quantity', defaultMessage: "Quantity " }) +
                                                    lineItem.Quantity.error  //this.props.intl.formatMessage({ id: 'app.quantityOnlyNumbersallowedMessage', defaultMessage: " Only numbers are allowed. " })
                                                    :''
                                                }
                                            /> :
                                            <div>
                                                <Label name="Quantity" id="Quantity" className="labelHeader">
                                                    {this.props.intl.formatMessage({ id: 'orderLineItem.Quantity', defaultMessage: "Quantity" })}
                                                </Label>
                                                <Label name="QuantityValue" id="QuantityValue" className="labelValue">{lineItem.Quantity.value}</Label>
                                            </div>}
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-md6 ms-sm12">
                                        {
                                            lineItem.isEditMode === true ?
                                                <div>
                                                    <Label name="EndItemLabel" id="EndItemLabel" className="labelHeader">
                                                        {this.props.intl.formatMessage({ id: 'orderLineItem.EndItemTitle', defaultMessage: 'End Item' })}
                                                    </Label>
                                                    <Label name="EndItemValue" id="EndItemValue" className="labelValue">{lineItem.EndItem.text}</Label>
                                                </div> :
                                                <EndItem id="EndItem"
                                                    name="EndItem"
                                                    inactiveEndItem={true}
                                                    isOrderType={true}
                                                    siteIds={orderHeader ? [orderHeader.SiteId.value] : []}
                                                    customerType={orderHeader ? orderHeader.SoldToType.value : ""}
                                                    customerId={orderHeader ? orderHeader.CustomerId.value : ""}
                                                    lineItemType={lineItem ? lineItem.ShipmentType.text : ""}
                                                    compareDate={today}
                                                    selectedText={lineItem.EndItem.text}
                                                    errorMessage={lineItem.EndItem.error  ? lineItem.EndItem.error + " " + "EndItem" : ""}
                                                    onSelect={(value) => { this.onEndItemChange(value) }}
                                                    isRequired={true}
                                                />
                                        }
                                    </div>
                                    {
                                        this.props.orderHeader.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE ?
                                            <div className="ms-Grid-col ms-md6 ms-sm12">
                                                {lineItem.isEditMode === true ?
                                                    lineItem.LicensableItem.text &&
                                                    <div>
                                                        <Label name="LicensableItemLabel" id="LicensableItemLabel" className="labelHeader">
                                                            {this.props.intl.formatMessage({ id: 'orderLineItem.LicensableTitle', defaultMessage: 'LicensableItem' })}
                                                        </Label>
                                                        <Label name="LicensableItemValue" id="LicensableItemValue" className="labelValue">{lineItem.LicensableItem.text}</Label>
                                                    </div> :
                                                    <Licensable name="LicensableItem"
                                                        required={true}
                                                        label={this.props.intl.formatMessage({ id: 'orderLineItem.LicensableTitle', defaultMessage: 'LicensableItem' })}
                                                        id="LicensableItem"
                                                        customerId={this.props.orderHeader && this.props.orderHeader.CustomerId.value}
                                                        customerType={this.props.orderHeader ? this.props.orderHeader.SoldToType.value : ""}
                                                        endItemId={this.props.lineItem && this.props.lineItem["EndItem"].value}
                                                        lineItemType={lineItem ? lineItem.ShipmentType.text : ""}
                                                        compareDate={today}
                                                        selectedKey={lineItem.LicenseId.value + lineItem.LicensableItem.value}
                                                        handleSelect={(data) => data && this.onLicenseEndItemChange(data)}
                                                        errorMessage={lineItem.LicensableItem.error ? this.props.intl.formatMessage({ id: 'orderLineItem.LicensableTitle', defaultMessage: 'LicensableItem' }) + " " + lineItem.LicensableItem.error : ""}
                                                    />
                                                }
                                            </div> : nullRender()}
                                </div>
                            </CardBody>
                        </AccordionBody>
                    </Accordion>


                </Card>
            </div>
        );
    }
}

OrderLineItem.propTypes = propTypes;
export default injectIntl(OrderLineItem)






