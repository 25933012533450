import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as SearchShipmentActions from './SearchShipment.actions';

export const searchShipmentResult=createReducer(null,{
  [SearchShipmentActions.SET_SEARCH_SHIPMENT_RESULT](state,{shipments}){
    return fromJS(shipments)
  }
})

export const searchShipmentCriteria=createReducer(null,{ 
  [SearchShipmentActions.SAVE_SEARCH_SHIPMENT_CRITERIA](state,{searchCriteria}){
    return fromJS(searchCriteria)
  }
})