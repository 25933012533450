import React, { Component } from 'react';
import './EditOrder.scss'
import { injectIntl } from 'react-intl';
import { Label, DefaultButton, TextField } from 'office-ui-fabric-react';
import { WizardStep, WizardStepFooter } from '../../../core/components';
import OrderLineItems from '../OrderLineItem/OrderLineItems';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';
import { OrderConstants } from '../../../../app/order/constants';
import { TabIndexes } from '../../../common/constants';

export class EditOrderInfo extends Component {
    isStateValid = () => {
        let isValid = true;
        let lineItems = this.props.order.LineItems;
        if (this.props.order.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_INT_VALUE) {
            if (this.props.order.PoNumber.value === '') isValid = false;
        }
        lineItems.forEach((data) => {
            Object.keys(data).forEach(key => {
                if ((key === "ShipmentType" || key === "Quantity" || key === "EndItem" ||
                    key === "LicensableItem") && data[key].value === '') {
                    isValid = false;
                }
            })
        });
        return isValid;
    }

    validateItems = (lineItems) => {
        var noOfInValidItems = 0;

        lineItems.forEach((item) => {
            noOfInValidItems = noOfInValidItems + this.validateItem(item) ? 0 : 1;
        });
        if (noOfInValidItems === 0)
            return true;
        else {
            return false;
        }
    }

    validateItem = (item) => {
        var noOfErrors = 0;
        Object.keys(item).forEach(key => {
            if ((key === "ShipmentType" || key === "Quantity" || key === "EndItem" || key === "LicensableItem") && item[key].value === '') {
                item[key].error = this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" });
                noOfErrors++;
            }
            else if (key === "Quantity") {
                let startRange = item.isEditMode ? 0 : 1;
                if (!Number.isInteger(Number(item[key].value)) || (!(item[key].value >= startRange && item[key].value <= 99999))) {
                    item[key].error = `${this.props.intl.formatMessage({ id: 'app.invalidIntegerValueMessage', defaultMessage: "Please enter integer value between" })} ${startRange} - 99999`;
                    noOfErrors++;
                }
            }
        });
        return noOfErrors === 0;
    }

    onSubmitClick = () => {
        var lineItems = this.props.order.LineItems;

        if (this.validateItems(lineItems) && this.isStateValid()) {
            this.props.nextPage();
        }
        else {
            this.props.handleChangeInLineItem({ lineItems: lineItems });
        }
    }

    render() {
        return (
            <div className="edit-order-info">
                <div className="edit-order-header-info">
                    <Accordion>
                        <AccordionHeader accordionheader={this.props.intl.formatMessage({ id: 'orderHeader.HeaderTitle', defaultMessage: 'Order Header' })}>
                            <div className="header-text">
                                <h3>{this.props.intl.formatMessage({ id: 'orderHeader.HeaderTitle', defaultMessage: 'Order Header' })}</h3>
                            </div>
                        </AccordionHeader>
                        <AccordionBody>
                            <div className="ms-Grid-row no-margin">
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="ReportedByLabel" id="ReportedByLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'common.ARSiteTitle', defaultMessage: 'AR Site' })}</Label>
                                    <Label name="ReportedByValue" id="ReportedByValue" className="labelValue">{this.props.order.SiteId.text}</Label>
                                </div>
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="AROrderLabel" id="AROrderLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.OrderIdTitle', defaultMessage: 'AR Order#' })}</Label>
                                    <Label name="AROrderValue" id="AROrderValue" className="labelValue">{this.props.order.OrderId.value}</Label>
                                </div>
                            </div>
                            <div className="ms-Grid-row no-margin">
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="OrderTypeHeaderLabel" id="OrderTypeHeaderLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'order.OrderTypeTitle', defaultMessage: 'Order Type' })}</Label>
                                    <Label name="OrderTypeHeaderValue" id="OrderTypeHeaderValue" className="labelValue">{this.props.order.OrderType.text}</Label>
                                </div>
                            </div>
                            <div className="ms-Grid-row no-margin">
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="SoldToTypeLabel" id="SoldToTypeLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.SoldToTitle', defaultMessage: 'Sold To' })}</Label>
                                    <Label name="SoldToTypeValue" id="SoldToTypeValue" className="labelValue">{this.props.order.SoldToType.text}</Label>
                                </div>
                            </div>
                            <div className="ms-Grid-row no-margin">
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="OrganizationLabel" id="OrganizationLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'common.SoldToOrgTitle', defaultMessage: 'Sold-To-Organization' })}</Label>
                                    <Label name="OrganizationValue" id="OrganizationValue" className="labelValue">{this.props.order.SoldToId.text}</Label>
                                </div>
                                <div className="ms-Grid-col ms-md4">
                                    <Label name="BrandNameLabel" id="BrandNameLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.BrandNameTitle', defaultMessage: 'Brand Name' })}</Label>
                                    <Label name="BrandNameValue" id="BrandNameValue" className="labelValue">{this.props.order.BrandId.text}</Label>
                                </div>
                                {
                                    this.props.order.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_INT_VALUE &&
                                    <div className="ms-Grid-col ms-md4">
                                        {
                                            (this.props.order.Status.value === OrderConstants.SUBMITTED_STATUS_CODE || this.props.order.Status.value === OrderConstants.SUBMITTED_ACCEPT_STATUS_CODE) ?
                                                <div>
                                                    <Label name="PoNumberLabel" id="PoNumberLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.PONumberTitle', defaultMessage: 'OEM PO Number' })}</Label>
                                                    <Label name="PoNumberValue" id="PoNumberValue" className="labelValue">{this.props.order.PoNumber.value}</Label>
                                                </div>
                                                :
                                                <TextField name="PoNumber" label={this.props.intl.formatMessage({ id: 'orderHeader.PONumberTitle', defaultMessage: 'OEM PO Number' })} placeholder={this.props.intl.formatMessage({ id: 'app.oemponumberplaceholder', defaultMessage: 'Please enter PO Number' })} value={this.props.order.PoNumber.value}
                                                    required={true}
                                                    onChanged={(value) => this.props.handleChange([{
                                                        field: "PoNumber",
                                                        value: value,
                                                        text: value
                                                    }])}
                                                    errorMessage={this.props.order.PoNumber.error} />
                                        }
                                    </div>
                                }
                            </div>
                            {
                                this.props.order.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_INT_VALUE &&
                                <div>
                                    <div className="ms-Grid-row no-margin">
                                        {
                                            this.props.order.PoDate && this.props.order.PoDate.value &&
                                            <div className="ms-Grid-col ms-md4">
                                                <Label name="PODateLabel" id="PODateLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.PODateTitle', defaultMessage: 'PO Date' })}</Label>
                                                <Label name="PODateValue" id="PODateValue" className="labelValue">{this.props.intl.formatDate(new Date(this.props.order.PoDate.value))}</Label>
                                            </div>
                                        }
                                        {
                                            this.props.order.CommittedDate && this.props.order.CommittedDate.value &&
                                            <div className="ms-Grid-col ms-md4">
                                                <Label name="CommittedDateLabel" id="CommittedDateLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.CommDateTitle', defaultMessage: 'Committed Date' })}</Label>
                                                <Label name="CommittedDateValue" id="CommittedDateValue" className="labelValue">{this.props.intl.formatDate(new Date(this.props.order.CommittedDate.value))}</Label>
                                            </div>
                                        }
                                        {
                                            this.props.order.ReqShipDate && this.props.order.ReqShipDate.value &&
                                            <div className="ms-Grid-col ms-md4">
                                                <Label name="ReqShipDateLabel" id="ReqShipDateLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.ReqShipDateTitle', defaultMessage: 'Requested Ship Date' })}</Label>
                                                <Label name="ReqShipDateValue" id="ReqShipDateValue" className="labelValue">{this.props.intl.formatDate(new Date(this.props.order.ReqShipDate.value))}</Label>
                                            </div>
                                        }
                                    </div>
                                    {this.props.order.ShipToType && this.props.order.ShipToType.value && this.props.order.ShipToType.value !== '0' &&
                                        <div className="ms-Grid-row no-margin">
                                            <div className="ms-Grid-col ms-md4">
                                                <Label name="ShipToTypeLabel" id="ShipToTypeLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.ShipToTitle', defaultMessage: 'Ship To' })}</Label>
                                                <Label name="ShipToTypeValue" id="ShipToTypeValue" className="labelValue">{this.props.order.ShipToType.text}</Label>
                                            </div>
                                            <div className="ms-Grid-col ms-md4">
                                                <Label name="ShipToLocationLabel" id="ShipToLocationLabel" className="labelHeader">{this.props.intl.formatMessage({ id: 'orderHeader.selectshiptoloctitle', defaultMessage: 'Ship - To - Location' })}</Label>
                                                <Label name="ShipToLocationValue" id="ShipToLocationValue" className="labelValue">{this.props.order.ShipToId.text}</Label>
                                            </div>
                                        </div>}
                                </div>
                            }
                        </AccordionBody>
                    </Accordion>
                </div>

                <div className="edit-order-lineitems">
                    <OrderLineItems lineItems={this.props.order.LineItems} isStateValid={this.props.isStateValid}
                        handleChange={this.props.handleChangeInLineItem} orderHeader={this.props.order} validateItems={this.validateItems} editMode={true}>
                    </OrderLineItems>
                </div>
                <div className="row-space-top">
                    <WizardStep>
                        <WizardStepFooter>
                            <DefaultButton
                                className="iconRight margin-top10"
                                primary={true}
                                data-automation-id="test"
                                disabled={false}
                                text={this.props.intl.formatMessage({
                                    id: 'app.cancel',
                                    defaultMessage: 'Cancel',
                                })}
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={this.props.cancelPage}
                                allowDisabledFocus={true} />
                            <DefaultButton
                                className="iconRight margin-top10"
                                primary={true}
                                data-automation-id="test"
                                disabled={false}
                                text={this.props.intl.formatMessage({
                                    id: 'app.next',
                                    defaultMessage: 'Next',
                                })}
                                iconProps={{ iconName: 'ChevronRightSmall' }}
                                onClick={() => { this.onSubmitClick() }}
                                allowDisabledFocus={true} />
                        </WizardStepFooter>
                    </WizardStep>
                </div>
            </div>);
    }
}

export default injectIntl(EditOrderInfo);