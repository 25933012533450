import React, { Component } from 'react';
import { connect } from "react-redux";
import HiddenAlert from './HiddenAlert';
import { hiddenAlertsSelector } from './Alert.selectors';


class HiddenAlerts extends Component {   
    render() {
        let { alerts, timeOut } = this.props;
        if (!alerts) return null;
        if (!timeOut) timeOut = 2000;
        return (
            <React.Fragment>
                {alerts && alerts.length > 0 && alerts.map((alert, id) => <HiddenAlert key={alert.id} {...alert} dismissTime={timeOut} />)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        alerts: hiddenAlertsSelector(state)
    }
};

export default connect(mapStateToProps, null)(HiddenAlerts);