import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { GET_ORDER_DETAILS, setOrderDetails, SET_ORDER_APPROVAL_STATUS } from './OrderDetails.actions';

import {showAlert} from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'
export function* getOrderDetailsSaga() {
    while (true) {
        try {
            const { siteId, msOrderId } = yield take(GET_ORDER_DETAILS);  
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order/${msOrderId}`, method:"GET"}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(setOrderDetails(data));
            }
        } catch (error) {
            yield put(throwException("getOrderSaga error: " + error));          
        }
    }
}


export function* setOrderApprovalStatus() {
    while (true) {
        try {
            const { siteId, msOrderId, status } = yield take(SET_ORDER_APPROVAL_STATUS);   
            const config = yield ApplicationConfig.config;
            const operation = status === true ? 'approve' : 'reject';
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order/${msOrderId}/${operation}`, method: "PUT"}});
            if (response.status === 200) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Order status is updated successfully"]}));
                const orderData = yield call(InvokeUrl,{ Config : {url: `${config.web_api_path}/api/coa/v1/${siteId}/order/${msOrderId}`,method: "GET"}});
                const orderObject = yield apply(orderData, orderData.json);
                yield put(setOrderDetails(orderObject));
            }
        } catch (error) {
            yield put(throwException("setOrderDetails error: " + error));         
        }
    }
}


