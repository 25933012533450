import { MsalAuthProvider } from "./MsalAuthProvider";
import PropTypes from "prop-types";
import { MsalAuthProviderConfigShape } from "../Auth.models";

export class MsalAuthProviderFactory {
	config = PropTypes.objectOf(MsalAuthProviderConfigShape);
	constructor(config) {
		this.config = config;
	}

	getAuthProvider = () => {
		return new MsalAuthProvider(this.config);
	};
}
