import React, { PureComponent } from 'react';
import { Route, Redirect,withRouter } from 'react-router-dom';
import { RedirectPath } from '../../constants';

class RedirectRoute extends PureComponent {
  
    render() {
        const { location,...rest } = this.props;
        const {pathname} = location;

        const [,restPath] = pathname.split(RedirectPath);
        
        return <Route {...rest} render={(props) => (
             <Redirect to={restPath} />
        )} />

    }
}

export default withRouter(RedirectRoute)