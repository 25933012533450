import ReactAI from 'react-appinsights';
import { THROW_EXCEPTION, TRACK_TRACE,TRACK_METRIC} from './Logger.actions';
import { take } from 'redux-saga/effects';
import { AppinsightsLogFilter } from '../../constants/CoreConstants';

export function* throwExceptionAppinsightsSaga(){
    while(true)
       { var appInsights = ReactAI.ai();
        const {exceptionMessage} = yield take(THROW_EXCEPTION);
        appInsights.trackException(AppinsightsLogFilter.FILTER_MESSAGE + exceptionMessage)
    }
}

export function* trackTraceAppinsightsSaga(){
    while(true){
    var appInsights = ReactAI.ai();
    const {traceMessage} = yield take(TRACK_TRACE);
    appInsights.trackTrace(AppinsightsLogFilter.FILTER_MESSAGE+traceMessage);}
}

export function* trackMetricAppinsightsSaga(){
    while(true){
    var appInsights = ReactAI.ai();
    const {trackMetric} = yield take(TRACK_METRIC);
    appInsights.trackMetric(AppinsightsLogFilter.FILTER_MESSAGE.trackMetric.Name + trackMetric.Value);}
}
