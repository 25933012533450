import React, { Component } from 'react';
import './SearchFilter.scss'
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox } from 'office-ui-fabric-react';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';

const propTypes = {
    OrderStatusList: PropTypes.array,
    OrderDurations: PropTypes.array,
    onFilterChange: PropTypes.func
}

export class SearchFilter extends Component {  
    
    constructor(props){
        super(props);

        this.state = {
            OrderStatusList: this.props.OrderStatusList,
            OrderDurations: this.props.OrderDurations
        }
    }



    
    getStatusFilter =(filterName, items) => {
        return(
            <div className="search-filter">
                <Accordion>
                    <AccordionHeader accordionheader={filterName}>
                        <div className="search-filter-name">
                            <span>{filterName}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="search-filter-items">
                            {
                                items && items.map((item,index)=>
                                    <div key={index} className="search-filter-item">   
                                        <div className="search-filter-item-checkbox"> 
                                            <Checkbox label={item.Status}  />
                                        </div>      
                                        <div className="search-filter-item-count"> 
                                            <span>0</span>
                                        </div>                             
                                    </div>
                                )
                            }                    
                        </div>    
                    </AccordionBody>
                </Accordion>                   
            </div>          
        );
    }

    getMonthFilter = (filterName, items) =>{
        return(                   
            <div className="search-filter">
                <Accordion>
                    <AccordionHeader accordionheader={filterName}>
                        <div className="search-filter-name">
                            <span>{filterName}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="search-filter-items">
                            {
                                items && items.map((item, index)=>
                                    <div key={index} className="search-filter-item">   
                                        <div className="search-filter-item-checkbox"> 
                                            <Checkbox label={item.DurationKey}  />
                                        </div>      
                                        <div className="search-filter-item-count"> 
                                            <span>0</span>
                                        </div>                             
                                    </div>
                                )
                            }                    
                        </div>    
                    </AccordionBody>
                </Accordion>                   
            </div>            
        );
    }
       
    render() {
        return (
            <div className="search-filters">
                <div className="search-filters-text">
                    <span>{this.props.intl.formatMessage({ id: 'searchOrder.SearchTypeddddd', defaultMessage: 'Filter' })}</span>
                </div>
                {
                    this.state.OrderStatusList && this.getStatusFilter(
                        this.props.intl.formatMessage({ id: 'searchOrder.SearchTypdsfdsfdsfe', defaultMessage: 'Status' }),
                        this.state.OrderStatusList)
                }
                {
                    this.state.OrderDurations && this.getMonthFilter(
                        this.props.intl.formatMessage({ id: 'searchOrder.SearchTypeddddd', defaultMessage: 'Submitted Date' }),
                        this.state.OrderDurations)
                }                 
            </div>
        );
    }
}

SearchFilter.propTypes = propTypes;

export default injectIntl(SearchFilter);
