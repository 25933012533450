import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as searchAgreementActions from './SearchAgreement.actions';

export const agreementSearchResult = createReducer(null,{
    [searchAgreementActions.SET_SEARCH_AGREEMENT_RESULT](state,{agreements}){
      return fromJS(agreements)
    }
  })

export const agreementCriteriaInfo = createReducer(null,{
    [searchAgreementActions.SAVE_AGREEMENT_CRITERIA](state,{agreementCriteria}){
      return fromJS(agreementCriteria)
    }
  })

