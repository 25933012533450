import {makeActionCreator} from '../../core/utility/makeActionCreator'

export const INITIALIZE_APP ="common/actions/INITIALIZE_APP";
export const initializeApp = makeActionCreator(INITIALIZE_APP, false);

export const SET_TITLE ="common/actions/INITIALIZE_APP";
export const setTitle = makeActionCreator(SET_TITLE, false,"title");

export const INIT_APPINSIGHTS = "common/actions/INIT_APPINSIGHTS";
export const initAppInsights = makeActionCreator(INIT_APPINSIGHTS, false);

export const SET_USER_ROLES  = "common/actions/SET_USER_ROLES";
export const setUserRoles  = makeActionCreator(SET_USER_ROLES, false,"roles");

export const GET_ROLES_MAPPING  = "common/actions/GET_ROLES_MAPPING";
export const getRolesMapping  = makeActionCreator(GET_ROLES_MAPPING, false);

export const SET_ROLES_MAPPING  = "common/actions/SET_ROLES_MAPPING";
export const setRolesMapping  = makeActionCreator(SET_ROLES_MAPPING, false,"rolesMapping");


export const SET_CONTACT_EMAIL  = "common/actions/SET_CONTACT_EMAIL";
export const setContactEmail  = makeActionCreator(SET_CONTACT_EMAIL, false,"contactEmail");



export const GET_CONTACT_EMAIL  = "common/actions/GET_CONTACT_EMAIL";
export const getContactEmail  = makeActionCreator(GET_CONTACT_EMAIL, false);

