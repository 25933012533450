import fetch from "isomorphic-fetch";

class ApplicationConfig {
    constructor() {
        this.config = this.getConfigData()
    }

    makeApiCall() {
        return fetch(window.location.origin + '/app.config.json',{method:"GET","Pragma":"no-cache"})
        .then(response => ( response.json() ))
        .catch(error =>  ({
            "web_api_path": "https://localhost:5555"
        }));
    }

    getConfigData() {
        return this.makeApiCall();
    }
}

let instance = new ApplicationConfig();
export default instance;