
import { createReducer } from '../../../core/utility';
import {   
  SET_ORDER_DETAILS
} from './OrderDetails.actions';
import { fromJS } from 'immutable';

export const orderInfo = createReducer(null, {
  [SET_ORDER_DETAILS](state, { orderInfo }) {
    return fromJS(orderInfo);
  }  
});
