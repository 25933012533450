import { createReducer } from '../../utility/createReducer';
import uuidv4 from 'uuid/v4';
import { fromJS } from 'immutable';
import { SHOW_ALERT, HIDE_ALERT, HIDE_FEATURE_ALERTS, SHOW_HIDDEN_ALERT, HIDE_HIDDEN_ALERT} from './Alert.actions';
import { AlertsFeature , AlertsTypes } from '../../constants';

export const alerts = createReducer(null, {
    [SHOW_ALERT](state, { alert }) {
        alert.isGeneric = alert.isGeneric || false ;
        alert.id = uuidv4();
        const feature = alert.feature ? alert.feature : AlertsFeature.GLOBAL_ALERTS;
        if(!alert.feature) alert.feature = AlertsFeature.GLOBAL_ALERTS;
        if (state === null || state === undefined) return fromJS({ [feature]: [alert] });
        state = state.toJS();
        const existingAlerts = state[feature];
        if (existingAlerts === null || existingAlerts === undefined || AlertsTypes.SUCCESS === alert.type) { state[feature] = [alert]; return fromJS(state); }
        if(!alert.isGeneric || !existingAlerts.some(x=>x.isGeneric))
           state[feature].push(alert);
        return fromJS(state);
    },
    [HIDE_ALERT](state, { alert }) {
        if (state === null || state === undefined) return fromJS({});
        var featureArray = state.get(alert.feature);
        if (!featureArray) return fromJS(state);
        var newArray = featureArray.filter(function (a) { return a.get("id") !== alert.id })
        state = state.set(alert.feature, newArray)
        return state;
    },
    [HIDE_FEATURE_ALERTS](state, { feature }) {
        feature = feature ? feature : AlertsFeature.GLOBAL_ALERTS;
        if (state === null || state === undefined) return fromJS({});
        var featureArray = state.get(feature);
        if (!featureArray) return fromJS(state);
        state = state.set(feature, fromJS([]))
        return state;
    }
});

export const hiddenAlerts = createReducer(null, {
    [SHOW_HIDDEN_ALERT](state, { alert }) {
        alert.id = uuidv4();       
        if (state === null || state === undefined)
            return fromJS([alert]);
        state = state.toJS();      
        state.push(alert);
        return fromJS(state);
    },
    [HIDE_HIDDEN_ALERT](state, { alert }) {
        if (state === null || state === undefined) 
            return fromJS([]);
        state = state.toJS();       
        var newArray = state.filter(item=> item.id!==alert.id);
        return fromJS(newArray);
    },
});



