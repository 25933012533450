import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as barcodeActions from './Barcode.actions';

export const barcodeDescriptions = createReducer(null, {
  [barcodeActions.SET_BARCODE_DESCRIPTIONS](state, { barcodeDescriptions }) {
    return fromJS(barcodeDescriptions)
  }
})

export const barcodeMapping = createReducer(null, {
  [barcodeActions.SET_BARCODE_MAPPING](state, { barcodeMapping }) {
    return fromJS(barcodeMapping)
  }
})