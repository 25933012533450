import React from 'react';
import { injectIntl } from 'react-intl';
import { Label, ActionButton } from 'office-ui-fabric-react';
import { AuthorizedComponent } from '../Auth';
import { TabIndexes } from '../../constants';

const DetailsHeader = (props) => {
    return (
        <div className="order-shipment-details-header" aria-label={props.headerText} >
            <h1>{props.headerText}</h1>
            <div className="ms-Grid-row order-shipment-details-sub-header">
                <div className="ms-Grid-col ms-md10">
                    <Label name="MSOrderDetails" id="MSOrderDetailsLabel" className="labelValue">{props.msIdFieldText}:
                    {" " + props.msId} |
                    <span className="order-shipment-status">{" " + props.status}</span>
                    </Label>
                </div>
                <div className="ms-Grid-col ms-md2">
                    <div className="actions">
                        {props.isEdit === true &&
                            <AuthorizedComponent authorizedRoles={props.authorizedEditRoles}>
                                <ActionButton
                                    data-automation-id="icon-edit"
                                    iconProps={{ iconName: 'Edit' }}
                                    text={props.intl.formatMessage({
                                        id: 'app.edit',
                                        defaultMessage: 'Edit',
                                    })}
                                    className="details-action-button"
                                    onClick={props.edit}
                                />
                            </AuthorizedComponent>
                        }
                    </div>
                </div>
            </div>
        </div>


    );
}

export default injectIntl(DetailsHeader);