import { takeLatest, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as LineItemActions from './EndItem.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException} from '../../../core/components/Logger/Logger.actions'

const getFilterUrl = (aradSiteIds, customerId, inactiveEndItem, searchText, isOrderType, lineItemType, compareDate, ChannelType) => {
    let filterUrl;
    let siteIdUrl;
    aradSiteIds && aradSiteIds.forEach((item) => {
        if (siteIdUrl) {
            siteIdUrl = siteIdUrl + "&AradSiteId=" + item
        }
        else {
            siteIdUrl = "AradSiteId=" + item
        }
    });
    filterUrl = siteIdUrl;
    if (customerId) {
        filterUrl = filterUrl ? filterUrl + "&customerId=" + customerId : "customerId=" + customerId;
    }

    if (inactiveEndItem) {
        filterUrl = filterUrl ? filterUrl + "&isActiveEnditemsOnly=" + inactiveEndItem : "isActiveEnditemsOnly=" + inactiveEndItem;
    }

    if (searchText) {
        filterUrl = filterUrl ? filterUrl + "&SearchString=" + searchText : "SearchString=" + searchText;
    }

    if (isOrderType) {
        filterUrl = filterUrl ? filterUrl + "&isOrder=" + isOrderType : "isOrder=" + isOrderType;
    }

    if (lineItemType) {
        filterUrl = filterUrl ? filterUrl + "&lineItemType=" + lineItemType : "lineItemType=" + lineItemType;
    }

    if (compareDate) {
        filterUrl = filterUrl ? filterUrl + "&compareDate=" + compareDate : "compareDate=" + compareDate;
    }

    if (ChannelType) {
        filterUrl = filterUrl ? filterUrl + "&ChannelType=" + ChannelType : "ChannelType=" + ChannelType;
    }

    return filterUrl;
}

export function* EndItemListener() {
    yield takeLatest(LineItemActions.GET_LINE_ITEM_ENDITEM, EndItemSaga);
}

function* EndItemSaga(input) {
    try {
        const { aradSiteIds, customerId, inactiveEndItem, searchText, isOrderType, lineItemType, compareDate, ChannelType } = yield (input);

        const config = yield ApplicationConfig.config;
        const filterUrl = yield getFilterUrl(aradSiteIds, customerId, inactiveEndItem, searchText, isOrderType, lineItemType, compareDate, ChannelType);
        const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/enditems?${filterUrl}`, method:'GET'}});
        if (response.status === 200) {
            const data = yield apply(response, response.json);
            var EndItemData = data.map((d) => {
                return {
                    key: d.ItemId,
                    displayText: d.Name
                };
            });
            yield put(LineItemActions.setLineItemEndItem(EndItemData));
        } 
    } catch (error) {
        yield put(throwException("LineItem EndItem error:" + error))
    }
}

