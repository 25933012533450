import { makeActionCreator } from '../../../core/utility';

export const GET_SHIPMENT_WIZARD_DATA = "Shipment/Components/CreateShipment/GET_SHIPMENT_WIZARD_DATA";
export const getShipmentWizardData = makeActionCreator(GET_SHIPMENT_WIZARD_DATA, false);

export const SET_SHIPMENT_WIZARD_DATA = "Shipment/Components/CreateShipment/SET_SHIPMENT_WIZARD_DATA";
export const setShipmentWizardData = makeActionCreator(SET_SHIPMENT_WIZARD_DATA, false, "wizardData");

export const SET_SHIPMENT_WIZARD_STEP_DATA = "Shipment/Components/CreateShipment/SET_SHIPMENT_WIZARD_STEP_DATA";
export const setShipmentWizardStepData = makeActionCreator(SET_SHIPMENT_WIZARD_STEP_DATA, false, "stepData");

export const SET_SHIPMENT_WIZARD_CURRENT_STEP = "Shipment/Components/CreateShipment/SET_SHIPMENT_WIZARD_CURRENT_STEP";
export const setShipmentWizardCurrentStep = makeActionCreator(SET_SHIPMENT_WIZARD_CURRENT_STEP, false, "stepData");

export const SHIPMENT_WIZARD_STEP_NEXT = "Shipment/Components/CreateShipment/SHIPMENT_WIZARD_STEP_NEXT";
export const shipmentWizardStepNext = makeActionCreator(SHIPMENT_WIZARD_STEP_NEXT, true, "stepData");

export const SHIPMENT_WIZARD_STEP_PREVIOUS = "Shipment/Components/CreateShipment/SHIPMENT_WIZARD_STEP_PREVIOUS";
export const shipementWizardStepPrevious = makeActionCreator(SHIPMENT_WIZARD_STEP_PREVIOUS, true, "stepData");

export const SHIPMENT_WIZARD_SET_STEP = "Shipment/Components/CreateShipment/SHIPMENT_WIZARD_SET_STEP";
export const shipmentWizardSetStep = makeActionCreator(SHIPMENT_WIZARD_SET_STEP, true, "stepData");

export const SAVE_SHIPMENT ="Shipment/components/CreateShipment/SAVE_SHIPMENT";
export const saveShipment = makeActionCreator(SAVE_SHIPMENT,false, "siteId", "shipmentRequest");

export const SET_SHIPMENT ="Shipment/components/CreateShipment/SET_SHIPMENT";
export const setShipment = makeActionCreator(SET_SHIPMENT,false,"shipment");



