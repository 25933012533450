import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import { SET_SEARCH_SHIPTO_RESULT, SET_VALIDATE_SHIPTO, SET_SAVE_SHIPTO_OEPRATION_COMPLETION_STATUS, SET_SHIPTO_BY_CUSTOMERSITEID } from './SearchShipTo.actions';

export const searchShipToResult = createReducer(null, {
  [SET_SEARCH_SHIPTO_RESULT](state, { searchShipToResult }) {
    return fromJS(searchShipToResult);
  },
});

export const isDuplicateShipTo = createReducer(null, {
  [SET_VALIDATE_SHIPTO](state, { isDuplicateShipTo }) {
    return fromJS(isDuplicateShipTo);
  }
});

export const saveShipToOperationCompleted = createReducer(null, {
  [SET_SAVE_SHIPTO_OEPRATION_COMPLETION_STATUS](state, { saveShipToOperationCompleted }) {
    return fromJS(saveShipToOperationCompleted)
  }
})

export const customerSiteDetails = createReducer(null, {
  [SET_SHIPTO_BY_CUSTOMERSITEID ](state, { customerSiteDetails }) {
    return fromJS(customerSiteDetails)
  }
})