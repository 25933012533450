import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { GET_SHIPMENT_DASHBOARD_DATA,setShipmentDashboardData } from './ShipmentDashboard.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';


export function* getShipmentDashboardDataSaga() {
    while (true) {
        try {
            const { siteIds, duration } = yield take(GET_SHIPMENT_DASHBOARD_DATA);        
            const config = yield ApplicationConfig.config;

            let siteIdUrl;
            siteIds && siteIds.forEach((item)=>{     
                if(siteIdUrl){
                    siteIdUrl = siteIdUrl + "&siteId=" + item
                }
                else{
                    siteIdUrl = "siteId=" + item
                }                
            });
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/shipment/summary?${siteIdUrl}&StartDate=${duration.StartDate}&EndDate=${duration.EndDate}`, method:"GET"}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(setShipmentDashboardData(data));
            }else {
                yield put(setShipmentDashboardData(null));
               
            }           
        } catch (error) {
            yield put(throwException("setShipmentDashboardData error: " + error));
            yield put(setShipmentDashboardData(null));         
        }
    }
}


