import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from '../../../core/components/AutoComplete'
import { getShipToOrgs } from './OrderHeader.actions';
import { shipToOrgsSelector } from './OrderHeader.selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
//import { OrderConstants } from '../../constants';

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    selectedText: PropTypes.string,
    errorMessage: PropTypes.string,
    onSelect: PropTypes.func,
    siteId: PropTypes.string,
    customerId: PropTypes.string
}

class ShipToList extends Component {  

    fetchData = async (searchText, resolve, reject) => {       
        this.props.getShipToOrgs(this.props.siteId, this.props.customerId, searchText);  
    }

    render = () => {
        if (!(this.props.siteId && this.props.customerId)) {
            return null;
        }
        return (
            <AutoComplete
                name="ShipToLoc"
                label={this.props.label}
                isRequired={this.props.isRequired}
                placeholder={this.props.placeholder}
                defaultDisplayText={this.props.selectedText}
                errorMessage={this.props.errorMessage}
                onSearch={(searchText) => { this.fetchData(searchText) }}
                items={this.props.shipToOrgs?this.props.shipToOrgs:[]}               
                onSelect={this.props.onSelect}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        shipToOrgs: shipToOrgsSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getShipToOrgs(siteId, customerId, searchText) {
        dispatch(getShipToOrgs(siteId, customerId, searchText))
    }
});

ShipToList.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShipToList));