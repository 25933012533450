import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as ShipmentLineItemActions from './ShipmentLineItem.actions';

export const shipmentTypeLineItem=createReducer(null,{
  [ShipmentLineItemActions.SET_SHIPMENT_TYPE_LINE_ITEM](state,{shipmentTypeLineItem}){
    return fromJS(shipmentTypeLineItem);
  }
});

export const shipmentLineItemComponents=createReducer(null,{
  [ShipmentLineItemActions.SET_SHIPMENT_LINE_ITEM_COMPONENT](state,{shipmentLineItemComponents}){
    return fromJS(shipmentLineItemComponents);
  }
});