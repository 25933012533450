import {makeActionCreator} from '../../../core/utility'

export const GET_ORDER_TYPES ="order/components/OrderHeader/GET_ORDER_TYPES";
export const getOrderTypes = makeActionCreator(GET_ORDER_TYPES, false);
export const SET_ORDER_TYPES = "order/components/OrderHeader/SET_ORDER_TYPES";
export const setOrderTypes = makeActionCreator(SET_ORDER_TYPES, false, "orderTypes");

export const GET_SOLDTO_TYPES ="order/components/OrderHeader/GET_SOLDTO_TYPES";
export const getSoldToTypes = makeActionCreator(GET_SOLDTO_TYPES, false);
export const SET_SOLDTO_TYPES = "order/components/OrderHeader/SET_SOLDTO_TYPES";
export const setSoldToTypes = makeActionCreator(SET_SOLDTO_TYPES, false, "soldtotypes");


export const GET_SOLDTO_BRANDS ="order/components/OrderHeader/GET_SOLDTO_BRANDS";
export const getSoldToBrands = makeActionCreator(GET_SOLDTO_BRANDS, false, "siteId", "customerId", "soldToType");
export const SET_SOLDTO_BRANDS = "order/components/OrderHeader/SET_SOLDTO_BRANDS";
export const setSoldToBrands = makeActionCreator(SET_SOLDTO_BRANDS, false, "brands");

export const GET_SHIPTO_TYPES ="order/components/OrderHeader/GET_SHIPTO_TYPES";
export const getShipToTypes = makeActionCreator(GET_SHIPTO_TYPES, false);
export const SET_SHIPTO_TYPES = "order/components/OrderHeader/SET_SHIPTO_TYPES";
export const setShipToTypes = makeActionCreator(SET_SHIPTO_TYPES, false, "shiptotypes");

export const GET_SHIPTO_ORGS ="order/components/OrderHeader/GET_SHIPTO_ORGS";
export const getShipToOrgs = makeActionCreator(GET_SHIPTO_ORGS, false, "siteId", "customerId", "searchString");
export const SET_SHIPTO_ORGS = "order/components/OrderHeader/SET_SHIPTO_ORGS";
export const setShipToOrgs = makeActionCreator(SET_SHIPTO_ORGS, false, "shipToOrgs");

export const GET_VALIDATE_ORDERID ="order/components/OrderHeader/GET_VALIDATE_ORDERID";
export const getValidateOrderId = makeActionCreator(GET_VALIDATE_ORDERID, false,"siteId", "orderId");
export const SET_VALIDATE_ORDERID = "order/components/OrderHeader/SET_VALIDATE_ORDERID";
export const setValidateOrderId = makeActionCreator(SET_VALIDATE_ORDERID, false, "isDuplicateOrderId");