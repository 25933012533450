import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox, nullRender } from 'office-ui-fabric-react';
import { getCountries } from './CountryList.actions';
import { countriesSelector } from './CountryList.selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ComboBoxStyle } from '../../constants'

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    selectedCountry: PropTypes.string,
    errorMessage: PropTypes.string,
    handleSelect: PropTypes.func
}

class CountryList extends Component {
    componentDidMount() {
        this.props.getCountries();
    }

    render = () => {
        if (!this.props.countries) {
            return nullRender();
        }
        return (
            <ComboBox
                name="CountryList"
                label={this.props.label}
                required={this.props.isRequired}
                selectedKey={this.props.selectedCountry}
                options={this.props.countries}
                onChange={this.props.handleSelect}
                errorMessage={this.props.errorMessage}
                autoComplete='off'
                useComboBoxAsMenuWidth={true}
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countries: countriesSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getCountries() {
        dispatch(getCountries())
    }
});

CountryList.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CountryList));