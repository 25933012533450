import React, { Component } from "react";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import PageSizeSelection from "./PageSizeSelection";
import PaginatationLabel from "./PaginatationLabel";
import './Pagination.scss';

const propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  pageNo: PropTypes.number,
  interval: PropTypes.number,
  title: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  hideNoOfRecordsMessage: PropTypes.bool,
  hidePageSizeSelection: PropTypes.bool,
};

const defaultProps = {
  pageSize: 10
};

class Pager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageSize: this.props.pageSize ? this.props.pageSize : defaultProps.pageSize,
      interval: this.props.interval? this.props.interval: defaultProps.pageSize,
      currentPage: this.props.pageNo? this.props.pageNo: 1
    };
  }
  
  componentWillReceiveProps(newProps){
    this.setState({      
      currentPage: newProps.pageNo? newProps.pageNo: 1
    });
  }

  componentDidMount() {
    this.setState({
      currentPageSize: this.props.pageSize ? this.props.pageSize : defaultProps.pageSize,
      interval: this.props.interval? this.props.interval: defaultProps.pageSize
    });
  }

  onPageSizeChanged = value => {
    let currentPageSize = Number.parseInt(value);
    this.setState({ currentPageSize: currentPageSize });
    this.props.onPageChange(1, currentPageSize);
  };

  onChangePage = page => {
    this.setState({ currentPage: page });
    this.props.onPageChange(page, this.state.currentPageSize);
  };

  render() {
    const {
      itemsCount,
      title
    } = this.props;
    if (!itemsCount) return null;
    const { interval, currentPageSize, currentPage } = this.state;

    return (
      <React.Fragment>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row page-size">
            <div className="ms-Grid-col  ms-xl6 ms-lg6 ms-md12 ms-sm12 search-result-view pager ">
              {
                this.props.hidePageSizeSelection ? null :
                  <PageSizeSelection
                    title={"Page Size"}
                    interval={interval}
                    length={itemsCount}
                    defaultValue={currentPageSize}
                    onPageSizeChanged={this.onPageSizeChanged}
                  />
              }
              {
                this.props.hideNoOfRecordsMessage ? null :
                  <PaginatationLabel
                    currentPage={currentPage}
                    currentPageSize={currentPageSize}
                    length={itemsCount}
                    title={title}
                  />
              }


            </div>
            <div className="ms-Grid-col ms-xl6 ms-lg6 ms-md12 ms-sm12 search-result-view pager">
              <Pagination
                initialPage={currentPage}
                itemsCount={itemsCount}
                pageSize={currentPageSize}
                onChangePage={this.onChangePage}
              />
            </div>
          </div>
        </div>




      </React.Fragment>
    );
  }
}

Pager.propTypes = propTypes;
export default Pager;
