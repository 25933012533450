import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react';

const propTypes = {
    filters: PropTypes.array,
    onFilterBadgeChange: PropTypes.func
}

const getFormattedDate = (dt) => {
    dt = new Date(dt);
    return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000).toISOString().split("T")[0];
};

const renderFilterType = {
    date: (title, filterItem) => {
        return title + " (" + filterItem.text + "): " + getFormattedDate(filterItem.selectedValue);
    },
    default: (title, filterItem) => {
        return title + ": " + filterItem.text;
    }
};

export class FilterBadge extends Component {

    handleRemove(field, filterItem){
        let filters = this.props.filters;        
        let filter = filters.find(item=> item.field === field);
        if(filter){
            filter.items = filter.items.filter(item=> item.value!==filterItem.value);
        }
        this.props.onFilterBadgeChange(filters, filter);        
    }

    getBadge = (title, field, filterType, filterItem) => {
        return (
            <span className="filter-badge-item filter-item-view" key={filterItem.value}>  
                <span className="filter-badge-item-text" title={filterItem.text}>{field + " - " +filterItem.text}</span>     
                <span className="filter-badge-item-btn">
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        title="Remove"
                        aria-label={
							`${renderFilterType[filterType || "default"](title, filterItem)}` +
							this.props.intl.formatMessage({ id: "common.SearchResult.removeSelectedItem", defaultMessage: ", Click enter to remove it." })
						}            
                        onClick={() => this.handleRemove(field, filterItem)}
                    />
                </span>          
            </span>
        )
    }

    render() {
        return (
            <div className="filter-badge">
                {
                    this.props.filters && this.props.filters.map((filter) => {
                        return (
                            filter.items.map((item) => {
                                return this.getBadge(filter.title, filter.field, filter.type, item);
                            })
                        )
                    })
                }
            </div>
        );
    }
}

FilterBadge.propTypes = propTypes;

export default injectIntl(FilterBadge);
