import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox } from 'office-ui-fabric-react';
import { arSitesSelector } from '../../selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ComboBoxStyle } from '../../constants'

const propTypes = {
    isRequired: PropTypes.bool,
    selected: PropTypes.string,
    handleSelect: PropTypes.func,
    errorMessage: PropTypes.string
}

class ArSites extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected
        }
    }

    componentWillMount() {
        this.checkAndSetDefault(this.state.selected, this.props.arSites);
    }

    componentWillReceiveProps(newProps) {
        this.checkAndSetDefault(newProps.selected, newProps.arSites);
        if (this.state.selected !== newProps.selected) {
            this.setState({ selected: newProps.selected });
        }
    }

    checkAndSetDefault = (selected, arSites) => {
        if (!selected && arSites) {
            this.props.handleSelect({
                key: "0", value: this.getArSiteData(arSites, "0"),
                arSiteIds: this.getArSiteIds(arSites, "0"),
            });
            this.setState({ selected: "0" });
        }
    }

    onSelect = (event, data) => {
        this.props.handleSelect({
            key: data.key, value: this.getArSiteData(this.props.arSites, data.key),
            arSiteIds: this.getArSiteIds(this.props.arSites, data.key)
        });
        this.setState({
            selected: data.key
        });
    }

    getArSiteData = (items, selected) => {
        if (selected === "0") {
            items = items && items.filter(item => item.key !== "0");
        }
        else {
            items = items && items.filter(item => item.key === selected);
        }
        return items;
    }

    getArSiteIds = (items, selected) => {
        let arSites = this.getArSiteData(items, selected);
        var arSiteIds = [];
        arSites.forEach(item => {
            arSiteIds.push(item.key);
        });
        return arSiteIds;
    }

    render = () => {
        return (
            <ComboBox
                name="arSites"
                id="arSites"
                label={this.props.intl.formatMessage({ id: 'common.ARSiteTitle', defaultMessage: 'AR Site' })}
                required={this.props.isRequired}
                selectedKey={this.state.selected}
                options={this.props.arSites}
                onChange={this.onSelect}
                errorMessage={this.props.errorMessage}
                autoComplete='off'
                useComboBoxAsMenuWidth={true}
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
            />
        );
    }
}

const mapStateToProps = (state) => {
    let arSites = arSitesSelector(state);
    if (arSites) {
        arSites = [{ key: "0", text: "All" }].concat(arSites);
    }
    else{
       // arSites = [{ key: "0", text: "All" }]
    }
    return {
        arSites: arSites
    };
}

ArSites.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, null)(ArSites));
