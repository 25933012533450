import React, { Component } from 'react';
import './OrderLineItem.scss'
import PropTypes from 'prop-types';
import { OrderLineItemDefaultDetails } from './DefaultOrderLineItemDetails';
import { injectIntl, intlShape } from 'react-intl';
import { OrderConstants } from '../../constants';
import { IconButton, Label, Link } from 'office-ui-fabric-react';
import OrderLineItem from './OrderLineItem'
import { Accordion, AccordionHeader, AccordionBody, OptionsButton } from '../../../core/components';
import { default as loadashFilter } from 'lodash/filter';
import { uniqueId as loadashUniqueId } from 'lodash/util';
import { isInStatus, Statuses, TabIndexes } from '../../../common/constants';
import { getOrderLineItemOrderType } from './OrderLineItem.actions';
import { showHiddenAlert } from '../../../core/components/Alert/Alert.actions';


import { orderLineItemOrderTypesSelector } from './OrderLineItem.selectors'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

const propTypes = {
    handleChange: PropTypes.func,
    orderHeader: PropTypes.object,
    intl: intlShape.isRequired,
    lineItems: PropTypes.array
}

let optionStyle = {
    width: '200px'
};

let displayId = 1;

class OrderLineItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
            noOfAlert: 0
        };

        this.AddBtnRef = React.createRef();
    }

    componentWillMount() {
        let siteId = this.props.siteId;
        let soldToType = this.props.soldToType;
        let customerId = this.props.customerId;
        if (!this.props.editMode && this.props.orderHeader && this.props.orderHeader.SoldToType) {
            this.props.actions.getOrderLineItemOrderType(this.props.orderHeader.SoldToType.value);
        }
        let lineItems = this.props.lineItems;
        if (lineItems.length === 0) {
            lineItems.push(this.defaultDetails(displayId));
        }
        if (this.props.orderHeader) {
            if (!(siteId && soldToType && customerId)) {
                siteId = this.props.orderHeader.SiteId.value;
                soldToType = this.props.orderHeader.SoldToType.value;
                customerId = this.props.orderHeader.CustomerId.value;
            }
            if (!(this.props.orderHeader.SiteId.value === siteId && this.props.orderHeader.SoldToType.value === soldToType && this.props.orderHeader.CustomerId.value === customerId)) {
                lineItems.forEach(item => {
                    item.EndItem = { value: '', text: '', error: '' };
                    item.LicensableItem = { value: '', text: '', error: '' };
                })
                siteId = this.props.orderHeader.SiteId.value;
                soldToType = this.props.orderHeader.SoldToType.value;
                customerId = this.props.orderHeader.CustomerId.value;
            }
        }
        this.props.handleChange({
            siteId: siteId,
            soldToType: soldToType,
            customerId: customerId,
            lineItems: lineItems
        });
    }

    componentWillUnmount(){
        this.AddBtnRef = null;
    }
    
    handleOrderTypeChange = (data) => {
        let shipmentType = this.props.lineItems && this.props.lineItems[0].ShipmentType;
        if (data.key === shipmentType.value) {
            return;
        }
        shipmentType.value = data.key;
        shipmentType.text = data.text;
        shipmentType.error = (data.value === '' ? this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" }) : '');
        var lineItems = this.props.lineItems;
        lineItems.forEach(lineItem => {
            lineItem["ShipmentType"] = shipmentType;
            lineItem["EndItem"] = { value: '', text: '', error: '' };
            lineItem["LicensableItem"] = { value: '', text: '', error: '' };
            lineItem.isCollapsed = false;
        })
        this.props.handleChange({ lineItems: lineItems, isStateValid: this.isStateValid(...lineItems) });
    }


    handleChange = (lineItem) => {
        let lineItems = this.props.lineItems;
        const index = lineItems.findIndex(item => item.LineItemId.value === lineItem.LineItemId.value);
        lineItems[index] = lineItem;
        this.checkForDuplicateProduct(lineItems, index, lineItem);
        this.props.handleChange({ lineItems: lineItems, isStateValid: this.isStateValid(lineItem) });
    }

    checkForDuplicateProduct = (lineItems, index, lineItem) => {
        if (lineItem.EndItem.error === '' && lineItem.EndItem.value !== '' &&
            loadashFilter(lineItems, { EndItem: lineItem.EndItem }).length > 1) {
            lineItems[index].EndItem.error = 'This product has already been added on another line item';
        }
    }

    setRef = (lineItem) => {
        if (lineItem.FirstElementRef && lineItem.FirstElementRef.current) {
            let lineItems = this.props.lineItems;
            const index = lineItems.findIndex(item => item.LineItemId.value === lineItem.LineItemId.value);
            lineItems[index] = lineItem;
            lineItem.FirstElementRef.current.focus();
        }
    }

    handleCancel = (lineItemId, displayId) => {
        let itemLength = this.props.lineItems.length;
        let lineItem = null;
        if (itemLength > 1) {
            let index = this.props.lineItems.findIndex(item => item.LineItemId.value !== lineItemId && !item.isCollapsed);
            if (index > -1) {
                lineItem = this.props.lineItems[index];
                lineItem.FirstElementRef = null;
            }
            else {
                if (this.AddBtnRef && this.AddBtnRef.current) {
                    setTimeout(this.AddBtnRef.current.focus(), 1000);
                }
            }
            let items = this.props.lineItems.filter(item => item.LineItemId.value !== lineItemId);
            items.forEach((item) => {
                item.DisplayId = (item.DisplayId > displayId) ? item.DisplayId - 1 : item.DisplayId
            });

            if (items.length === 1) {
                items[0].isAdded = false;
            }

            this.props.handleChange({ lineItems: items, isStateValid: true });
            this.props.alertActions.showHiddenAlert({ message: `Line Item ${displayId} removed` });
        }
    }

    isStateValid = (lineItem) => {
        if (lineItem &&
            lineItem.Quantity.value && lineItem.ShipmentType.value && lineItem.EndItem.value && lineItem.EndItem.error === '' &&
            (this.props.orderHeader.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE && lineItem.LicensableItem.value)) {
            return true;
        }
        return false;
    }

    defaultDetails = (displayId) => {
        return OrderLineItemDefaultDetails(loadashUniqueId(), displayId);
    }

    onAddBtnClick = (event) => {
        let lineItems = this.props.lineItems;

        if (lineItems.length > 0) {
            if (this.props.validateItems(lineItems)) {
                lineItems.forEach((item) => {
                    item.isCollapsed = true;
                })
                let displayId = parseInt(lineItems[lineItems.length - 1].DisplayId) + 1;
                let newItem = this.defaultDetails(displayId);
                newItem.isAdded = true;
                lineItems.push(newItem);
                this.props.alertActions.showHiddenAlert({ message: `Line Item ${displayId} added` });
            }
        }
        else {
            lineItems.push(this.defaultDetails(1));
        }
        lineItems[lineItems.length - 1].ShipmentType.value = this.props.lineItems[0].ShipmentType.value;
        lineItems[lineItems.length - 1].ShipmentType.text = this.props.lineItems[0].ShipmentType.text;
        this.props.handleChange({ lineItems: lineItems, isStateValid: false });
    }

    renderOrderLineItem = (item, isRemoveAllowed) => {
        item = Object.assign({}, item);
        return <OrderLineItem key={item.LineItemId.value} isEditMode={this.props.editMode ? true : !isRemoveAllowed} lineItem={item}
            orderHeader={this.props.orderHeader}
            handleChange={this.handleChange}
            handleCancel={this.handleCancel}
            setRef={this.setRef}> </OrderLineItem>
    }

    onOrderLineItemsToggle = () => {
        this.props.lineItems.forEach((item) => {
            item.isCollapsed = !this.state.isCollapsed
        })
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
        this.props.handleChange({ lineItems: this.props.lineItems });
    }

    render() {
        if (!(this.props.lineItems && this.props.lineItems.length > 0)) {
            return null;
        }
        return (
            <div className="order-line-items">
                {
                    this.props.editMode ? null :
                        <div className="order-type">
                            <OptionsButton
                                isRequired={true}
                                id="orderType"
                                optionButtonStyle={optionStyle}
                                label={this.props.intl.formatMessage({ id: 'orderLineItem.OrderType', defaultMessage: 'Select Order Type' })}
                                options={this.props.orderLineItemOrderTypes ? this.props.orderLineItemOrderTypes : []}
                                onSelect={(data) => this.handleOrderTypeChange(data)}
                                defaultSelectedKey={this.props.lineItems[0].ShipmentType.value && this.props.lineItems[0].ShipmentType.value.toString()}
                                errorMsg={this.props.lineItems[0].ShipmentType.error} />
                        </div>
                }
                <Accordion>
                    <AccordionHeader accordionheader={this.props.intl.formatMessage({ id: 'orderLineItems.HeaderTitle', defaultMessage: 'Order Line Items' })}>
                        <div className="header-text">
                            <h3>
                                {
                                    this.props.editMode ?
                                        this.props.intl.formatMessage({ id: 'orderLineItems.HeaderTitle', defaultMessage: 'Order Line Items' }) :
                                        this.props.intl.formatMessage({ id: 'orderLineItems.LineItemsTitle', defaultMessage: "Line Items" })
                                }
                            </h3>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="order-type">
                            {
                                this.props.editMode === true ?
                                    <div>
                                        <Label name="OrderTypeLabel" id="OrderTypeLabel" className="labelHeader">
                                            {this.props.intl.formatMessage({ id: 'orderLineItem.OrderType', defaultMessage: 'Order Type' })}
                                        </Label>
                                        <Label name="OrderTypeValue" id="OrderTypeValue" className="labelValue">{this.props.lineItems[0].ShipmentType.text}</Label>
                                    </div> : null
                            }
                        </div>
                        {
                            (!this.props.editMode || !isInStatus(this.props.orderHeader.Status.value, Statuses.SUBMITTED)) ?
                                <div>
                                    {this.props.intl.formatMessage({ id: 'orderLineItems.orderlineItemInfoMsg', defaultMessage: 'Note : Manually add as many as lines from the Add New Line Items' })}
                                </div> : null
                        }
                        <div className="order-lineitems-collapse-section">
                            <div className="order-lineitems-collapse">
                                <span  aria-expanded={!this.state.isCollapsed} role="alert" className="hover-content" onClick={() => this.onOrderLineItemsToggle()}>
                                    {
                                        this.state.isCollapsed ?
                                            <IconButton iconProps={{ iconName: 'ChevronDown' }}
                                                aria-label="Add New Line Items hide section collapsed"
                                                aria-expanded="false"
                                                className="line-items-toggle-icon ms-slideUpIn10" /> :

                                            <IconButton iconProps={{ iconName: 'ChevronUp' }}
                                                aria-label="Add New Line Items show section expanded"
                                                aria-expanded="true"
                                                className="line-items-toggle-icon ms-slideDownIn10" />
                                    }
                                </span>
                            </div>
                        </div>
                        {
                            this.props.lineItems.map((item, index) => {
                                return this.renderOrderLineItem(item, this.props.lineItems.length > 1)
                            })
                        }
                        {
                            (!this.props.editMode || !isInStatus(this.props.orderHeader.Status.value, Statuses.SUBMITTED)) ?
                                <div className="ms-Grid-row zero-margin">
                                    <div className="add-line-item-button" >
                                        <Link componentRef={this.AddBtnRef} onClick={this.onAddBtnClick} title={this.props.intl.formatMessage({ id: 'orderLineItem.addlineItemTitle', defaultMessage: 'Add Line Item' })}>
                                            {this.props.intl.formatMessage({ id: 'orderLineItem.addlineItemTitle', defaultMessage: 'Add Line Item' })}
                                            <i className="ms-Icon ms-Icon--CircleAdditionSolid iconButtonAddition"></i>
                                        </Link>
                                    </div>
                                </div> : null
                        }
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderLineItemOrderTypes: orderLineItemOrderTypesSelector(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators({ getOrderLineItemOrderType }, dispatch),
        alertActions: bindActionCreators({ showHiddenAlert }, dispatch),
    }
}
OrderLineItems.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderLineItems))

