import React from 'react';
import { injectIntl } from 'react-intl';
import { DefaultButton } from 'office-ui-fabric-react';
import './Details.scss'
import { AuthorizedComponent } from '../Auth';

const DetailsFooter = (props) => {
    return (
        <div className="container">
            <div className="ms-Grid" >
                <div className="ms-Grid-row row-space Details-Footer-Button">
                    {props.isApproval === true &&
                        <AuthorizedComponent authorizedRoles={props.authorizedApproverRoles}>
                            <div>
                                <DefaultButton
                                    className="iconRight"
                                    primary={true}
                                    data-automation-id="accept"
                                    disabled={false}
                                    text={props.intl.formatMessage({
                                        id: 'app.approve',
                                        defaultMessage: 'Approve',
                                    })}
                                    iconProps={{ iconName: 'Accept' }}
                                    onClick={props.approve}
                                    allowDisabledFocus={true} />
                                <DefaultButton
                                    className="iconRight"
                                    primary={true}
                                    data-automation-id="reject"
                                    disabled={false}
                                    text={props.intl.formatMessage({
                                        id: 'app.reject',
                                        defaultMessage: 'Reject',
                                    })}
                                    iconProps={{ iconName: 'cancel' }}
                                    onClick={props.reject}
                                    allowDisabledFocus={true} />
                            </div>
                        </AuthorizedComponent>
                    }
                </div>
            </div>
        </div>
    );
}

export default injectIntl(DetailsFooter);