import { makeActionCreator } from "../../../core/utility";

export const GET_SHIPMENT_TYPE_LINE_ITEM ="shipment/components/ShipmentLineItem/GET_SHIPMENT_TYPE_LINE_ITEM";
export const getShipmentTypeLineItem= makeActionCreator(GET_SHIPMENT_TYPE_LINE_ITEM, false,"SoldToType");
export const SET_SHIPMENT_TYPE_LINE_ITEM = "shipment/components/ShipmentLineItem/SET_SHIPMENT_TYPE_LINE_ITEM";
export const setShipmentTypeLineItem = makeActionCreator(SET_SHIPMENT_TYPE_LINE_ITEM, false, "shipmentTypeLineItem");

export const GET_SHIPMENT_LINE_ITEM_COMPONENT ="shipment/components/ShipmentLineItem/GET_SHIPMENT_LINE_ITEM_COMPONENT";
export const getShipmentLineItemComponent= makeActionCreator(GET_SHIPMENT_LINE_ITEM_COMPONENT, false, "inactiveEndItem","searchText");
export const SET_SHIPMENT_LINE_ITEM_COMPONENT = "shipment/components/ShipmentLineItem/SET_SHIPMENT_LINE_ITEM_COMPONENT";
export const setShipmentLineItemComponent = makeActionCreator(SET_SHIPMENT_LINE_ITEM_COMPONENT, false, "shipmentLineItemComponents");

