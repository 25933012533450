import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from '../../../core/components/AutoComplete'
import { getLineItemEndItem } from './EndItem.actions';
import { lineItemEndItemsSelector } from './EndItem.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ShipmentConstants } from '../../../shipment/constants';

const propTypes = {
    siteIds: PropTypes.array.isRequired,
    customerId: PropTypes.string,
    customerType: PropTypes.string.isRequired,
    inactiveEndItem: PropTypes.bool.isRequired,
    isOrderType: PropTypes.bool.isRequired,
    lineItemType: PropTypes.string.isRequired,
    compareDate: PropTypes.instanceOf(Date),
    selectedText: PropTypes.string,
    onSelect: PropTypes.func,
    errorMessage: PropTypes.string,
}

class EndItem extends Component {

    fetchData = async (searchText) => {
        let compareDate;
        if (this.props.compareDate) {
            let dt = new Date(this.props.compareDate.getTime() - (this.props.compareDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
            compareDate = dt;
        }
        
        this.props.getLineItemEndItem(this.props.siteIds, this.props.customerId, this.props.inactiveEndItem, searchText, this.props.isOrderType, this.props.lineItemType, compareDate, this.props.customerType);
    }

    render = () => {
        if (!(this.props.siteIds && this.props.siteIds.length > 0) || (!this.props.customerId && this.props.customerType !== ShipmentConstants.CUSTOMERTYPE_AR_VALUE)) {
            return null;
        }
        return (
            <AutoComplete
                name="EndItem"
                label={this.props.intl.formatMessage({ id: 'orderLineItem.EndItemTitle', defaultMessage: 'End Item' })}
                ariaLabel={this.props.intl.formatMessage({ id: 'orderLineItem.EndItemTitle', defaultMessage: 'End Item' })}
                isRequired={this.props.isRequired}
                placeholder={this.props.intl.formatMessage({ id: 'app.autocompleteplaceholder', defaultMessage: 'Please type to search...' })}
                defaultDisplayText={this.props.selectedText}
                errorMessage={this.props.errorMessage}
                onSearch={(searchText) => { this.fetchData(searchText) }}
                items={this.props.LineItemEndItems}
                onSelect={this.props.onSelect}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        LineItemEndItems: lineItemEndItemsSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getLineItemEndItem(siteIds, customerId, inactiveEndItem, searchText, isOrderType, lineItemType, compareDate, ChannelType) {
        dispatch(getLineItemEndItem(siteIds, customerId, inactiveEndItem, searchText, isOrderType, lineItemType, compareDate, ChannelType))
    }
});
EndItem.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EndItem));