import React, { Component } from 'react';
import DetailsHeader from './DetailsHeader';
import DetailsFooter from './DetailsFooter';
import {Statuses,isInStatus} from '../../constants';

class Details extends Component {

    isEditable = (status) => {
        return isInStatus(status,Statuses.SUBMITTED) || isInStatus(status,Statuses.PENDING_SUBMISSION);
    }
    isApprovable = (status) => {
        return isInStatus(status,Statuses.AWAITING_ACCEPTANCE);
    }

    render() {
        const status = this.props.orderShipmentData.Status.value;
        const [isEdit, isApproval] = [this.isEditable(status), this.isApprovable(status)];
        return (
            <div className="order-shipment-details">
                <DetailsHeader headerText={this.props.sectionHeading} msIdFieldText={this.props.msIdFieldName}
                    msId={this.props.orderShipmentData.MsOrderId ? this.props.orderShipmentData.MsOrderId.value : this.props.orderShipmentData.MsShipmentId.value}
                    status={this.props.orderShipmentData.Status.text}
                    isApproval={isApproval} isEdit={isEdit}
                    approve={this.props.approve} reject={this.props.reject}
                    cancel={this.props.cancel} edit={this.props.edit}
                    delete={this.props.delete}
                    authorizedEditRoles={this.props.authorizedEditRoles} ></DetailsHeader>

                {this.props.children}
                <DetailsFooter authorizedApproverRoles={this.props.authorizedApproverRoles} orderShipmentData={this.props.orderShipmentData} isApproval={isApproval} isEdit={isEdit} approve={this.props.approve} reject={this.props.reject} cancel={this.props.cancel} edit={this.props.edit} delete={this.props.delete}></DetailsFooter>
            </div>
        );
    }
}

export default Details;