import React from 'react';
import PropTypes from 'prop-types';
import './Accordion.scss';

const propTypes= {
    isOpen: PropTypes.bool,
    onAccordionToggle: PropTypes.func
}

class Accordion extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            isOpen: this.props.isOpen===undefined? true: this.props.isOpen
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            isOpen: newProps.isOpen===undefined? true: newProps.isOpen
        });
    }

    onAccordionToggle = (isOpen) =>{
        this.setState({
            isOpen: isOpen
          });
        this.props.onAccordionToggle && this.props.onAccordionToggle(isOpen);
    }
    // renderChildren = (child) => {
    //     switch (child.type.name) {
    //         case 'AccordionHeader':
    //           return <AccordionHeader key={child.type.name} isOpen={this.state.isOpen} 
    //                     onAccordionToggle={this.onAccordionToggle} 
    //                     children={child.props.children} />
    //         case 'AccordionBody':
    //             return <AccordionBody key={child.type.name} isOpen={this.state.isOpen} children={child.props.children}/>          
    //         default:
    //           return null;
    //       }
    // }

    render(){
        const { children } = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { isOpen: this.state.isOpen, onAccordionToggle: this.onAccordionToggle, 
                changeState: this.changeState }));
        return(          
            <div className="accordion">
                {childrenWithProps}
            </div>
        )       
    }
}

Accordion.propTypes = propTypes;
export default Accordion;
