export const Logger = {
    verbose(message, optionalParams) {
      console.log.apply(console, ['[VERBOSE] ' + message].concat(optionalParams));
    },
  
    info(message, optionalParams){
      console.info.apply(console, ['[INFO] ' + message].concat(optionalParams));
    },
  
    warn(message, optionalParams){
      console.warn.apply(console, ['[WARN] ' + message].concat(optionalParams));
    },
  
    error(message, optionalParams){
      console.error.apply(console, ['[ERROR] ' + message].concat(optionalParams));
    }
  }