import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from "highcharts";


const propTypes = {
    chartTitle: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    onAxisClick: PropTypes.func
}
const ChunkStatuses = {
    LOADED: "Loaded",
    FAILED: "Failed",
    INPROGRESS: "InProgress"
}
var ReactHighcharts = null;
class PieChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modulesLoaded: ChunkStatuses.INPROGRESS
        }
        
    }

    componentDidMount() {
        Promise.all([
            import('highcharts/modules/accessibility'),import('highcharts/modules/exporting'),import('highcharts-react-official')
        ]).then(([acc,exp,rehigh])=>{            
            ReactHighcharts = rehigh.default;
            require("highcharts/modules/exporting")(Highcharts);
            require('highcharts/modules/accessibility')(Highcharts);
            this.setState({modulesLoaded : ChunkStatuses.LOADED});
        }).catch(()=>{
            console.log("failed to load")
            this.setState({modulesLoaded : ChunkStatuses.FAILED});
        })
    }

    render() {
        return <React.Fragment>
            {this.state.modulesLoaded  === ChunkStatuses.INPROGRESS ? <div></div> : 
                      <ReactHighcharts options={this.getPieChatConfig()} highcharts={Highcharts} containerProps = {{ style: {height: '400px'} }} />}
               </React.Fragment>
    }


    getPieChatConfig() {
        return {
            credits: {
                enabled: false
            },
            chart: {
                type: 'pie',
                // options3d: {
                //     enabled: true,
                //     alpha: 45,
                //     beta: 0
                // }

            },
            accessibility: {
                description: this.props.chartTitle ? this.props.chartTitle : ''
            },
            title: {
                text: this.props.chartTitle ? this.props.chartTitle : ''
            },
            tooltip: {
                pointFormat: '<b>{point.y:.0f}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name},{point.y:.0f}'
                    },
                    events: {
                        click: this.onChartAxisClick.bind(this)
                    },
                },
                series: {
                    point: {
                        events: {
                            click: this.onChartAxisClick.bind(this)
                        }
                    }
                }

            },
            series: [{
                type: 'pie',
                name: 'Count',
                data: this.props.chartData
            }]
        };
    }

    onChartAxisClick(event) {        
        this.props.onAxisClick && (event.point != undefined ? this.props.onAxisClick(event.point) : this.props.onAxisClick(event.target)) ;
    }

}

PieChart.propTypes = propTypes;

export default PieChart;