import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {  userRolesSelector } from '../../selectors';
class AuthorizedRoute extends PureComponent {
    isAuthorizedRoute(){
        return this.props.authorizedRoles.some(authorizedRole=> this.props.userRoles.includes(authorizedRole));
    }
    render() {
        const { component: Component, userRoles, ...rest } = this.props;
        if (!userRoles) return null;
        return <Route {...rest} render={(props) => (
            this.isAuthorizedRoute()? <Component {...props} />: <Redirect to='/NotAuthorized' />
        )} />

    }
}
const mapStateToProps = (state) => {
    return {
        userRoles:userRolesSelector(state)
    }
}
export default connect(mapStateToProps, null)(AuthorizedRoute)