export const AlertsTypes = {
    INFO: 0,
    ERROR: 1,
    SUCCESS: 2,
    WARNING: 3
};

export const AlertsFeature = {
    ORION_ALERTS: "Orion",
    ORION_PANEL_ALERTS: "Orion_Panel",
    GLOBAL_ALERTS: "Global",
};

export const Auth = {
    IDTokenKey: "msal.idtoken"
};

export const AppinsightsLogFilter = {
    FILTER_MESSAGE : "[UI] "
};