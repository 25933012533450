import { createReducer } from '../../../core/utility';
import { SET_SEARCH_BRAND_RESULT,SET_VALIDATE_BRANDNAME,SET_SAVE_BRAND_OEPRATION_COMPLETION_STATUS } from './Brand.actions';
import { fromJS } from 'immutable';

  export const searchBrandResult = createReducer(null, {
    [SET_SEARCH_BRAND_RESULT](state, { searchBrandResult }) {
      return fromJS(searchBrandResult);
    },
  });

  export const isDuplicateBrandName = createReducer(null, {
    [SET_VALIDATE_BRANDNAME](state, { isDuplicateBrandName }) {
        return fromJS(isDuplicateBrandName);
    }
});

export const saveBrandOperationCompleted=createReducer(null,{
  [SET_SAVE_BRAND_OEPRATION_COMPLETION_STATUS](state,{saveBrandOperationCompleted}){
    return fromJS(saveBrandOperationCompleted)
  }
})