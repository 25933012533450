import React, { Component } from 'react';
import './OrderLineItem.scss'
import { WizardStep, WizardStepHeader, WizardStepBody, WizardStepFooter } from '../../../core/components';
import { DefaultButton } from 'office-ui-fabric-react';
import OrderLineItems from './OrderLineItems'
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { OrderConstants } from '../../constants';
import { orderStepSelector } from '../CreateOrder/CreateOrder.selectors';
import { showHiddenAlert } from '../../../core/components/Alert/Alert.actions';
import { bindActionCreators } from 'redux';

const propTypes = {
    initialState: PropTypes.object,
    onSubmitClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onStateChange: PropTypes.func,
}

class OrderLineItemStep extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.initialState;
        this.state.lineItems = Object.keys(this.state.inputData).length > 0 ? this.state.inputData : [];
    }

    validateItems = (lineItems) => {
        var noOfInValidItems = 0;

        lineItems.forEach((item) => {
            noOfInValidItems = noOfInValidItems + (this.validateItem(item) ? 0 : 1);
        });
        if (noOfInValidItems === 0)
            return true;
        else {
            return false;
        }
    }

    validateItem = (item) => {
        var noOfErrors = 0;
        Object.keys(item).forEach(key => {
            if (key === "EndItem" &&
                (this.props.orderHeader.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE) &&
                item[key].value === '') {
                item[key].error = this.props.intl.formatMessage({ id: 'app.enditemRequiredfieldMessage', defaultMessage: "End Item Field Required" });
                noOfErrors++;
            }
            else if (key === "Quantity" &&
                (this.props.orderHeader.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE)
                && item[key].value === '') {
                item[key].error = this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" });
                noOfErrors++;
            }
            else if (key === "LicensableItem" &&
                (this.props.orderHeader.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE)
                && item[key].value === '') {
                item[key].error = this.props.intl.formatMessage({ id: 'app.licensablefieldMessage', defaultMessage: "Required Field" });
                noOfErrors++;
            }
            else if (key === "Quantity") {
                let startRange = item.isEditMode ? 0 : 1;
                if (!Number.isInteger(Number(item[key].value)) || (!(item[key].value >= startRange && item[key].value <= 99999))) {
                    item[key].error = `${this.props.intl.formatMessage({ id: 'app.invalidIntegerValueMessage', defaultMessage: "Please enter integer value between" })} ${startRange} - 99999`;
                    noOfErrors++;
                }
            }
            else if(key === 'EndItem' && item[key].value !== '' && item[key].error !== '') {
                noOfErrors++;
            }
        });
        return noOfErrors === 0;
    }

    handleChange = (object) => {
        object.lineItems = [...object.lineItems];
        this.setState(object);
    }

    onSubmitClick = () => {
        let lineItems = this.state.lineItems;
        let isStateValid = this.validateItems(lineItems);
        if(isStateValid) {
            let currentState = this.state;
            currentState.inputData =  this.state.lineItems;
            currentState.isStateValid = isStateValid;
            delete currentState.lineItems;
            this.props.onSubmitClick(currentState);
        }
        else {
            this.handleChange({ lineItems: lineItems, isStateValid: false });
            this.props.alertActions.showHiddenAlert({ message: this.props.intl.formatMessage({ id: 'common.RequiredFieldsError', defaultMessage: 'please fill all the required fields' }) });
        }
    }

    onPreviousClick = () => {
        let currentState = this.state;
        currentState.inputData =  this.state.lineItems;
        delete currentState.lineItems;
        this.props.onPreviousClick(currentState);
    }

    render() {
        return (
            <div className="order-lineitem-container">
                <WizardStep>
                    <WizardStepHeader title={this.props.intl.formatMessage({ id: 'orderLineItems.HeaderTitle', defaultMessage: "Order Line Items" })} />
                    <WizardStepBody>
                        <OrderLineItems {...this.state} orderHeader={this.props.orderHeader} handleChange={this.handleChange} validateItems={this.validateItems}>
                        </OrderLineItems>
                    </WizardStepBody>
                    <WizardStepFooter>
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={this.props.intl.formatMessage({ id: 'app.previous', defaultMessage: "Previous" })}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                            onClick={() => { this.onPreviousClick() }}
                            allowDisabledFocus={true} />
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={false}
                            text={this.props.intl.formatMessage({ id: 'app.next', defaultMessage: "Next" })}
                            iconProps={{ iconName: 'ChevronRightSmall' }}
                            onClick={() => { this.onSubmitClick() }}
                            allowDisabledFocus={true} />
                    </WizardStepFooter>
                </WizardStep>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let orderHeaderInfo = orderStepSelector(OrderConstants.ORDER_HEADER)(state);
    return {
        orderHeader: orderHeaderInfo ? orderHeaderInfo.inputData : null
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        alertActions: bindActionCreators({ showHiddenAlert }, dispatch),
    }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderLineItemStep))
OrderLineItemStep.propTypes = propTypes;



