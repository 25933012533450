import React, { Component } from 'react';
import {
    MessageBar,
    MessageBarType
} from 'office-ui-fabric-react';
import { AlertsTypes } from '../../constants';
import { bindActionCreators } from 'redux';
import * as alertActions from './Alert.actions';
import { connect } from "react-redux";
import "./Alert.scss";

const AlertTypeMessageBarTypeMap = {
    [AlertsTypes.ERROR]: MessageBarType.error,
    [AlertsTypes.WARNING]: MessageBarType.warning,
    [AlertsTypes.SUCCESS]: MessageBarType.success,
    [AlertsTypes.INFO]: MessageBarType.info
}

class Alert extends Component {

    render() {
        const { id, type, feature, message, dismissTime } = this.props;
        let messageBarType = AlertTypeMessageBarTypeMap[type];
        this.alert = { id, type, feature, message };
        const thisRef = this;
        if (type !== AlertsTypes.ERROR) {
            setTimeout(() => { thisRef.onDismiss(thisRef.alert) }, dismissTime);
        }
        return (
            <React.Fragment>
                <div>
                    <MessageBar role='alert' aria-live='assertive' messageBarType={messageBarType}
                        isMultiline={false}
                        onDismiss={()=>{this.onDismiss(this.alert);}} dismissButtonAriaLabel="Close">
                        {message}
                    </MessageBar>
                </div>
            </React.Fragment>
        )
    }

    onDismiss = (alert) => {
        this.props.actions.hideAlert(alert);
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(alertActions, dispatch),
    }
}
export default connect(
    null,
    mapDispatchToProps
)(Alert);
