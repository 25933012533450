import { DefaultButton, TextField } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { WizardStep, WizardStepBody, WizardStepFooter, WizardStepHeader } from '../../../core/components';
import { ArSiteList, SoldToOrgControl, ThirdPartyList, DatePickerWithBoundary } from '../../../common/components';
import BrandNamesList from './BrandNamesList';
import './OrderHeader.scss';
import OrderTypeOptions from './OrderTypeOptions';
import SoldToTypeOptions from './SoldToTypeOptions';
import ShipToTypes from './ShipToTypes';
import ShipToList from './ShipToList';
import { getValidateOrderId } from './OrderHeader.actions';
import { isDuplicateOrderIdSelector } from './OrderHeader.selectors';
import { OrderConstants } from '../../constants';
import { isNullOrUndefined } from 'util';
import { validComponentRoleMappingSelector } from '../../../common/selectors';
import { showHiddenAlert } from '../../../core/components/Alert/Alert.actions';
import { bindActionCreators } from 'redux';
import { FeatureAlerts, showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { hideFeatureAlert } from '@ec-oem/ec.oem.oa3.ui.core/components/Alert/Alert.actions';

let validateOrderIdResolve = null;
const propTypes = {
    initialState: PropTypes.object,
    onSubmitClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onStateChange: PropTypes.func,
    intl: intlShape.isRequired
}

const today = new Date(Date.now());

const requiredFields = ["OrderId", "OrderType", "SoldToType", "SoldToId", "BrandId",
    "PoNumber", "CommittedDate", "ReqShipDate", "ShipToType", "ShipToId", "CustomerId", "SiteId"];

class OrderHeaderStep extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.initialState;
        this.state.inputData = this.setDefaultData(this.state.inputData);
    }

    componentWillUnmount = () => {
        validateOrderIdResolve = null;
    }

    setDefaultData = (data) => {
        return {
            OrderId: { value: data.OrderId ? data.OrderId.value : '', text: data.OrderId ? data.OrderId.text : '', error: data.OrderId ? data.OrderId.error : '' },
            OrderType: { value: data.OrderType ? data.OrderType.value : OrderConstants.ORDERTYPE_DEFAULT_VALUE, text: data.OrderType ? data.OrderType.text : OrderConstants.ORDERTYPE_DEFAULT_TEXT, error: data.OrderType ? data.OrderType.error : '' },
            SoldToType: { value: data.SoldToType ? data.SoldToType.value : '', text: data.SoldToType ? data.SoldToType.text : '', error: data.SoldToType ? data.SoldToType.error : '' },
            SoldToId: { value: data.SoldToId ? data.SoldToId.value : '', text: data.SoldToId ? data.SoldToId.text : '', error: data.SoldToId ? data.SoldToId.error : '' },
            BrandId: { value: data.BrandId ? data.BrandId.value : '', text: data.BrandId ? data.BrandId.text : '', error: data.BrandId ? data.BrandId.error : '' },
            PoNumber: { value: data.PoNumber ? data.PoNumber.value : '', text: data.PoNumber ? data.PoNumber.text : '', error: data.PoNumber ? data.PoNumber.error : '' },
            PoDate: { value: data.PoDate && data.PoDate.value ? new Date(data.PoDate.value) : today, text: data.PoDate ? data.PoDate.text : '', error: data.PoDate ? data.PoDate.error : '' },
            CommittedDate: { value: data.CommittedDate && data.CommittedDate.value ? new Date(data.CommittedDate.value) : today, text: data.CommittedDate ? data.CommittedDate.text : '', error: data.CommittedDate ? data.CommittedDate.error : '' },
            ReqShipDate: { value: data.ReqShipDate && data.ReqShipDate.value ? new Date(data.ReqShipDate.value) : today, text: data.ReqShipDate ? data.ReqShipDate.text : '', error: data.ReqShipDate ? data.ReqShipDate.error : '' },
            ShipToType: { value: data.ShipToType ? data.ShipToType.value : '0', text: data.ShipToType ? data.ShipToType.text : '', error: data.ShipToType ? data.ShipToType.error : '' },
            ShipToId: { value: data.ShipToId ? data.ShipToId.value : '', text: data.ShipToId ? data.ShipToId.text : '', error: data.ShipToId ? data.ShipToId.error : '' },
            CustomerId: { value: data.CustomerId ? data.CustomerId.value : '', text: data.CustomerId ? data.CustomerId.text : '', error: '' },
            SiteId: { value: data.SiteId ? data.SiteId.value : '', text: data.SiteId ? data.SiteId.text : '', error: data.SiteId ? data.SiteId.error : '' },
            IsEMEA: { value: data.IsEMEA ? data.IsEMEA.value : false, text: '', error: '' },
            IsTSTDisable:{value:false}
        };
    }

    getRequiredFields = () => {
        let requiredItems = requiredFields;
        if (this.state.inputData.OrderType.value === OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE) {
            requiredItems = requiredItems.filter(item => !(item === "ShipToId" || item === "ShipToType" || item === "PoDate" || item === "CommittedDate" || item === "ReqShipDate" || item === "PoNumber"));
        }

        if (!this.showRequestedShipDate()) {
            requiredItems = requiredItems.filter(item => !(item === "ReqShipDate"));
        }
        return requiredItems;
    }

    isStateValid = () => {
        if (this.state.inputData) {
            let valid = true;
            let data = this.state.inputData;

            if (this.props.isDuplicateOrderId && this.props.isDuplicateOrderId.status) {
                return false;
            }

            let requiredFields = this.getRequiredFields();
            Object.keys(data).forEach(key => {
                if (requiredFields.indexOf(key) > -1) {
                    if (key === "ShipToType") {
                        valid = (valid && data[key] && data[key].value && data[key].value !== '0') ? true : false;
                    }
                    else {
                        valid = (valid && data[key] && data[key].value) ? true : false;
                    }
                }
            });

            return valid;
        }
    }

    showRequestedShipDate = () => this.props.requestedShipDateRole || this.state.inputData.IsEMEA.value;

    setFields = (keyValueCollection, action) => {
        let inputFields = this.state.inputData;
        keyValueCollection && keyValueCollection.forEach(element => {
            if (element.error) {
                inputFields[element.field].error = element.error;
            }
            else {
                inputFields[element.field].error = "";
            }
            inputFields[element.field].value = element.value;
            inputFields[element.field].text = element.text;
        });

        this.setState({
            inputData: inputFields,
        });

        if (action) {
            action();
        }
    }

    showError = () => {
        if (this.state.inputData) {
            let inputData = this.state.inputData;
            let requiredFields = this.getRequiredFields();
            let noOfErrors = 0;
            Object.keys(inputData).forEach(key => {
                if (requiredFields.indexOf(key) > -1 && !(inputData[key] && inputData[key].value)) {
                    inputData[key].value = key === "SiteId" ? '' : '';
                    inputData[key].text = '';
                    inputData[key].error = inputData[key].error ? inputData[key].error :
                        this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' });
                    noOfErrors++;
                }

                if (key === "ShipToType" && requiredFields.indexOf(key) > -1 && inputData[key] && inputData[key].value && inputData[key].value === '0') {
                    inputData[key].value = '0';
                    inputData[key].text = '';
                    inputData[key].error = inputData[key].error ? inputData[key].error :
                        this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' });
                    noOfErrors++;
                }
            });
            if (noOfErrors > 0) {
                this.setState({
                    inputData: inputData
                });
            }
        }
    }

    handleOrderIdChange = (value, _this) => {
        if (value) {
            const regex = /^[a-zA-Z0-9_].*$/i;
            if(!regex.test(value.trim())) {
                var data = _this.state.inputData;
                data["OrderId"].error = _this.props.intl.formatMessage({ id: 'orderHeader.invalidOrderIdFormatMessage', defaultMessage: 'Order number should start with [A-Za-z0-9_]' });
                _this.setState({ inputData: data });
                return;
            }
            
            _this.props.getValidateOrderId(_this.state.inputData.SiteId.value, {Id : value});
            validateOrderIdResolve = (val) => {
                if (val.id === value && val.status) {
                    var data = _this.state.inputData;
                    data["OrderId"].error = _this.props.intl.formatMessage({ id: 'orderHeader.validateOrderIdMessage', defaultMessage: 'An order or shipment with the same Id already exists' });
                    _this.setState({ inputData: data });
                }
            };
        }
    }

    onSubmitClick = () => {
        let valid = this.isStateValid();
        if (valid) {
            let data = this.state;
            data.isStateValid = true;
            if (data.inputData.OrderType.value === OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE) {
                data.inputData.PoDate.value = null;
                data.inputData.CommittedDate.value = null;
                data.inputData.ReqShipDate.value = null;
            }
            if (!this.showRequestedShipDate()) {
                data.inputData.ReqShipDate.value = null;
            }

            this.props.onSubmitClick(data)
        }
        else {
            this.showError();            
            //this.props.alertActions.showHiddenAlert({message: this.props.intl.formatMessage({ id: 'common.RequiredFieldsError', defaultMessage: 'please fill all the required fields' })} );
        }
    }
    onchangeSoldTo = (value)=>{
        this.props.hideFeatureAlert("Orion");
        if(value && value.TSTStatusID && value.TSTStatusID.TSTStatus 
            && value.TSTStatusID.DisableFields && !value.TSTStatusID.IngestionError 
            && value.TSTStatusID.TSTError){
			 
            this.props.showAlert({
				type: AlertsTypes.ERROR,
				isGeneric: false,
				feature: "Orion",
				message: this.props.intl.formatMessage(value.TSTStatusID.TSTError)
			});
            this.setFields([
                {
                    field: "IsTSTDisable",
                    value: true
                }
            ])
			return;
		}
        this.setFields([
            {
                field: "SoldToId",
                value: value ? value.key : '',
                text: value ? value.displayText : '',
                error: value ? (value.IsCustomerOnHold === false ? '' :
                    this.props.intl.formatMessage({ id: 'common.CustomeOnHold', defaultMessage: 'Customer is OnHold. Any order submitted will go for approval.' })) :
                    this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
            },
            {
                field: "CustomerId",
                value: value && this.state.inputData ? value.CustomerId : '',
                text: value && this.state.inputData ? value.CustomerId : '',
                error: ''
            },
            {
                field: "BrandId",
                value: '',
                text: '',
                error: ''
            },
            {
                field: "ShipToId",
                value: '',
                text: '',
                error: ''
            },
            {
                field: "IsTSTDisable",
                value: false
            }
        ])
    }
    render() {
        return (
            <div className="order-header-container">
                <WizardStep>
                    <WizardStepHeader title={this.props.intl.formatMessage({ id: 'orderHeader.HeaderTitle', defaultMessage: 'Order Header' })} />
                    <WizardStepBody>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row order-header-padding">
                                <div className="ms-Grid-col ms-md4 ms-sm12">
                                    <ArSiteList
                                        label={this.props.intl.formatMessage({ id: 'common.ARSiteTitle', defaultMessage: 'AR Site ' })}
                                        isRequired={true}
                                        isDisabled={this.state.inputData.IsTSTDisable.value}
                                        selectedSite={this.state.inputData.SiteId.value}
                                        errorMessage=
                                        {this.state.inputData.SiteId.error ?
                                            this.props.intl.formatMessage({ id: 'common.ARSiteTitle', defaultMessage: 'AR Site ' }) +
                                            this.state.inputData.SiteId.error
                                            : '' 
                                        }
                                        handleSelect={(event, value) => this.setFields([
                                            {
                                                field: "SiteId",
                                                value: value ? value.key : '',
                                                text: value ? value.text : '',
                                                error: value ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                            },
                                            {
                                                field: "IsEMEA",
                                                value: value ? value.IsEMEA : false,
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "OrderId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            }])} 
                                            />
                                </div>
                                {
                                    this.state.inputData.SiteId.value ?
                                        <div className="ms-Grid-col ms-md4 ms-sm12">
                                            <TextField name="OrderId" id="AROrderId"
                                                required={true}
                                                label={this.props.intl.formatMessage({ id: 'orderHeader.OrderIdTitle', defaultMessage: 'AR Order# ' })}
                                                value={this.state.inputData.OrderId.value}
                                                disabled={this.state.inputData.IsTSTDisable.value}
                                                placeholder={this.props.intl.formatMessage({ id: 'orderHeader.orderIdplaceholder', defaultMessage: 'Please enter OrderId' })}
                                                onChange={(ev, value) => this.setFields([
                                                    {
                                                        field: "OrderId",
                                                        value: value.trim() ? value.trim() : '',
                                                        text: value.trim() ? value.trim() : '',
                                                        error: value.trim() ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                    }])}
                                                onBlur={(event) => this.handleOrderIdChange(this.state.inputData.OrderId.value, this)}
                                                errorMessage={this.state.inputData.OrderId.error ?
                                                    this.props.intl.formatMessage({ id: 'orderHeader.OrderIdTitle', defaultMessage: 'AR Order# ' })
                                                    + " " +
                                                    this.state.inputData.OrderId.error : ''
                                                
                                                } />
                                        </div> : null
                                }
                            </div>
                            <div className="ms-Grid-row order-header-padding">
                                <div className="ms-Grid-col ms-md12 ms-sm12">
                                    <OrderTypeOptions
                                        label={this.props.intl.formatMessage({ id: 'orderHeader.selectordertypetitle', defaultMessage: 'Select Order Type' })}
                                        isRequired={true}
                                        isDisabled={this.state.inputData.IsTSTDisable.value}
                                        selectedKey={this.state.inputData.OrderType.value}
                                        errorMessage={this.state.inputData.OrderType.error ?
                                            this.props.intl.formatMessage({ id: 'orderHeader.selectordertypetitle', defaultMessage: 'Select Order Type' }) +
                                            this.state.inputData.OrderType.error
                                            : '' 
                                        }
                                        onSelect={(selectedKey) => this.setFields([
                                            {
                                                field: "OrderType",
                                                value: selectedKey ? selectedKey.key : '',
                                                text: selectedKey ? selectedKey.text : '',
                                                error: selectedKey ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                            },
                                            {
                                                field: "SoldToType",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "SoldToId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "CustomerId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "CommittedDate",
                                                value: today,
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "ReqShipDate",
                                                value: today,
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "PoDate",
                                                value: today,
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "PoNumber",
                                                value: '',
                                                text: '',
                                                error: ''
                                            }
                                        ])} />
                                </div>

                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-md12">
                                    <div className="order-header-sub-header">
                                        {this.props.intl.formatMessage({ id: 'common.soldtoselection', defaultMessage: 'Sold To Selection' })}
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row order-header-padding">
                                <div className="ms-Grid-col ms-md12">
                                    <SoldToTypeOptions
                                        label={this.props.intl.formatMessage({ id: 'orderHeader.selectsoldtotypetitle', defaultMessage: 'Select Sold To ' })}
                                        isRequired={true}
                                        isDisabled={this.state.inputData.IsTSTDisable.value}
                                        selectedKey={this.state.inputData.SoldToType.value}
                                        errorMessage={this.state.inputData.SoldToType.error?
                                            this.props.intl.formatMessage({ id: 'orderHeader.selectsoldtotypetitle', defaultMessage: 'Select Sold To ' }) + " " +
                                            this.state.inputData.SoldToType.error
                                            :
                                            ''}
                                        onSelect={(selectedKey) => this.setFields([
                                            {
                                                field: "SoldToType",
                                                value: selectedKey ? selectedKey.key : '',
                                                text: selectedKey ? selectedKey.text : '',
                                                error: selectedKey ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                            },
                                            {
                                                field: "SoldToId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "CustomerId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "BrandId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            },
                                            {
                                                field: "ShipToType",
                                                value: selectedKey ? (selectedKey.key === OrderConstants.SOLDTOTYPE_DSP_VALUE && this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE ? OrderConstants.SHIPTOTYPE_OEM_VALUE : '0') : '0',
                                                text: selectedKey ? (selectedKey.key === OrderConstants.SOLDTOTYPE_DSP_VALUE && this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE ? OrderConstants.SHIPTOTYPE_OEM_TEXT : '0') : '0', 
                                                error: ''
                                            },
                                            {
                                                field: "ShipToId",
                                                value: '',
                                                text: '',
                                                error: ''
                                            }
                                        ])} />
                                </div>
                            </div>
                            <div className="ms-Grid-row order-header-padding">
                                {
                                    this.state.inputData.SiteId.value && this.state.inputData.SoldToType.value ?
                                        <React.Fragment>
                                            <div className="ms-Grid-col ms-md4 ms-sm12">
                                                <SoldToOrgControl id="SoldToId" 
                                                    name="SoldToId"
                                                    label={this.props.intl.formatMessage({ id: 'common.SoldToOrgTitle', defaultMessage: 'Sold - To - Organization' })}
                                                    isRequired={true}
                                                    placeholder={this.props.intl.formatMessage({ id: 'app.autocompleteplaceholder', defaultMessage: 'Please type to search...' })}
                                                    aria-label="List-Sold-To-Organization"
                                                    selectedText={this.state.inputData.SoldToId.text}
                                                    errorMessage={this.state.inputData.SoldToId.error ?
                                                        this.props.intl.formatMessage({ id: 'common.SoldToOrgTitle', defaultMessage: 'Sold - To - Organization' }) + " " +
                                                        this.state.inputData.SoldToId.error
                                                        : '' 
                                                    }
                                                    orderType={this.state.inputData.OrderType.value}
                                                    soldToType={this.state.inputData.SoldToType.value}
                                                    onSelect={(value) =>
                                                       this.onchangeSoldTo(value)} />
                                            </div>
                                            {
                                                this.state.inputData.CustomerId.value?
                                                    <div className="ms-Grid-col ms-md4 ms-sm12">
                                                        <BrandNamesList
                                                            label={this.props.intl.formatMessage({ id: 'orderHeader.BrandNameTitle', defaultMessage: 'Brand Name ' })}
                                                            isRequired={true}
                                                            selectedBrand={this.state.inputData.BrandId.value}
                                                            errorMessage={this.state.inputData.BrandId.error ?
                                                                this.props.intl.formatMessage({ id: 'orderHeader.BrandNameTitle', defaultMessage: 'Brand Name ' }) +
                                                                this.state.inputData.BrandId.error
                                                                : ''
                                                            }
                                                            siteId={this.state.inputData.SiteId.value}
                                                            customerId={this.state.inputData.CustomerId.value}
                                                            soldToType={this.state.inputData.SoldToType.value}
                                                            handleSelect={(event, value) => this.setFields([
                                                                {
                                                                    field: "BrandId",
                                                                    value: value ? value.key : '',
                                                                    text: value ? value.text : '',
                                                                    error: value ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                                }])} />

                                                    </div>: null

                                            }                                           
                                        </React.Fragment> : null

                                }
                                {
                                    this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE ?
                                        <div className={"ms-Grid-col ms-md4 ms-sm12"}>
                                            <TextField name="PoNumber"
                                                label={this.props.intl.formatMessage({ id: 'orderHeader.PONumberTitle', defaultMessage: 'OEM PO Number ' })}
                                                placeholder={this.props.intl.formatMessage({ id: 'orderHeader.oemponumberplaceholder', defaultMessage: 'Please enter PO Number' })}
                                                required={this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE}
                                                errorMessage={this.state.inputData.PoNumber.error ?
                                                    this.props.intl.formatMessage({ id: 'orderHeader.PONumberTitle', defaultMessage: 'OEM PO Number ' })
                                                    + this.state.inputData.PoNumber.error
                                                    :""
                                                }
                                                disabled = {this.state.inputData.IsTSTDisable.value}
                                                value={this.state.inputData.PoNumber.value}
                                                onChange={(ev, value) => this.setFields([
                                                    {
                                                        field: "PoNumber",
                                                        value: value && value.trim() ? value.trim() : '',
                                                        text: value && value.trim() ? value.trim() : '',
                                                        error: (this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE && value && value.trim()) ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                    }
                                                ])} />
                                        </div> : null
                                }
                            </div>
                            
                            <div className="ms-Grid-row order-header-padding">
                                {
                                    this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE ?
                                        <React.Fragment>
                                            <div className="ms-Grid-col ms-md4 ms-sm12">
                                                <DatePickerWithBoundary name="PoDate" id="PODate"
                                                    ariaLabel={this.props.intl.formatMessage({ id: 'orderHeader.PODateTitle', defaultMessage: 'PO Date' })}
                                                    allowTextInput={true}
                                                    label={this.props.intl.formatMessage({ id: 'orderHeader.PODateTitle', defaultMessage: 'PO Date' })}
                                                    isRequired={true}
                                                    maxDate={today}
                                                    isOutOfBoundsErrorMessage={"Date must be less than or equal to " + today.toLocaleDateString()}
                                                    selectedDate={this.state.inputData.PoDate.value ? this.state.inputData.PoDate.value : today}
                                                    disabled={this.state.inputData.IsTSTDisable.value}
                                                    onSelectDate={(date) => this.setFields([
                                                        {
                                                            field: "PoDate",
                                                            value: date,
                                                            text: date,
                                                            error: date ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                        }
                                                    ])}
                                                    isRequiredErrorMessage={this.state.inputData.PoDate.error ? `${this.props.intl.formatMessage({ id: 'orderHeader.PODateTitle', defaultMessage: 'PO Date' }) + " " + this.state.inputData.PoDate.error }`: ""} />
                                            </div>
                                            <div className="ms-Grid-col ms-md4 ms-sm12">
                                                <DatePickerWithBoundary name="CommittedDate" id="CommittedDate"
                                                    ariaLabel={this.props.intl.formatMessage({ id: 'orderHeader.CommDateTitle', defaultMessage: 'Committed Date' })}
                                                    allowTextInput={true}
                                                    label={this.props.intl.formatMessage({ id: 'orderHeader.CommDateTitle', defaultMessage: 'Committed Date' })}
                                                    isRequired={true}
                                                    minDate={isNullOrUndefined(this.state.inputData.PoDate.value) ? today : this.state.inputData.PoDate.value}
                                                    isOutOfBoundsErrorMessage={"Date must be greater than or equal to " + (isNullOrUndefined(this.state.inputData.PoDate.value) ? today.toLocaleDateString() : this.state.inputData.PoDate.value.toLocaleDateString())}
                                                    selectedDate={this.state.inputData.CommittedDate.value ? this.state.inputData.CommittedDate.value : today}
                                                    disabled={this.state.inputData.IsTSTDisable.value}
                                                    onSelectDate={(date) => this.setFields([
                                                        {
                                                            field: "CommittedDate",
                                                            value: date,
                                                            text: date,
                                                            error: date ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                        }
                                                    ])}
                                                    isRequiredErrorMessage={this.state.inputData.CommittedDate.error ? `${this.props.intl.formatMessage({ id: 'orderHeader.CommDateTitle', defaultMessage: 'Committed Date' }) + " " + this.state.inputData.CommittedDate.error }`: ""} />
                                            </div>
                                        </React.Fragment> : null
                                }
                                {
                                    this.showRequestedShipDate() && this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE ?
                                        <div className="ms-Grid-col ms-md4 ms-sm12">
                                            <DatePickerWithBoundary name="ReqShipDate" id="ReqShipDate"
                                                ariaLabel={this.props.intl.formatMessage({ id: 'orderHeader.ReqShipDateTitle', defaultMessage: 'Requested Ship Date' })}
                                                allowTextInput={true}
                                                label={this.props.intl.formatMessage({ id: 'orderHeader.ReqShipDateTitle', defaultMessage: 'Requested Ship Date' })}
                                                isRequired={true}
                                                minDate={isNullOrUndefined(this.state.inputData.PoDate.value) ? today : this.state.inputData.PoDate.value}
                                                isOutOfBoundsErrorMessage={"Date must be greater than or equal to " + (isNullOrUndefined(this.state.inputData.PoDate.value) ? today.toLocaleDateString() : this.state.inputData.PoDate.value.toLocaleDateString())}
                                                disabled={this.state.inputData.IsTSTDisable.value}
                                                selectedDate={this.state.inputData.ReqShipDate.value ? this.state.inputData.ReqShipDate.value : today}
                                                onSelectDate={(date) => this.setFields([
                                                    {
                                                        field: "ReqShipDate",
                                                        value: date,
                                                        text: date,
                                                        error: date ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                    }
                                                ])}
                                                isRequiredErrorMessage={this.state.inputData.ReqShipDate.error? `${this.props.intl.formatMessage({ id: 'orderHeader.ReqShipDateTitle', defaultMessage: 'Requested Ship Date' }) + " " + this.state.inputData.ReqShipDate.error }`: ""} />
                                        </div> : null
                                }
                            </div>
                            {
                                this.state.inputData.OrderType.value !== OrderConstants.ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE && this.state.inputData.CustomerId.value ?
                                    <React.Fragment>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-md12">
                                                <div className="order-header-sub-header">
                                                    {this.props.intl.formatMessage({ id: 'orderHeader.shiptoselection', defaultMessage: 'Ship To Selection' })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-md4 ms-sm12">
                                                <ShipToTypes
                                                    label={this.props.intl.formatMessage({ id: 'orderHeader.selectshiptotypetitle', defaultMessage: 'Select Ship To' })}
                                                    isRequired={this.state.inputData.SoldToType.value !== OrderConstants.SOLDTOTYPE_DSP_VALUE}
                                                    isDisabled={this.state.inputData.SoldToType.value === OrderConstants.SOLDTOTYPE_DSP_VALUE}
                                                    selectedKey={this.state.inputData.ShipToType.value}
                                                    errorMessage={this.state.inputData.ShipToType.error ?
                                                        this.props.intl.formatMessage({ id: 'orderHeader.selectshiptotypetitle', defaultMessage: 'Select Ship To ' }) +
                                                        this.state.inputData.ShipToType.error:''
                                                    }
                                                    onSelect={(selectedKey) => this.setFields([
                                                        {
                                                            field: "ShipToType",
                                                            value: selectedKey ? selectedKey.key : '',
                                                            text: selectedKey ? selectedKey.text : '',
                                                            error: selectedKey ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                        },
                                                        {
                                                            field: "ShipToId",
                                                            value: '',
                                                            text: '',
                                                            error: ''
                                                        }
                                                    ])} />
                                            </div>
                                            {
                                                this.state.inputData.ShipToType.value !== '0' ?
                                                    <div className="ms-Grid-col ms-md4 ms-sm12">
                                                        {this.state.inputData.ShipToType.value === OrderConstants.SHIPTOTYPE_OEM_VALUE ?
                                                            <ShipToList
                                                                label={this.props.intl.formatMessage({ id: 'orderHeader.selectshiptoloctitle', defaultMessage: 'Ship - To - Location' })}
                                                                isRequired={true}
                                                                placeholder={this.props.intl.formatMessage({ id: 'app.autocompleteplaceholder', defaultMessage: 'Please type to search...' })}
                                                                selectedText={this.state.inputData.ShipToId.text}
                                                                errorMessage={this.state.inputData.ShipToId.error?
                                                                    this.props.intl.formatMessage({ id: 'orderHeader.selectshiptoloctitle', defaultMessage: 'Ship - To - Location' }) + ' '
                                                                    + this.state.inputData.ShipToId.error
                                                                    :''}
                                                                siteId={this.state.inputData.SiteId.value}
                                                                customerId={this.state.inputData.CustomerId.value}
                                                                onSelect={(selectedval) => this.setFields([
                                                                    {
                                                                        field: "ShipToId",
                                                                        value: selectedval ? selectedval.key : "",
                                                                        text: selectedval ? selectedval.displayText : "",
                                                                        error: selectedval ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                                    }
                                                                ])} /> :
                                                            <ThirdPartyList
                                                                label={this.props.intl.formatMessage({ id: 'orderHeader.selecttpititle', defaultMessage: 'Third Party Installer' })}
                                                                isRequired={true}
                                                                selectedTpi={this.state.inputData.ShipToId.value}
                                                                errorMessage={this.state.inputData.ShipToId.error ?
                                                                    this.props.intl.formatMessage({ id: 'orderHeader.selecttpititle', defaultMessage: 'Third Party Installer' }) + " " +
                                                                    this.state.inputData.ShipToId.error
                                                                    : '' 
                                                                }
                                                                customerId={this.state.inputData.CustomerId.value}
                                                                handleSelect={(event, value) => this.setFields([
                                                                    {
                                                                        field: "ShipToId",
                                                                        value: value.key,
                                                                        text: value.text,
                                                                        error: value ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: 'Required Field' })
                                                                    }
                                                                ])} />
                                                        }
                                                    </div> : null
                                            }
                                        </div>
                                    </React.Fragment> : null
                            }
                        </div>

                    </WizardStepBody>

                    <WizardStepFooter>
                        <DefaultButton
                            className="iconRight margin-top10"
                            primary={true}
                            data-automation-id="test"
                            disabled={this.state.inputData.IsTSTDisable.value}
                            text={this.props.intl.formatMessage({ id: 'app.next', defaultMessage: 'Next' })}
                            iconProps={{ iconName: 'ChevronRightSmall' }}
                            onClick={() => { this.onSubmitClick() }}
                            allowDisabledFocus={true} />
                    </WizardStepFooter>
                </WizardStep>
            </div >
        );
    }
}

const checkOrderId = (state) => {
    let val = isDuplicateOrderIdSelector(state)
    if (val !== null && validateOrderIdResolve) {
        validateOrderIdResolve(val);
    }

    return val;
}

const mapStateToProps = (state) => {
    return {
        isDuplicateOrderId: checkOrderId(state),
        requestedShipDateRole: validComponentRoleMappingSelector(state, "RequestedShipDate")
    };
}

const mapDispatchToProps = (dispatch) => ({
    getValidateOrderId(siteId, orderId) {
        dispatch(getValidateOrderId(siteId, orderId))
    },    
    alertActions: bindActionCreators({ showHiddenAlert }, dispatch),
    hideFeatureAlert: bindActionCreators(hideFeatureAlert, dispatch),
    showAlert: bindActionCreators(showAlert, dispatch),
})

OrderHeaderStep.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderHeaderStep));