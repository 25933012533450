import { take, put, call, apply } from 'redux-saga/effects';
import fileDownload  from 'react-file-download';
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import * as VerifyLicenseActions from './SearchVerifyLicense.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';
import uuidv4 from 'uuid/v4'
const getQueryParameter = (searchFilter) => {
    let queryParams = "";  

    queryParams = queryParams + (searchFilter.page ? "PageNo=" + searchFilter.page : "");
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
   
    return queryParams;
} 

export function* searchVerifyLicenseSaga() {
    while (true) {
        try {
            const { customerId, licenses, searchFilter } = yield take(VerifyLicenseActions.GET_SEARCH_VERIFY_LICENSE_RESULT);
            let requestData = JSON.stringify(licenses);

            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${customerId}/commitments?${getQueryParameter(searchFilter)}`, method: 'POST',data: requestData}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(VerifyLicenseActions.setSearchVerifyLicenseResult(data));
            } else {
                yield put(VerifyLicenseActions.setSearchVerifyLicenseResult(null));
            }
        } catch (error) {
            yield put(VerifyLicenseActions.setSearchVerifyLicenseResult(null));
            yield put(throwException("searchVerifyLicenseSaga error: " + error));
        }
    }
}

export function* exportVerifyLicenseSaga() {
    while (true) {
        try {
            const { customerId, licenses, searchFilter } = yield take(VerifyLicenseActions.EXPORT_VERIFY_LICENSE_RESULT);
            let requestData = JSON.stringify(licenses);
           
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${customerId}/commitments/report?${getQueryParameter(searchFilter)}`, method: 'POST',data: requestData},SagaHandleResponse :{ HandleResponse : false }});
            if (response.status === 200) {
                response.blob().then(data => {
                    fileDownload(data, `VerifyLicense_Report_${new Date().toDateString().split(" ").join("_")}.xlsx`);
                });
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
            }else
            {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Failed to generate report"] }));
            }
        } catch (error) {
            yield put(throwException("SearchLicensablesResultSaga details error: " + error));
        }
    }
}
