import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {    
    GET_COUNTRIES, setCountries
} from './CountryList.actions';
import {throwException} from '../../../core/components/Logger/Logger.actions'
export function* countriesSaga() {

    while (true) {
        try {
            yield take(GET_COUNTRIES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/countries`,method: 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var countriesData = data.map((d) => {
                    return {                        
                        key: d.CountryCode.toString(),
                        text: d.CountryName.toString()+"("+d.CountryCode.toString()+")"
                    };
                });
                yield put(setCountries(countriesData));
            } 
        } catch (error) {
        yield put(throwException("countriesSaga error: " + error));
        }
    }
}