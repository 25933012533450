import { makeActionCreator } from "../../../core/utility";

export const GET_SEARCH_ORDER_RESULT ="order/components/SearchOrder/GET_SEARCH_ORDER_RESULT";
export const getSearchOrderResult = makeActionCreator(GET_SEARCH_ORDER_RESULT, false,"searchFilter");
export const SET_SEARCH_ORDER_RESULT = "order/components/SearchOrder/SET_SEARCH_ORDER_RESULT";
export const setSearchOrderResult = makeActionCreator(SET_SEARCH_ORDER_RESULT, false, "orders");
export const EXPORT_ORDER_RESULT = "order/components/SearchOrder/EXPORT_ORDER_RESULT";
export const exportOrderResult = makeActionCreator(EXPORT_ORDER_RESULT, false, "searchFilter");
export const SAVE_SEARCH_ORDER_CRITERIA ="order/components/SearchOrder/SAVE_SEARCH_ORDER_CRITERIA";
export const saveSearchOrderCriteria = makeActionCreator(SAVE_SEARCH_ORDER_CRITERIA, false,"searchCriteria");

