import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox } from 'office-ui-fabric-react';
import { nullRender } from 'office-ui-fabric-react';
import { getSoldToBrands } from './OrderHeader.actions';
import { brandsSelector } from './OrderHeader.selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { OrderConstants } from '../../../../app/order/constants';
import { ComboBoxStyle } from '../../../../app/common/constants'

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    selectedBrand: PropTypes.string,
    errorMessage: PropTypes.string,
    customerId: PropTypes.string,
    siteId: PropTypes.string,
    soldToType: PropTypes.string,
    handleSelect: PropTypes.func
}

class BrandNamesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brands: this.props.brands,
            errorMessage: this.props.errorMessage
        }
    }

    componentDidMount() {
        this.props.getSoldToBrands(this.props.siteId, this.props.customerId, this.props.soldToType);
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.siteId !== this.props.siteId || nextProps.customerId !== this.props.customerId || nextProps.soldToType !== this.props.soldToType) && nextProps.siteId && nextProps.customerId && nextProps.soldToType) {
            this.props.getSoldToBrands(nextProps.siteId, nextProps.customerId, nextProps.soldToType);
            this.setState({ brands: [], errorMessage: '' });
        }
        else {
            let val = nextProps.brands ? Object.keys(nextProps.brands).length : 0
            val > 0 ? this.setState({ brands: nextProps.brands, errorMessage: nextProps.errorMessage }) : this.setState({ brands: [], errorMessage: this.props.intl.formatMessage({ id: 'orderHeader.brandsNotFound', defaultMessage: 'No brand exists' }) });

            if (!nextProps.selectedBrand && nextProps.soldToType !== OrderConstants.SOLDTOTYPE_OEM_VALUE) {
                let defaultValue = nextProps.brands && nextProps.brands[0];
                if (defaultValue && defaultValue.key === "0") {
                    this.props.handleSelect(null, defaultValue);
                }
            }
        }
    }

    render = () => {
        if (!(this.props.siteId && this.props.customerId && this.props.soldToType)) {
            return nullRender();
        }
        return (
            <ComboBox
                name="brandId"
                id="brandId"
                label={this.props.label}
                required={this.props.isRequired}
                selectedKey={this.props.selectedBrand}
                options={this.state.brands}
                onChange={this.props.handleSelect}
                errorMessage={this.state.errorMessage}
                autoComplete='off'
                useComboBoxAsMenuWidth={true}
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brands: brandsSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getSoldToBrands(siteId, customerId, soldToType) {
        dispatch(getSoldToBrands(siteId, customerId, soldToType))
    }
});

BrandNamesList.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BrandNamesList));