import { createSelector } from 'reselect';

const includeRoles = (userRoles, compRoles) => {
    let counter = 0;
    compRoles.forEach(element => {
        counter = counter + userRoles.includes(element) ? 1 : 0;
    });

    return counter > 0;
}

export const arSitesSelector = createSelector(
    state => state.app.get("arSites"),
    arSites => arSites ? arSites.toJS() : arSites
);

export const userClaimsSelector = createSelector(
    state => state.app.get("userClaims"),
    userClaims => userClaims ? userClaims.toJS() : userClaims
);
export const userRolesSelector = createSelector(
    state => state.app.get("userRoles"),
    userRoles => userRoles ? userRoles.toJS() : userRoles
);

export const rolesMappingSelector = createSelector(
    state => state.app.get("rolesMapping"),
    rolesMapping => rolesMapping ? rolesMapping.toJS() : rolesMapping
);

export const userEmailSelector = createSelector(
    state => state.app.getIn(["userInfo","user"]),
    userInfo=> userInfo ? userInfo.displayableId : userInfo
);
export const navigationMenuSelector = createSelector(
    state => state.app.get("navigationMenu"),
    navigationMenu => navigationMenu ? navigationMenu.toJS() : navigationMenu
);


export const contactEmailSelector = createSelector(
    state => state.app.get("contactEmail"),
    contactEmail => contactEmail 
);


export const validComponentRoleMappingSelector = (state, componentMap) => createSelector(
    [
        state => userRolesSelector(state),
        state => rolesMappingSelector(state)
    ],
    (userRoles, rolesMapping) => {
         let componentRole = rolesMapping ? rolesMapping.ComponentRoleMapping[componentMap] : null;
         return componentRole && userRoles && includeRoles(userRoles, componentRole);
    }
)(state);