import {makeActionCreator} from '../../../core/utility'
export const SAVE_BRAND ="artools/components/Brands/SAVE_BRAND";
export const saveBrand = makeActionCreator(SAVE_BRAND,false, "siteId","customerId","request");

export const GET_SEARCH_BRAND_RESULT = "artools/components/Brands/GET_SEARCH_BRAND_RESULT";
export const getSearchBrandResult = makeActionCreator(GET_SEARCH_BRAND_RESULT, false, "siteId", "customerId","brandName","searchFilter");
export const SET_SEARCH_BRAND_RESULT = "artools/components/Brands/SET_SEARCH_BRAND_RESULT";
export const setSearchBrandResult = makeActionCreator(SET_SEARCH_BRAND_RESULT, false, "searchBrandResult");

export const UPDATE_BRAND ="artools/components/Brands/UPDATE_BRAND";
export const updateBrand = makeActionCreator(UPDATE_BRAND,false, "siteId","customerId","brandId","request"); 

export const GET_VALIDATE_BRANDNAME ="artools/components/Brands/GET_VALIDATE_BRANDNAME";
export const getValidateBrandName = makeActionCreator(GET_VALIDATE_BRANDNAME, false,"siteId","customerId", "brandName");
export const SET_VALIDATE_BRANDNAME = "artools/components/Brands/SET_VALIDATE_BRANDNAME";
export const setValidateBrandName = makeActionCreator(SET_VALIDATE_BRANDNAME, false, "isDuplicateBrandName");

export const SET_SAVE_BRAND_OEPRATION_COMPLETION_STATUS = "artools/components/Brands/SET_SAVE_BRAND_OEPRATION_COMPLETION_STATUS";
export const saveBrandOperationCompletionStatus = makeActionCreator(SET_SAVE_BRAND_OEPRATION_COMPLETION_STATUS, false, "saveBrandOperationCompleted");

