import { makeActionCreator } from "../../../core/utility";

export const GET_SEARCH_SHIPMENT_RESULT ="shipment/components/SearchShipment/GET_SEARCH_SHIPMENT_RESULT";
export const getSearchShipmentResult = makeActionCreator(GET_SEARCH_SHIPMENT_RESULT, false,"searchFilter");
export const SET_SEARCH_SHIPMENT_RESULT = "shipment/components/SearchShipment/SET_SEARCH_SHIPMENT_RESULT";
export const setSearchShipmentResult = makeActionCreator(SET_SEARCH_SHIPMENT_RESULT, false, "shipments");
export const EXPORT_SHIPMENT_RESULT = "shipment/components/SearchShipment/EXPORT_SHIPMENT_RESULT";
export const exportShipmentResult = makeActionCreator(EXPORT_SHIPMENT_RESULT, false, "searchFilter");
export const SAVE_SEARCH_SHIPMENT_CRITERIA ="shipment/components/SearchShipment/SAVE_SEARCH_SHIPMENT_CRITERIA";
export const saveSearchShipmentCriteria = makeActionCreator(SAVE_SEARCH_SHIPMENT_CRITERIA, false,"searchCriteria");
