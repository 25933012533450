import { createSelector } from 'reselect'
export const pendingAPICallsSelector = createSelector(    
    state => state.app.get(`pendingAPIcalls`),
   pendingAPIcalls=>pendingAPIcalls
)

export const isSpinnerOn = createSelector(    
    state => state.app.get(`pendingAPIcalls`),
   pendingAPIcalls=> pendingAPIcalls>0
)

export const pendingAPICallsPanelSelector = createSelector(    
    state => state.app.get(`pendingAPIcallsPanel`),
    pendingAPIcallsPanel=>pendingAPIcallsPanel
)

export const isSpinnerOnPanel = createSelector(    
    state => state.app.get(`pendingAPIcallsPanel`),
    pendingAPIcallsPanel=> pendingAPIcallsPanel>0
)