import { makeActionCreator } from "../../../core/utility";

export const GET_SEARCH_VERIFY_LICENSE_RESULT ="shipment/components/VerifyLicense/GET_SEARCH_VERIFY_LICENSE_RESULT";
export const getSearchVerifyLicenseResult = makeActionCreator(GET_SEARCH_VERIFY_LICENSE_RESULT, false, "customerId", "licenses", "searchFilter");
export const SET_SEARCH_VERIFY_LICENSE_RESULT = "shipment/components/VerifyLicense/SET_SEARCH_VERIFY_LICENSE_RESULT";
export const setSearchVerifyLicenseResult = makeActionCreator(SET_SEARCH_VERIFY_LICENSE_RESULT, false, "verifyLicenseSearchResult");
export const EXPORT_VERIFY_LICENSE_RESULT = "shipment/components/VerifyLicense/EXPORT_VERIFY_LICENSE_RESULT";
export const exportVerifyLicenseResult = makeActionCreator(EXPORT_VERIFY_LICENSE_RESULT, false, "customerId", "licenses", "searchFilter");

export const SET_VERIFY_LICENSE_ENDITEM_LIST = "shipment/components/VerifyLicense/SET_VERIFY_LICENSE_ENDITEM_LIST";
export const setVarifyLicenseEndItemsList = makeActionCreator(SET_VERIFY_LICENSE_ENDITEM_LIST, false, "verifyLicenseEndItemsSearchText");
