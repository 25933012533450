import * as Msal from "@azure/msal-browser";
import PropTypes from "prop-types";

const UserInfoShape = {
	jwtAccessToken: PropTypes.string,
	jwtIdToken: PropTypes.string,
	user: PropTypes.instanceOf(MsalAccountInfo),
};

const AuthenticationState = {
	UNAUTHENTICATED: "Unauthenticated",
	AUTHENTICATED: "Authenticated"
};

const RedirectLoginInfoShape = {
	error: PropTypes.string,
	errorDesc: PropTypes.string,
	idToken: PropTypes.string,
	tokenType: PropTypes.string
};

const MsalAuthProviderConfigShape = {
	clientID: PropTypes.string,
	persistLoginPastSession: PropTypes.bool,
	scopes: PropTypes.arrayOf(PropTypes.string),
	redirectUri: PropTypes.string
};

class MsalAccountInfo {
	constructor(accountInfo){
		this.homeAccountId = accountInfo.homeAccountId;
		this.environment = accountInfo.environment;
		this.tenantId =  accountInfo.tenantId;
		this.username = accountInfo.username;
		this.localAccountId = accountInfo.localAccountId;
		this.name = accountInfo.name; 
		this.idTokenClaims =  accountInfo.idTokenClaims;
		this.idToken = accountInfo.idTokenClaims;
		this.displayableId = accountInfo.username;
	}
}


export { UserInfoShape, RedirectLoginInfoShape, MsalAuthProviderConfigShape, AuthenticationState, MsalAccountInfo };
