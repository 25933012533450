import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as OrderLineItemActions from './OrderLineItem.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'

const mapOrderTypes = (data) => {
    let ordertypes = [];
    data && data.forEach(data => {
        ordertypes.push({
            key: data.Value.toString(),
            text: data.Description
        })
    })
    return ordertypes;
}

export function* orderLineItemOrderTypeSaga() {
    while (true) {
        try {
            const { SoldToType } = yield take(OrderLineItemActions.GET_ORDER_LINE_ITEM_ORDERTYPE);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/ordertypes/${SoldToType}`, method:'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(OrderLineItemActions.setOrderLineItemOrderType(mapOrderTypes(data)));
            } 
        } catch (error) {
            yield put(throwException("orderLineItemOrderTypeSaga error: " + error));
        }
    }
}
