import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox, nullRender } from 'office-ui-fabric-react';
import { getLineItemLicensable } from './Licensable.actions';
import { lineItemLicensableSelector } from './Licensable.selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ShipmentConstants } from '../../../shipment/constants';
import { ComboBoxStyle } from '../../constants'

const propTypes = {
    customerId: PropTypes.string,
    customerType: PropTypes.string.isRequired,
    lineItemType: PropTypes.string.isRequired,
    endItemId: PropTypes.string.isRequired,
    compareDate: PropTypes.instanceOf(Date),
    handleSelect: PropTypes.func,
    required: PropTypes.bool
}

const extractDate = (dt) => {
    dt = new Date(dt);
    return new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
}

class Licensable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lineItemLicensables: this.props.lineItemLicensables
        }
    }

    componentDidMount() {
        let compareDate;
        if (this.props.compareDate) {
            compareDate = extractDate(this.props.compareDate);
        }

        this.props.getLineItemLicensable(this.props.customerId, this.props.endItemId, this.props.lineItemType, compareDate, this.props.customerType);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customerId !== this.props.customerId || nextProps.endItemId !== this.props.endItemId || (nextProps.compareDate && this.props.compareDate && extractDate(nextProps.compareDate) !== extractDate(this.props.compareDate)) || nextProps.customerType !== this.props.customerType || nextProps.lineItemType !== this.props.lineItemType) {
            let compareDate;
            if (nextProps.compareDate) {
                compareDate = extractDate(nextProps.compareDate);
            }

            this.props.getLineItemLicensable(nextProps.customerId, nextProps.endItemId, nextProps.lineItemType, compareDate, nextProps.customerType);
            this.setState({ lineItemLicensables: null });
        }
        else {
            let val = nextProps.lineItemLicensables ? Object.keys(nextProps.lineItemLicensables).length : 0
            val > 0 ? this.setState({ lineItemLicensables: nextProps.lineItemLicensables }) : this.setState({ lineItemLicensables: null });
        }
    }

    getLicensables() {
        let customerId = this.props.customerId;
        let endItemId = this.props.endItemId;
        let customerType = this.props.customerType;

        var data = (customerId || customerType === ShipmentConstants.CUSTOMERTYPE_AR_VALUE) && endItemId && this.state.lineItemLicensables &&
            this.state.lineItemLicensables[`${customerId}${endItemId}`];
        return data ? data : [];
    }

    onSelect = (event, data) => {
        var licensable = this.getLicensables().find(item => item.key === data.key);
        this.props.handleSelect(licensable);
    }
    render = () => {
        if (!(this.props.lineItemLicensables && this.props.endItemId)) {
            return nullRender();
        }
        return (
            <ComboBox
                name="LicensableItem"
                id="LicensableItem"
                label={this.props.label}
                required={this.props.required}
                selectedKey={this.props.selectedKey}
                options={this.getLicensables()}
                onChange={this.onSelect}
                errorMessage={this.props.errorMessage}
                autoComplete='off'
                useComboBoxAsMenuWidth={true}
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lineItemLicensables: lineItemLicensableSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getLineItemLicensable(customerId, endItemId, lineItemType, compareDate, customerType) {
        dispatch(getLineItemLicensable(customerId, endItemId, lineItemType, compareDate, customerType))
    }
});

Licensable.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Licensable));