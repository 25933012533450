import React, { Component } from 'react';
import { connect } from "react-redux";
import { OptionsButton } from '../../../core/components/OptionsButton';
import { getShipToTypes } from './OrderHeader.actions';
import { shiptotypesSelector } from './OrderHeader.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
//import { OrderConstants } from '../../constants';

let optionStyle = {
    width: '170px'
};

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled:PropTypes.bool,
    selectedKey: PropTypes.string,
    errorMessage: PropTypes.string,
    onSelect: PropTypes.func
}

class ShipToTypes extends Component {
    componentDidMount() {
        if (!this.props.shiptotypes) {
            this.props.getShipToTypes();
        }
    }

    render() {
        if (!(this.props.shiptotypes)) return null;        
        return (
            <OptionsButton
                name="ShipToType"
                id={"ShipToTypes"}
                label={this.props.label}
                isRequired={this.props.isRequired}
                isDisabled={this.props.isDisabled}
                defaultSelectedKey={this.props.selectedKey}
                errorMsg={this.props.errorMessage}
                options={this.props.shiptotypes}
                onSelect={this.props.onSelect}
                optionButtonStyle={optionStyle} />
        );
    }
}

const getOptions = (options) => {
    return options ? options.map(data => {
        return {
            key: data.key.toString(),
            text: data.text
        }
    }) : options;
}

const mapStateToProps = (state) => {
    return {
        shiptotypes: getOptions(shiptotypesSelector(state))
    };
}

const mapDispatchToProps = (dispatch) => ({
    getShipToTypes() {
        dispatch(getShipToTypes())
    }
})

ShipToTypes.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShipToTypes));