import { createReducer } from '../../../core/utility';
import { SET_ORDER_TYPES, SET_SOLDTO_TYPES, SET_SOLDTO_BRANDS, SET_SHIPTO_TYPES, SET_SHIPTO_ORGS, SET_VALIDATE_ORDERID } from './OrderHeader.actions';
import { fromJS } from 'immutable';

export const orderTypes = createReducer(null, {    
    [SET_ORDER_TYPES](state, { orderTypes }) {
        return fromJS(orderTypes);
    }
});

export const soldtotypes = createReducer(null, {    
    [SET_SOLDTO_TYPES](state, { soldtotypes }) {
        return fromJS(soldtotypes);
    }
});

export const brands = createReducer(null, {    
    [SET_SOLDTO_BRANDS](state, { brands }) {
        return fromJS(brands);
    }
});

export const shiptotypes = createReducer(null, {    
    [SET_SHIPTO_TYPES](state, { shiptotypes }) {
        return fromJS(shiptotypes);
    }
});

export const shipToOrgs = createReducer(null, {    
    [SET_SHIPTO_ORGS](state, { shipToOrgs }) {
        return fromJS(shipToOrgs);
    }
});

export const isDuplicateOrderId = createReducer(null, {    
    [SET_VALIDATE_ORDERID](state, { isDuplicateOrderId }) {
        return fromJS(isDuplicateOrderId);
    }
});
