import { makeActionCreator } from '../../../core/utility';

export const SAVE_SHIPTO = "artools/components/SearchShipTo/SAVE_SHIPTO";
export const saveShipTo = makeActionCreator(SAVE_SHIPTO, false, "siteId", "customerId", "request");

export const UPDATE_SHIPTO = "artools/components/SearchShipTo/UPDATE_SHIPTO";
export const updateShipTo = makeActionCreator(UPDATE_SHIPTO, false, "siteId", "customerId", "customerSiteId", "request");

export const SEARCH_SHIPTO = "artools/components/SearchShipTo/SEARCH_SHIPTO";
export const getSearchShipToResult = makeActionCreator(SEARCH_SHIPTO, false, "aradsiteId", "customerId","shipToId","searchFilter");

export const SET_SEARCH_SHIPTO_RESULT = "artools/components/SearchShipTo/SET_SEARCH_SHIPTO_RESULT";
export const setSearchShipToResult = makeActionCreator(SET_SEARCH_SHIPTO_RESULT, false, "searchShipToResult");

export const GET_VALIDATE_SHIPTO = "artools/components/SearchShipTo/GET_VALIDATE_SHIPTO";
export const getValidateShipToName = makeActionCreator(GET_VALIDATE_SHIPTO, false, "siteId", "customerId", "name");

export const SET_VALIDATE_SHIPTO = "artools/components/SearchShipTo/SET_VALIDATE_SHIPTO";
export const setValidateShipToName = makeActionCreator(SET_VALIDATE_SHIPTO, false, "isDuplicateShipTo");

export const GET_SHIPTO_BY_CUSTOMERSITEID = "artools/components/SearchShipTo/GET_SHIPTO_BY_CUSTOMERSITEID";
export const getShipToByCustomerSiteId = makeActionCreator(GET_SHIPTO_BY_CUSTOMERSITEID, false, "siteId", "customerId", "customerSiteId");

export const SET_SHIPTO_BY_CUSTOMERSITEID = "artools/components/SearchShipTo/SET_SHIPTO_BY_CUSTOMERSITEID";
export const setCustomerSiteDetails = makeActionCreator(SET_SHIPTO_BY_CUSTOMERSITEID, false, "customerSiteDetails");

export const SET_SAVE_SHIPTO_OEPRATION_COMPLETION_STATUS = "artools/components/SearchShipTo/SET_SAVE_SHIPTO_OEPRATION_COMPLETION_STATUS";
export const saveShipToOperationCompletionStatus = makeActionCreator(SET_SAVE_SHIPTO_OEPRATION_COMPLETION_STATUS, false, "saveShipToOperationCompleted");
