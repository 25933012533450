import React, { Component } from 'react';
import { connect } from "react-redux";
import { OptionsButton } from '../../../core/components/OptionsButton';
import { getSoldToTypes } from './OrderHeader.actions';
import { soldtotypesSelector } from './OrderHeader.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

let optionStyle = {
    width: '195px'
};

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    selectedKey: PropTypes.string,
    errorMessage: PropTypes.string,
    onSelect: PropTypes.func,
    isDisabled: PropTypes.bool
}

class SoldToTypeOptions extends Component {
    componentDidMount() {
        if (!this.props.soldtotypes) {
            this.props.getSoldToTypes();
        }
    }

    render() {
        if (!this.props.soldtotypes) return null;
        return (
            <OptionsButton
                name="SoldToType"
                id={"SoldToTypes"}
                label={this.props.label}
                isRequired={this.props.isRequired}
                defaultSelectedKey={this.props.selectedKey}
                errorMsg={this.props.errorMessage}
                options={this.props.soldtotypes}
                isDisabled={this.props.isDisabled}
                onSelect={this.props.onSelect}
                optionButtonStyle={optionStyle} />
        );
    }
}

const getOptions = (options) => {
    return options ? options.map(data => {
        return {
            key: data.key.toString(),
            text: data.text
        }
    }) : options;
}

const mapStateToProps = (state) => {
    return {
        soldtotypes: getOptions(soldtotypesSelector(state))
    };
}

const mapDispatchToProps = (dispatch) => ({
    getSoldToTypes() {
        dispatch(getSoldToTypes())
    }
})

SoldToTypeOptions.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SoldToTypeOptions));