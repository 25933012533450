import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import {GET_BARCODE_DESCRIPTIONS,SAVE_BARCODE_MAPPING,GET_BARCODE_MAPPING,setBarcodeDescriptions,setBarcodeMapping} from './Barcode.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'

export function* barcodeDescriptionsSaga() {
    while (true) {
        try {
            yield take(GET_BARCODE_DESCRIPTIONS);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/barcodes`, method: 'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                var barcodeDescriptions = data && data.map((d) => {
                    return { key: d.BarcodeId, text: d.Description };
                });
                yield put(setBarcodeDescriptions(barcodeDescriptions));
            } 
           
        } catch (error) {
            yield put(throwException("get barcode description error: " + error));
        }
    }
}


export function* barcodeMappingSaga() {
    while (true) {
        try {
            const { siteId, customerId } = yield take(GET_BARCODE_MAPPING);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/v1/site/${siteId}/customer/${customerId}/barcode`, method: 'GET'}, SagaHandleResponse : {HandleResponse:true ,ExcludedResponses :[404]}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(setBarcodeMapping(data));
            } 
            else if(response.status === 404)
            {
                yield put(setBarcodeMapping({}));
            }
           
        } catch (error) {
            yield put(throwException("get barcode mapping saga error: " + error));
        }
    }
}



export function* saveBarcodeMappingSaga() {
    while (true) {
        try {
            const { arSiteId, customerId, barcodeId} = yield take(SAVE_BARCODE_MAPPING);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl,  { Config : {url: `${config.web_api_path}/api/coa/v1/site/${arSiteId}/customer/${customerId}/barcodeId/${barcodeId}`, method: 'POST'}});
            if (response.status === 200) {
                yield put(setBarcodeMapping({
                    AradSiteId : arSiteId,
                    CustomerId : customerId,
                    BarCodeId: barcodeId
                }));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Barcode mapping saved successfully"] }));
            } 
           
        } catch (error) {
            yield put(throwException("save barcode saga error: " + error));
        }
    }
}