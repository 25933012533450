import React from 'react';
import PropTypes from 'prop-types';
import WizardNavItem from './WizardNavItem';
import './WizardNav.scss';

const propTypes = {
    items: PropTypes.array.isRequired,
    onItemClick: PropTypes.func
}

class WizardNav extends React.Component {


    isOrderComplete = () => {
        var completedItems = this.props.items.filter(item=> item.status === 3);
        return completedItems.length === this.props.items.length;
    }

    //status : 1 -> Not Started, 2-> In Progress, 3 -> Completed, 0 -> Disabled

    render() {
        let stateClassName = this.isOrderComplete() ? "completed": "disabled";
        return (
            // <div className="wizard-nav flat">
            //     <div className="flat">
            //         {this.props.items && this.props.items.map((item, index) => {
            //             return <WizardNavItem key={index} title={item.text} status={item.status} onItemClick={() => this.props.onItemClick(item)} ></WizardNavItem>
            //         })}
            //     </div>
            // </div>

            

            <div className={"wizard-nav-container"} >
                 {this.props.items && this.props.items.map((item, index) => {
                        return <WizardNavItem key={index} title={item.text} status={item.status} onItemClick={() => this.props.onItemClick(item)} ></WizardNavItem>
                    })}

                 <div className={"wizard-nav-item-container " + stateClassName}>
                    <div className="wizard-nav-item">          
                       
                    </div>
                    <div className="status-container">               
                       
                    </div>            
                </div>     
            </div>
        );
    }
}
WizardNav.propTypes = propTypes;
export default WizardNav;