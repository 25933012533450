import * as CoreSagas from './app/core/sagas';
import * as CommonSagas from './app/common/sagas';
import * as OrderSagas from './app/order/sagas';
import * as ShipmentSagas from './app/shipment/sagas';
import * as LicenseSagas from './app/license/sagas';
import * as ArToolsSagas from './app/artools/sagas';

const sagas = {...CoreSagas, ...CommonSagas, ...OrderSagas, ...ShipmentSagas, ...LicenseSagas, ...ArToolsSagas};

export const initSagas = (sagaMiddleware) =>{
    Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
}