import { takeLatest, put, call, apply } from 'redux-saga/effects';
import { GET_CUSTOMERS, setCustomers } from './Customer.actions';
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException} from '../../../core/components/Logger/Logger.actions'
import { TSTStatusTypeErrorMessage, TSTStatusType } from "@ec-oem/ec.oem.oa3.ui.common/components/ErrorType/InvalidTSTStatus/InvalidTSTStatus.constants";
export function* getCustomersListener() {
    yield takeLatest(GET_CUSTOMERS, getCustomersSaga);
}


function* getCustomersSaga(input) {
    try {
        const { searchString } = yield (input);
        const config = yield ApplicationConfig.config;
        const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/customer?searchString=${searchString}`, method:'GET'}});
        if (responseC.status === 200) {
            const data = yield apply(responseC, responseC.json);
            var customerData = data && data.map((d) => {
                return {                        
                    key: d.CustomerId.toString(),
                    displayText: d.DisplayName,
                    CustomerId: d.CustomerId,
                    TSTStatusID: d.TSTStatusID && config.TSTFeature==="1" 
                        ? IsValidCompany(d.TSTStatusID,config.TSTStatusMapper) : null                      
                };
            });               
            yield put(setCustomers(customerData));
        } 
    } catch (error) {
        yield put(throwException("Customers Saga error:" + error));
    }
}
function IsValidCompany (TSTStatusID,TSTStatusMapper) {
    let status = TSTStatusID.toString().replace(/\s+/g, "");
    let tstStatusId = TSTStatusType[status];
 return (
    {
        TSTStatus : tstStatusId,
        DisableFields : !TSTStatusMapper.NoBlockTSTStatuses.includes(tstStatusId),
        TSTError : TSTStatusMapper.NoBlockTSTStatuses.includes(tstStatusId) ? null
                          : (TSTStatusMapper.PartialBlockTSTStatuses.includes(tstStatusId) 
                          && TSTStatusTypeErrorMessage[tstStatusId]  
                          ? TSTStatusTypeErrorMessage[tstStatusId] 
                          : TSTStatusTypeErrorMessage[TSTStatusType.UnderReview]),
        IngestionError: tstStatusId === TSTStatusType.IngestionError
    }
  ); 
}