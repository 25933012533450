import React from "react";
import PropTypes from "prop-types";
import { TabIndexes } from "../../../common/constants";

const propTypes = {  
  title: PropTypes.string.isRequired
};

const WizardStepHeader = ({title}) => {
  return(
    <div className="wizard-step-header">      
        <h2>{title}</h2>
    </div>
  );
}
WizardStepHeader.propTypes = propTypes;
export default WizardStepHeader;
