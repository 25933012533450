import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { contactEmailSelector } from '../../selectors';
import { throwException } from '../../../core/components/Logger/Logger.actions';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        this.props.logError(error);
    }

    render() {
        if (this.state.hasError) {
            return (<div className="error-boundary container">
                <h1>{this.props.intl.formatMessage({ id: 'common.error.1', defaultMessage: 'Something went wrong. Please try reloading the page. Please Contact ' })}

                <a href={"mailto:" + this.props.contactEmail}>{this.props.contactEmail}</a>
                {this.props.intl.formatMessage({ id: 'common.error.2', defaultMessage: ' if the error persists.' })}
                </h1>
            </div>);
        }

        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    logError(error) {
        dispatch(throwException(error));
    }

})

const mapStateToProps = (state, ownProps) => {
    return {
        contactEmail : contactEmailSelector(state)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ErrorBoundary));