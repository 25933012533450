import { createReducer } from "../../../core/utility";
import { fromJS } from "immutable";
import * as searchProductActions from './SearchProduct.actions';

export const productMasterInfo=createReducer(null,{
  [searchProductActions.SET_PRODUCT_MASTER_INFO](state,{productMasterInfo}){
    return fromJS(productMasterInfo)
  }
})

export const searchProductResults=createReducer(null,{
    [searchProductActions.SET_SEARCH_PRODUCT_RESULTS](state,{searchProductResults}){
      return fromJS(searchProductResults)
    }
  })

export const productFamilyValues=createReducer(null,{
    [searchProductActions.SET_PRODUCT_FAMILY_VALUES](state,{productFamilyValues}){
      return fromJS(productFamilyValues)
    }
  })
  
  export const searchProductCriteria=createReducer(null,{
    [searchProductActions.SAVE_SEARCH_PRODUT_CRITERIA](state,{searchProductCriteria}){
      return fromJS(searchProductCriteria)
    }
  })