import React from "react";
import PropTypes from "prop-types";
import { IconButton } from 'office-ui-fabric-react';

const propTypes = {  
  isOpen: PropTypes.bool,
  onAccordionToggle: PropTypes.func,
  children: PropTypes.any,
  accordionheader: PropTypes.string
};



const AccordionHeader = ({ isOpen, onAccordionToggle,children,accordionheader}) => {
  
  return(
    <div className="accordion-header" >      
      <div className="accordion-header-text">
        {children}
      </div> 
      <div aria-expanded={isOpen} role="alert" className="accordion-header-icon" onClick={() => onAccordionToggle(!isOpen)}>
        {
          isOpen ? 
            <IconButton iconProps={{ iconName: 'ChevronUp' }}
                aria-label={(accordionheader != undefined || accordionheader != null ? accordionheader : "") + (" show section expanded")}
                aria-expanded="true"
                className="header-toggle-icon ms-slideUpIn10" />:
            <IconButton iconProps={{ iconName: 'ChevronDown' }}
                aria-label={(accordionheader != undefined || accordionheader != null ? accordionheader : "") + (" hide section collapsed")}
                aria-expanded="false"
                className="header-toggle-icon ms-slideDownIn10" />      
        }
      </div>        
    </div>
  );
}
AccordionHeader.propTypes = propTypes;
export default AccordionHeader;
