import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { UPDATE_ORDER } from './EditOrder.actions';
import { setOrderDetails } from '../OrderDetails/OrderDetails.actions';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'

function mapLicenseData(lineItems) {
    var lineItemArray = [];
    for (var item of lineItems) {
        lineItemArray.push({
            EndItemId: item.EndItem.value,
            ShipmentTypeCode: item.ShipmentType.value,
            Quantity: item.Quantity.value,
            ArlineItemNumber: item.ArlineItemNumber.value,
            LicensableItemId: item.LicensableItem.value,
            LicenseId: item.LicenseId.value,
            ClientTransactionType: item.ClientTransactionType
        })
    }

    return lineItemArray;
}

function mapOrderDataToRequestObject(orderRequest) {
    return {
        OrderId: orderRequest.OrderId.value,
        OrderType: orderRequest.OrderType.value,
        SoldToId: orderRequest.SoldToId.value,
        PoNumber: orderRequest.PoNumber.value,
        BrandId: orderRequest.BrandId.value,
        ShipToType: orderRequest.ShipToType.value,
        ShipToId: orderRequest.ShipToType.value === 1 ? orderRequest.ShipToId.value : '',
        InstallerId: orderRequest.ShipToType.value === 2 ? orderRequest.ShipToId.value : '',
        PoDate: orderRequest.PoDate.value,
        CommittedDate: orderRequest.CommittedDate.value,
        ReqShipDate: orderRequest.ReqShipDate.value,
        LineItems: mapLicenseData(orderRequest.LineItems),
        Remark: orderRequest.Remark.value,
        ClientTransactionType: orderRequest.ClientTransactionType
    };
}

export function* updateOrderSaga() {
    while (true) {
        try {
            const { siteId, orderRequest } = yield take(UPDATE_ORDER);
            let requestData = JSON.stringify(mapOrderDataToRequestObject(orderRequest));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order/${orderRequest.MsOrderId.value}`,method: "PUT", data:requestData}});
            if (response.status === 200) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["order details are updated successfully"] }));
                const orderresponse = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order/${orderRequest.MsOrderId.value}`, method:"GET"}});
                const data = yield apply(orderresponse, orderresponse.json);
                yield put(setOrderDetails(data));
            } 
        } catch (error) {
            yield put(throwException("updateOrderSaga error: " + error));
        }
    }
}