import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as searchLicensablesActions from './SearchLicensables.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'
import uuidv4 from 'uuid/v4';

const getSearchRequest = (searchFilter) => {
    let queryParams = "";  

    queryParams = queryParams + (searchFilter.PageNo ? "PageNo=" + searchFilter.PageNo : "");
    queryParams = queryParams + (searchFilter.PageSize ? "&PageSize=" + searchFilter.PageSize : "");
    queryParams = queryParams + (searchFilter.LicensableNumber ? "&SearchString=" + searchFilter.LicensableNumber : "");
   
    return queryParams;
} 

export function* searchLicensablesResultSaga() {
    while (true) {
        try {
            const { licensableFilter } = yield take(searchLicensablesActions.GET_SEARCH_LICENSABLES_RESULT);
            
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${licensableFilter.CustomerId}/licenses/${licensableFilter.AgreementNumber}/licensables?${getSearchRequest(licensableFilter)}`, method:'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(searchLicensablesActions.setSearchLicensablesResult(data));
            } 
        } catch (error) {
            yield put(throwException("SearchLicensablesResultSaga details error: " + error));
        }
    }
}


