import { take,  takeLatest, put, call, apply } from 'redux-saga/effects';
import {
    GET_ORDER_TYPES, setOrderTypes,
    GET_SOLDTO_TYPES, setSoldToTypes,
    GET_SOLDTO_BRANDS, setSoldToBrands,
    GET_SHIPTO_TYPES, setShipToTypes,
    GET_SHIPTO_ORGS, setShipToOrgs,
    GET_VALIDATE_ORDERID, setValidateOrderId
} from './OrderHeader.actions'
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes, AlertsFeature } from '../../../core/constants';




export function* orderTypesSaga() {

    while (true) {
        try {
            yield take(GET_ORDER_TYPES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/inventorytypes`, method: 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var orderTypesData = data.map((d) => {
                    return { key: d.Value.toString(), text: d.Description };
                });

                yield put(setOrderTypes(orderTypesData));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch order types."] }));
                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch order types."] }));

                console.log(["Failed to fetch order types."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch order types."] }));

            console.log("orderTypesSaga error:" + error);
        }
    }
}

export function* soldtotypesSaga() {

    while (true) {
        try {
            yield take(GET_SOLDTO_TYPES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/soldtotypes?isOrder=true`, method: 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var soldToTypesData = data.map((d) => {
                    return { key: d.DistributionChannelType.toString(), text: d.Description };
                });

                yield put(setSoldToTypes(soldToTypesData));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch sold to types."] }));

                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch sold to types."] }));

                console.log(["Failed to fetch sold to types."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Internal Error Occurred,Failed to fetch sold to types."] }));

            console.log("soldtotypesSaga error:" + error);
        }
    }
}

export function* brandsSagaListener() {
    yield takeLatest(GET_SOLDTO_BRANDS, brandsSaga);
}

function* brandsSaga(params) {
    try {
        const { siteId, customerId, soldToType } = params;
        console.log("Test" + siteId +"-" +customerId +"-" + soldToType );
        const config = yield ApplicationConfig.config;
        if (siteId && customerId) {
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/brands?SiteId=${siteId}&CustomerId=${customerId}&DistributionChannel=${soldToType}`, method:'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var brandsData = data.map((d) => {
                    return {
                        key: d.BrandId.toString(),
                        text: d.BrandName
                    };
                });


                yield put(setSoldToBrands(brandsData));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch Brand Names."] }));

                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch Brand Names."] }));

                console.log(["Failed to fetch BrandNames."]);
            }
        }
    } catch (error) {
        yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Internal Error Occurred,Failed to fetch Brand Names."] }));

        console.log("brandsSaga error:" + error);
    }
}

export function* shiptotypesSaga() {

    while (true) {
        try {
            yield take(GET_SHIPTO_TYPES);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/shiptotypes`, method : 'GET'}});

            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);

                var shipToTypesData = data.map((d) => {
                    return { key: d.Value.toString(), text: d.Description };
                });

                yield put(setShipToTypes(shipToTypesData));

            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch ship to types."] }));

                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch ship to types."] }));

                console.log(["Failed to fetch ship to types."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Internal Error Occurred,Failed to fetch ship to types."] }));

            console.log("shiptotypesSaga error:" + error);
        }
    }
}

export function* shipToOrgsListener() {
    yield takeLatest(GET_SHIPTO_ORGS, shipToOrgsSaga);
}

function* shipToOrgsSaga(input) {
    try {
        const { siteId, customerId, searchString } = yield (input);
        const config = yield ApplicationConfig.config;
        const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/shipto?CustomerId=${customerId}&SiteId=${siteId}&SearchString=${searchString}`, method:'GET'}});

        if (responseC.status === 200) {
            const data = yield apply(responseC, responseC.json);

            var shipToData = data.map((d) => {
                return {
                    key: d.CustomerSiteId.toString(),
                    displayText: d.DisplayName
                };
            });


            yield put(setShipToOrgs(shipToData));

        } else if (responseC.status === 500) {
            const data = yield apply(responseC, responseC.json);
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch Ship to Orgs."] }));

            console.log([data]);
        } else {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to fetch Ship to Orgs."] }));
            console.log(["Failed to fetch Shipto Orgs."]);
        }

    } catch (error) {
        yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Internal Error Occurred,Failed to fetch Ship to Orgs.."] }));

        console.log("shipToOrgsSaga error:" + error);
    }
}

export function* isDuplicateOrderIdSaga() {

    while (true) {
        try {
            const { siteId, orderId } = yield take(GET_VALIDATE_ORDERID);
            const config = yield ApplicationConfig.config;
            let requestData = JSON.stringify(orderId);
            const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/order/isduplicate`, method:"POST", data: requestData}});

            if (responseC.status === 200) {
                const isValid = yield apply(responseC, responseC.json);
                yield put(setValidateOrderId({ id: orderId.Id, status: isValid }));
            } else if (responseC.status === 500) {
                const data = yield apply(responseC, responseC.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to validate OrderID."] }));
                console.log([data]);
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Failed to validate OrderID."] }));

                console.log(["Failed to validate OrderID."]);
            }

        } catch (error) {
            yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.ORION_ALERTS, message: ["Internal Error Occurred, Failed to validate OrderID."] }));

            console.log("isDuplicateOrderIdSaga error:" + error);
        }
    }
}
