import {makeActionCreator} from '../../../core/utility';

export const GET_VALIDATE_SHIPMENTID ="shipment/components/ShipmentHeader/GET_VALIDATE_SHIPMENTID";
export const getValidateShipmentId = makeActionCreator(GET_VALIDATE_SHIPMENTID, false,"siteId", "shipmentId");
export const SET_VALIDATE_SHIPMENTID = "shipment/components/ShipmentHeader/SET_VALIDATE_SHIPMENTID";
export const setValidateShipmentId = makeActionCreator(SET_VALIDATE_SHIPMENTID, false, "isDuplicateShipmentId");

export const GET_CUSTOMERTYPES ="shipment/components/ShipmentHeader/GET_CUSTOMERTYPES";
export const getCustomerTypes = makeActionCreator(GET_CUSTOMERTYPES, false);
export const SET_CUSTOMERTYPES = "shipment/components/ShipmentHeader/SET_CUSTOMERTYPES";
export const setCustomerTypes = makeActionCreator(SET_CUSTOMERTYPES, false, "customerTypes");