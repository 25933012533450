import { take, put, call, apply } from 'redux-saga/effects';
import fileDownload  from 'react-file-download';
import { InvokeUrl } from '../../../core/utility';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import * as SearchShipmentActions from './SearchShipment.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';
import uuidv4 from 'uuid/v4';

const getSearchRequest=(searchFilter)=>{
    let queryParams="";
    searchFilter && searchFilter.ArSiteId.forEach((item)=>{     
        if(queryParams){
            queryParams = queryParams + "&ArSiteId=" + item;
        }
        else{
            queryParams = "ArSiteId=" + item;
        }                
    });
    searchFilter.Filters && Object.keys(searchFilter.Filters).forEach((item) => {
        searchFilter.Filters[item].forEach(filter => {
            queryParams = queryParams + "&" + item + "=" + filter;
        });        
    });
  
    queryParams = queryParams + (searchFilter.SearchType ? "&SearchType=" + searchFilter.SearchType : "");
    queryParams = queryParams + (searchFilter.SearchText ? "&SearchText=" + searchFilter.SearchText : "");

    queryParams = queryParams + (searchFilter.CustomerId ? "&CustomerId=" + searchFilter.CustomerId : "");
    queryParams = queryParams + (searchFilter.EndItem ? "&EndItem=" + searchFilter.EndItem : "");
    queryParams = queryParams + (searchFilter.Component ? "&Component=" + searchFilter.Component : "");
    queryParams = queryParams + (searchFilter.DateType ? "&DateType=" + searchFilter.DateType : "");
    queryParams = queryParams + (searchFilter.StartDate ? "&StartDate=" + searchFilter.StartDate : "");
    queryParams = queryParams + (searchFilter.EndDate ? "&EndDate=" + searchFilter.EndDate : "");

    queryParams = queryParams + (searchFilter.PageNo ? "&PageNo=" + searchFilter.PageNo : "");
    queryParams = queryParams + (searchFilter.PageSize ? "&PageSize=" + searchFilter.PageSize : "");
    queryParams = queryParams + (searchFilter.SortDirection ? "&SortDirection=" + searchFilter.SortDirection : "");
    queryParams = queryParams + (searchFilter.SortBy ? "&SortBy=" + searchFilter.SortBy : "");

    return queryParams;
   }

export function* searchShipmentSaga() {
    while (true) {
        try {
            const { searchFilter } = yield take(SearchShipmentActions.GET_SEARCH_SHIPMENT_RESULT);
           
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/shipments?`+ getSearchRequest(searchFilter), method:'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                data.ResultId = uuidv4();
                yield put(SearchShipmentActions.setSearchShipmentResult(data));
            } else {
                yield put(SearchShipmentActions.setSearchShipmentResult(null));
            }
        } catch (error) {
            yield put(throwException("searchShipmentSaga error: " + error));
            yield put(SearchShipmentActions.setSearchShipmentResult(null));
        }
    }
}

export function* exportShipmentSaga() {
    while (true) {
        try {
            const { searchFilter } = yield take(SearchShipmentActions.EXPORT_SHIPMENT_RESULT);

            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/shipments/report?` + getSearchRequest(searchFilter), method:'GET' },SagaHandleResponse :{ HandleResponse : false }});
            if (response.status === 200) {
                response.text().then(data => {
                    fileDownload(data, `Shipment_Report_${new Date().toDateString().split(" ").join("_")}.csv`);
                });
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
            } 
            else
            {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Failed to generate report"] }));
            }
        } catch (error) {
            yield put(throwException("searchOrderSaga error: " + error));
        }
    }
}
