import {makeActionCreator} from '../../core/utility';
export const GET_ARSITES ="common/actions/GET_ARSITES";
export const getArSites = makeActionCreator(GET_ARSITES, false);

export const SET_ARSITES = "common/actions/SET_ARSITES";
export const setArSites = makeActionCreator(SET_ARSITES, false, "sites");

export const GET_CLAIMS ="common/actions/GET_CLAIMS";
export const getClaims = makeActionCreator(GET_CLAIMS, false);

export const SET_CLAIMS = "common/actions/SET_CLAIMS";
export const setClaims = makeActionCreator(SET_CLAIMS, false, "claims");

export const GET_NAVIGATION_MENU ="common/actions/GET_NAVIGATION_MENU";
export const getNavigationMenu = makeActionCreator(GET_NAVIGATION_MENU, false);

export const SET_NAVIGATION_MENU ="common/actions/SET_NAVIGATION_MENU";
export const setNavigationMenu = makeActionCreator(SET_NAVIGATION_MENU,false,"navigationMenu");

