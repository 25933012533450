import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { GET_SHIPMENT_WIZARD_DATA, setShipmentWizardData, SAVE_SHIPMENT, setShipment } from './CreateShipment.actions';
import shipmentWizardData from './ShipmentWizardConfig.json';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { throwException } from '../../../core/components/Logger/Logger.actions';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';

export function* getShipmentWizardDataSaga() {
    while (true) {
        try {
            yield take(GET_SHIPMENT_WIZARD_DATA);
            yield put(setShipmentWizardData(shipmentWizardData));
        } catch (error) {
            yield put(throwException("getShipmentWizardDataSaga error: " + error));
        }
    }
}

export function* saveShipmentSaga() {
    while (true) {
        try {
            const { siteId, shipmentRequest } = yield take(SAVE_SHIPMENT);
            let requestData = JSON.stringify(shipmentRequest);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/coa/v1/${siteId}/shipment`, method: "POST", data: requestData } });
            if (response.status === 201) {
                const data = yield apply(response, response.json);
                yield put(setShipment(data));
            }
        } catch (error) {
            yield put(throwException("saveShipmentSaga error: " + error));
        }
    }
}


