import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userRolesSelector } from '../../selectors';
class AuthorizedComponent extends PureComponent {
    isAuthorized() {
        return this.props.authorizedRoles.some(authorizedRole => this.props.userRoles.includes(authorizedRole));
    }
    render() {
        const { children, userRoles } = this.props;
        if (!userRoles || !this.isAuthorized()) return null;//Not authorized results in no render, can be enhanced to render the false condition later
        return <React.Fragment>{children}</React.Fragment>

    }
}
const mapStateToProps = (state) => {
    return {
        userRoles: userRolesSelector(state)
    }
}
export default connect(mapStateToProps, null)(AuthorizedComponent)