export const PageTitles = {
    CREATE_ORDER:"Create Order",
    EDIT_ORDER:"Edit Order",
    CREATE_SHIPMENT:"Create Shipment",
    EDIT_SHIPMENT:"Edit Shipment",
    ORDER_DASHBOARD:"Order Dashboard",
    SHIPMENT_DASHBOARD:"Shipment Dashboard",
    SEARCH_ORDER:"Search Order",
    SEARCH_SHIPMENT:"Search Shipment",
    ORDER_DETAILS:"Order Details",
    SHIPMENT_DETAILS:"Shipment Details",
    LICENSE:"License",
    OEM_PARTNUMBER_MANAGEMENT: "Part Number Maintenance",
    BARCODE_SYMBOLOGY_MAINTENANCE: "Barcode Symbology Maintenance",
    BRANDLOGO: "Brand Name and Logo Maintenance",
    OEM_SHIPTO: "Ship To Maintenance"
}
export const RedirectPath = "/redirectURI";

export const TstAllowStatus = ["1","6","10","11","12","13"];

export const TSTStatusKey="http://ms.it.oem/2011/10/claims/HomeCompanyTSTStatus";

export const IsActiveAgreementKey="http://ms.it.oem/2011/10/claims/HomeCompanyIsActiveAgreement";

