import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import registerServiceWorker from './registerServiceWorker';
import { getStore } from './store';
import { initializeIcons } from '@uifabric/icons';
import { IntlProvider } from "react-intl";
import { localizationData } from './localizationData';
import ApplicationConfig from './app/core/utility/applicationConfig';
import { AzureAD } from './app/core/components/Auth';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { ErrorBoundary } from './app/common/components/ErrorBoundaries';
const App = React.lazy(() => import('./app/App'));
initializeIcons();

const language = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

// Split locales with a region code
const languageCode = language && language.toLowerCase().split(/[_-]+/)[0];
const messages = localizationData[languageCode] || localizationData[language] || localizationData.en;


ApplicationConfig.config.then((config) => {
    const store = getStore();
    ReactDOM.render((

        <Provider store={store}>

            <Suspense fallback={<ProgressIndicator />}>
                <IntlProvider locale={languageCode} messages={messages}>
                    <ErrorBoundary>
                        <AzureAD msal_config={config.MSAL_info}
                            componentToRender={App} />
                    </ErrorBoundary>

                </IntlProvider>
            </Suspense>
        </Provider>

    ), document.getElementById('root'));
    registerServiceWorker();
    return config;
});