
import * as React from 'react';

export class CardHeader extends React.Component {
  render() {
    return (
      <div className="card-header">
        {this.props.children}       
       </div>
    );
  }
}