import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ComboBox } from 'office-ui-fabric-react';
import { arSitesSelector } from '../../selectors';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ComboBoxStyle } from '../../constants'

const propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    selectedSite: PropTypes.string,
    errorMessage: PropTypes.string,
    handleSelect: PropTypes.func,
    componentRef: PropTypes.object,
    isDisabled: PropTypes.bool
}

class ArSiteList extends Component {

    render = () => {
        return (
            <ComboBox
                name="arSiteList"
                id="arSiteList"
                label={this.props.label}
                required={this.props.isRequired}
                disabled={this.props.isDisabled}
                selectedKey={this.props.selectedSite}
                errorMessage={this.props.errorMessage}
                options={this.props.arSites ? this.props.arSites : []}
                onChange={this.props.handleSelect}
                componentRef={this.props.componentRef}
                onMenuOpen={this.props.onComboboxMenuOpen}
                autoComplete='off'
                useComboBoxAsMenuWidth={true}
                styles={ComboBoxStyle.errorStyle}
                comboBoxOptionStyles={ComboBoxStyle.OptionStyle}
                aria-live= {this.props.errorMessage ? "assertive" : "polite"}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        arSites: arSitesSelector(state)
    };
}

ArSiteList.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, null)(ArSiteList));