import { call, put, apply, take } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import { UPDATE_SHIPMENT } from './EditShipment.actions';
import { showAlert } from '../../../core/components/Alert';
import { AlertsTypes } from '../../../core/constants';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { setShipmentDetails } from '../ShipmentDetails/ShipmentDetails.actions'
import { ShipmentConstants } from '../../constants/ShipmentConstants';
import {throwException } from '../../../core/components/Logger/Logger.actions';


const extractDate = (dt) => {
    dt = new Date(dt);
    return new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
}

function mapShipmentDataToRequestObject(shipmentRequest) {
    return {
        ArshipmentId: shipmentRequest.ArshipmentId.value,
        ShipDate: extractDate(shipmentRequest.ShipDate.value),
        PoNumber: shipmentRequest.PoNumber.value,
        PoDate: shipmentRequest.PoDate.value,
        CommittedDate: shipmentRequest.CommittedDate.value,
        ReqShipDate: shipmentRequest.ReqShipDate.value,
        SoldToId: shipmentRequest.SoldToId.value,
        InstallerId: shipmentRequest.InstallerId.value,
        ShipToCountryCode: shipmentRequest.ShipToCountry.value.trim(),
        ShipToCity: shipmentRequest.ShipToCity.value,
        Remark: shipmentRequest.Remark ? shipmentRequest.Remark.value : '',
        LineItems: mapLicenseData(shipmentRequest.LineItems),
        ClientTransactionType: shipmentRequest.ClientTransactionType
    };
}

function mapLicenseData(lineItems) {
    var lineItemArray = [];
    for (var item of lineItems) {
        lineItemArray.push({
            ShipmentTypeCode: item.ShipmentType.value,
            PoNote: item.PoMemo.value,
            InvoiceNumber: item.InvoiceNumber ? item.InvoiceNumber.value : '',
            InvoiceDate: item.InvoiceDate.value ? extractDate(item.InvoiceDate.value) : null,
            Quantity: item.Quantity.value,
            IsComponent: item.ProductType.value === ShipmentConstants.SHIPMENTLINEITEM_PRODUCTTYPE_ENDITEM_VALUE ? false : true,
            ItemId: item.Item.value,
            LicensableItemId: item.LicensableItem.value,
            LicenseId: item.LicenseId.value,
            ClientTransactionType: item.ClientTransactionType
        })
    }

    return lineItemArray;
}

export function* updateShipmentSaga() {
    while (true) {
        try {
            const { siteId, shipmentRequest } = yield take(UPDATE_SHIPMENT);
            let requestData = JSON.stringify(mapShipmentDataToRequestObject(shipmentRequest));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/${shipmentRequest.MsShipmentId.value}`, method:"PUT", data:requestData}});
            if (response.status === 200) {
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Shipment details are updated successfully"] }));
                const shipmentData = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/${siteId}/shipment/${shipmentRequest.MsShipmentId.value}`, method:"GET"}});
                const shipment = yield apply(shipmentData, shipmentData.json);
                yield put(setShipmentDetails(shipment));
            } 
        }
        catch (error) {
            yield put(throwException("updateShipmentSaga error: " + error));
        }
    }
}
