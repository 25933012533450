import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as licensingActions from './Licensing.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions'
export function* companyInfoSaga() {
    while (true) {
        try {
            const { customerId } = yield take(licensingActions.GET_COMPANY_INFO);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/v1/customer/${customerId}`, method:'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(licensingActions.setCompanyInfo(data));
            } 
        } catch (error) {
            yield put(throwException("CompanyInfo company Info error: " + error));
        }
    }
}
