import React, { Component } from 'react';
import './SearchResult.scss'
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox } from 'office-ui-fabric-react';
import { Accordion, AccordionHeader, AccordionBody } from '../../../core/components';
import { TabIndexes } from '../../../common/constants/tabIndex';

const propTypes = {
    field:  PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.array,
    onFilterChange: PropTypes.func    
}

export class SearchFilter extends Component {  
        
    handleChange = (event, filterItem) => {
        let items = this.props.items;
        var selectedItem = items.find(item => item.value === filterItem.value);
        if(selectedItem){
            selectedItem.isSelected = !selectedItem.isSelected;
        }
        let selectedItems = items.filter(item => item.isSelected);
            
        const data = {
            field: this.props.field,
			title: this.props.title,
            items: selectedItems          
        }
        this.props.onFilterChange(data);
    }
       
    render() {        
        return (
            <div className="search-filter">
                <Accordion isOpen={this.props.isOpen}>
                    <AccordionHeader accordionheader={this.props.title}>
                        <div className="search-filter-name">
                            <span tabIndex={TabIndexes.OutOfOrder} title={this.props.title} aria-label={this.props.title}>{this.props.title}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="search-filter-items">
                            {
                                this.props.items && this.props.items.map((item,index)=>
                                    <div key={index} className="search-filter-item">   
                                        <div className="search-filter-item-checkbox"> 
                                            <Checkbox ariaLabel={item.text + " " + item.count} label={item.text} disabled={item.count===0} checked={item.isSelected} onChange={(e)=> this.handleChange(e,item)}  />
                                        </div>      
                                        <div className="search-filter-item-count"> 
                                            <span>{item.count}</span>
                                        </div>                             
                                    </div>
                                )
                            }                    
                        </div>    
                    </AccordionBody>
                </Accordion>                   
            </div>           
        );
    }
}

SearchFilter.propTypes = propTypes;

export default injectIntl(SearchFilter);
