import { THROW_EXCEPTION, TRACK_TRACE,TRACK_METRIC} from './Logger.actions';
import { take } from 'redux-saga/effects';

export function* throwExceptionConsoleSaga(){
    while(true){
    const {exceptionMessage} = yield take(THROW_EXCEPTION);
    console.error(exceptionMessage);}
}

export function* trackTraceConsoleSaga(){
    while(true){
    const {traceMessage} = yield take(TRACK_TRACE);
    console.info(traceMessage);}
}

export function* trackMetricConsoleSaga(){
    while(true){
    const {trackMetric} = yield take(TRACK_METRIC);
    console.info(trackMetric.Name + trackMetric.Value);}
}