
import { createReducer } from '../../../core/utility';
import {
  SET_ORDER_WIZARD_DATA, SET_ORDER_WIZARD_STEP_DATA,
  ORDER_WIZARD_STEP_NEXT, ORDER_WIZARD_STEP_PREVIOUS,
  SET_ORDER_WIZARD_CURRENT_STEP, SET_ORDER
} from './CreateOrder.actions';
import { fromJS } from 'immutable';
import { OrderConstants } from '../../constants';

export const orderWizardData = createReducer(null, {
  [SET_ORDER_WIZARD_DATA](state, { wizardData }) {
    loadWizardStatusData(wizardData);
    return fromJS(wizardData);
  },

  [SET_ORDER_WIZARD_STEP_DATA](state, { stepData }) {
    let currentState = state.toJS();
    updateStepData(currentState, stepData);
    return fromJS(currentState);
  },

  [SET_ORDER_WIZARD_CURRENT_STEP](state, { stepData }) {
    let currentState = state.toJS();
    updateCurrentStep(currentState, stepData);
    return fromJS(currentState);
  },

  [ORDER_WIZARD_STEP_NEXT](state, { stepData }) {
    let currentState = state.toJS();
    updateNextStepData(currentState, stepData);
    return fromJS(currentState);
  },
  
  [ORDER_WIZARD_STEP_PREVIOUS](state, { stepData }) {
    let currentState = state.toJS();
    updatePreviousStepData(currentState, stepData);
    return fromJS(currentState);
  },
});

export const createdOrder = createReducer(null, {
  [SET_ORDER](state, { order }) {
    return fromJS(order);
  },
});


function loadWizardStatusData(wizardData) {
  if (!wizardData)
    return;
  wizardData.sort((a, b) => a.order - b.order);
  if (wizardData.length > 0) {
    wizardData[0].status = OrderConstants.IN_PROGRESS
  }
}


function updateNextStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0 && (index + 1) < wizardSteps.length) {
    wizardSteps[index] = stepData;
    wizardSteps[index].status = OrderConstants.COMPLETED;

    let currentStep = wizardSteps[index + 1];
    currentStep.status = OrderConstants.IN_PROGRESS;
  }
}

function updatePreviousStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index > 0) {
    wizardSteps[index] = stepData;
    wizardSteps[index].status = OrderConstants.NOT_STARTED;

    let currentStep = wizardSteps[index - 1];
    currentStep.status = OrderConstants.IN_PROGRESS;
  }
}

function updateCurrentStep(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0) {
    let lastStep = wizardSteps.find(item => item.status === OrderConstants.IN_PROGRESS);
    lastStep.status = lastStep.isStateValid ? OrderConstants.COMPLETED : OrderConstants.NOT_STARTED;

    wizardSteps[index] = stepData;
    wizardSteps[index].status = OrderConstants.IN_PROGRESS;
  }
}

function updateStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0) {
    wizardSteps[index] = stepData;
  }
}

