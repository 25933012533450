import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { orderInfoSelector } from './OrderDetails.selectors';
import * as orderDetailsActions from './OrderDetails.actions';
import { EditOrder } from '../EditOrder';
import OrderDetailsInfo from './OrderDetailsInfo';
import './OrderDetails.scss'
import Details from '../../../common/components/Details/Details';
import { pendingAPICallsSelector, FeatureAlerts } from '../../../core/components';
import { arSitesSelector, rolesMappingSelector } from '../../../common/selectors';
import queryString from 'query-string';
import {default as loadashFind} from 'lodash/find';
import {uniqueId as loadashUniqueId} from 'lodash/util';
import ReactAI from 'react-appinsights';
import { setPageTitle } from '../../../common/helpers/pageTitle';
import { AlertsFeature } from '../../../core/constants';
import { PageTitles } from '../../../common/constants';
import { withRouter } from 'react-router';
import {saveSearchOrderCriteria,setSearchOrderResult} from '../SearchOrder/SearchOrder.actions';

export class OrderDetails extends Component {
    constructor(props) {
        super(props);
        var param = queryString.parse(props.location.search);
        this.state = {
            msOrderId: this.props.match.params.msOrderId,
            siteId: param.siteId? (param.siteId.toString() || "0"): "0",
            isEditMode: false
        }      

       
    }
    componentWillMount() {
        if (this.state.msOrderId) {
            this.props.actions.getOrderDetails(this.state.siteId, this.state.msOrderId);
        }
        
    }    
    componentDidMount() {
        setPageTitle(PageTitles.ORDER_DETAILS);    
        this.props.history.listen((location, action) => {
            if(!(location.pathname==="/SearchOrder" || location.pathname.indexOf("/Order/")>-1)){
                this.props.searchOrderActions.saveSearchOrderCriteria(null);
                this.props.searchOrderActions.setSearchOrderResult(null);
            }           
        });    
    }

    componentWillUnmount(){
        this.props.actions.setOrderDetails(null);       
    }
    onEdit = () => {
        this.setState({ isEditMode: true });
    }

    onCancel = () => {

    }

    onApprove = (siteId) => {
        this.props.actions.setOrderApprovalStatus(siteId, this.state.msOrderId, true);
    }

    onReject = (siteId) => {
        this.props.actions.setOrderApprovalStatus(siteId, this.state.msOrderId, false);
    }

    onDelete = () => {

    }

    onEditSubmit = () => {
        this.setState({ isEditMode: false });
    }

    cancelEdit = () => {
        this.setState({ isEditMode: false });
    }
    render() {
        if ((!this.props.isLoading) && !this.props.orderInfo) {
            return <div className="order-not-found">Order Not Found</div>
        }
        if (!this.props.orderInfo)
            return null;
        var orderDetails = mapOrderToOrderInfo(this.props.orderInfo, this.state.siteId, this.props.arSites);
        return (
            <div className="container">
                <FeatureAlerts timeOut={5000} feature={AlertsFeature.ORION_ALERTS} />
                {
                    this.state.isEditMode ?
                        <EditOrder order={orderDetails} {...this.state} cancel={this.cancelEdit} onEditSubmit={this.onEditSubmit} /> :

                        <Details msIdFieldName={this.props.intl.formatMessage({ id: 'orderReview.MSOrderIdTitle', defaultMessage: 'MS Order Number' })}
                            orderShipmentData={orderDetails}
                            sectionHeading={this.props.intl.formatMessage({ id: 'order.orderDetailsTitle', defaultMessage: 'Order Details' })}
                            authorizedApproverRoles={this.props.rolesMapping.ComponentRoleMapping.ApproveOrder}
                            authorizedEditRoles={this.props.rolesMapping.ComponentRoleMapping.EditOrder}
                            approve={() => this.onApprove(this.state.siteId)} edit={this.onEdit}
                            reject={() => this.onReject(this.state.siteId)} cancel={this.onCancel}
                            delete={this.onDelete}>
                            <OrderDetailsInfo {...orderDetails}></OrderDetailsInfo>
                        </Details>
                }
            </div>
        );


    }
}

function mapOrderToOrderInfo(data, siteId, arSites) {
    let arSiteIdObj = loadashFind(arSites, { key: siteId });
    let arSiteName = arSiteIdObj ? arSiteIdObj.text : '';

    return {
        OrderId: { value: data.OrderId, text: data.OrderId, error: '' },
        OrderType: { value: data.OrderTypeCode, text: data.OrderType, error: '' },
        SoldToType: { value: data.SoldToTypeCode.toString(), text: data.SoldToType, error: '' },
        SoldToId: { value: data.SoldToId, text: data.SoldToName, error: '' },
        BrandId: { value: data.BrandId, text: data.BrandName, error: '' },
        PoNumber: { value: data.PoNumber, text: '', error: '' },
        PoDate: { value: data.PoDate, text: '', error: '' },
        CommittedDate: { value: data.CommittedDate, text: '', error: '' },
        ReqShipDate: { value: data.ReqShipDate, text: '', error: '' },
        ShipToType: { value: data.ShipToTypeCode, text: data.ShipToType, error: '' },
        ShipToId: { value: data.ShipToId, text: data.ShipToName, error: '' },
        CustomerId: { value: data.CustomerId, text: '', error: '' },
        SiteId: { value: siteId, text: arSiteName, error: '' },
        Status: { value: data.StatusCode, text: data.Status, error: '' },
        MsOrderId: { value: data.MsOrderId, text: '', error: '' },
        Remark: { value: data.Remark, text: data.Remark, error: '' },
        LineItems: mapOrderLineItemToOrderInfo(data.LineItems),
        ClientTransactionType: 1
    };
}

function mapOrderLineItemToOrderInfo(lineItems) {
    var items = [];

    lineItems.forEach((lineItem, index) => {
        items.push({
            LineItemId: { value: loadashUniqueId(), text: '', error: '' },
            DisplayId: index + 1,
            ShipmentType: { value: lineItem.ShipmentTypeCode, text: lineItem.ShipmentType, error: '' },
            Quantity: { value: lineItem.Quantity, text: '', error: '' },
            ArlineItemNumber: { value: lineItem.ArlineItemNumber, text: '', error: '' },
            //ShowInactiveEndItems: { value: '', error: '' },
            EndItem: { value: lineItem.EndItemId, text: lineItem.EndItemName, error: '' },
            LicensableItem: { value: lineItem.LicensableItemId, text: lineItem.LicensableItemName, error: '' },
            LicenseId: { value: lineItem.LicenseId, text: '', error: '' },
            InactiveEndItem: { value: '', text: '', error: '' },
            IsCoaPrinted: {value: lineItem.IsCoaPrinted, text: '', error:''},
            isEditMode: true,
            ClientTransactionType: 1
        });
    });
    return items;
}

function mapStateToProps(state) {
    let pendingCalls = pendingAPICallsSelector(state);
    return {
        orderInfo: orderInfoSelector(state),
        arSites: arSitesSelector(state),
        rolesMapping:rolesMappingSelector(state),
        isLoading: pendingCalls > 0
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(orderDetailsActions, dispatch),
        searchOrderActions: bindActionCreators({ saveSearchOrderCriteria, setSearchOrderResult }, dispatch),
    }
}

export default ReactAI.withTracking(injectIntl(withRouter(connect(mapStateToProps,mapDispatchToProps)(OrderDetails))), PageTitles.ORDER_DETAILS);
