import { put, take, select, call } from "redux-saga/effects";

import { authProviderSelector } from "./authz.selectors";
import { INITIALIZE_AUTH, setAuthProvider, triggerLogin, TRIGGER_LOGIN, TRIGGER_LOGOUT, setUserInfo } from "./authz.actions";
import { MsalAuthProviderFactory } from "./Auth.helpers";
import { Logger } from "./Auth.helpers/Logger";
import * as Msal from "@azure/msal-browser";


const INTERACTION_IN_PROGRESS = "interaction_in_progress";
const COOKIE = "msal.interaction.status=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

export function* initializeAuthSaga() {
	while (true) {
		try {
			const { msal_config, userInfoCallback } = yield take(INITIALIZE_AUTH);
			let authFactory = new MsalAuthProviderFactory({
				...msal_config,
				persistLoginPastSession: true,
				redirectUri: window.location.origin,
				storeAuthStateInCookie: true
			});

			 let authProvider = authFactory.getAuthProvider();
			 authProvider.userInfoChangedCallback = userInfoCallback;
			 yield put(setAuthProvider(authProvider));
			 let auth = yield call(authProvider.handleRedirectPromise)
			 if(!auth){
                yield put(triggerLogin());
			 }
			yield call(authProvider.handleRedirect, auth);
		} catch (error) {
			Logger.error("Auth Provider initialisation error: " + error);
		}
	}
}

export function* loginSaga() {
	while (true) {
		try {
			yield take(TRIGGER_LOGIN);
			let authProvider = yield select((state) => authProviderSelector(state));
			yield call(authProvider.login);
		} catch (error) {
			Logger.error("LoginSaga saga error: " + error);
			if(error instanceof Msal.BrowserAuthError && error.errorCode === INTERACTION_IN_PROGRESS){
			  document.cookie = COOKIE ;
			  let authProvider = yield select((state) => authProviderSelector(state));
			  yield call(authProvider.login);
			}
		}
	}
}

export function* logoutSaga() {
	while (true) {
		try {
			yield take(TRIGGER_LOGOUT);
			let authProvider = yield select((state) => authProviderSelector(state));
			authProvider.logout();
			yield put(setUserInfo(null));
		} catch (error) {
			Logger.error("Logout saga error: " + error);
		}
	}
}
