import { takeLatest, put, call, apply } from 'redux-saga/effects';
import {   
    GET_SOLDTO_ORGS, setSoldToOrgs
} from './SoldToOrgControl.actions'
import { InvokeUrl } from '../../../core/utility';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import { throwException } from '../../../core/components/Logger/Logger.actions'
import { TSTStatusTypeErrorMessage, TSTStatusType } from "@ec-oem/ec.oem.oa3.ui.common/components/ErrorType/InvalidTSTStatus/InvalidTSTStatus.constants";
export function* soldtoOrdsListener() {
    yield takeLatest(GET_SOLDTO_ORGS, soldtoOrgsSaga);
}

function* soldtoOrgsSaga(input) {
    try {
        const config = yield ApplicationConfig.config;
        let channelTypeQuery = input.channelType? `&ChannelType=${input.channelType}`: "";
        const responseC = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/soldto?SearchString=${input.searchString}` + channelTypeQuery, method:'GET'}});
        if (responseC.status === 200) {
            const data = yield apply(responseC, responseC.json);
            var soldToOrgsData = data && data.map((d) => {
                return {                        
                    key: d.ShipToId.toString(),
                    displayText: d.DisplayName,
                    CustomerId: d.CustomerId,
                    IsCustomerOnHold: d.IsCustomerOnHold,
                    TSTStatusID: d.TSTStatusID && config.TSTFeature==="1" 
                        ? IsValidCompany(d.TSTStatusID,config.TSTStatusMapper) : null
                };
            });
            yield put(setSoldToOrgs(soldToOrgsData));
        }
    } catch (error) {
        yield put(throwException("soldtoOrgsSaga error: " + error));
    }
}
function IsValidCompany (TSTStatusID,TSTStatusMapper) {
		let status = TSTStatusID.toString().replace(/\s+/g, "");
		let tstStatusId = TSTStatusType[status];
	 return (
		{
			TSTStatus : tstStatusId,
			DisableFields : !TSTStatusMapper.NoBlockTSTStatuses.includes(tstStatusId),
			TSTError : TSTStatusMapper.NoBlockTSTStatuses.includes(tstStatusId) ? null
			                  : (TSTStatusMapper.PartialBlockTSTStatuses.includes(tstStatusId) 
                              && TSTStatusTypeErrorMessage[tstStatusId]  
                              ? TSTStatusTypeErrorMessage[tstStatusId] 
                              : TSTStatusTypeErrorMessage[TSTStatusType.UnderReview]),
			IngestionError: tstStatusId === TSTStatusType.IngestionError
		}
	  ); 
}