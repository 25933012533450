import { createReducer } from '../../utility/createReducer';
import { fromJS} from 'immutable';
import { INCREMENT_NO_OF_CALLS,DECREMENT_NO_OF_CALLS,INCREMENT_NO_OF_CALLS_PANEL,DECREMENT_NO_OF_CALLS_PANEL
    } from './Loader.actions';

export const pendingAPIcalls = createReducer(null, {
    [INCREMENT_NO_OF_CALLS](state) {
        if(state === null || state === undefined) return fromJS(1);
        return fromJS(state+1);
    },
    [DECREMENT_NO_OF_CALLS](state) {
        if(state === null || state === undefined) return fromJS(0);
        return fromJS(state-1);
    }
});

export const pendingAPIcallsPanel = createReducer(null, {
    [INCREMENT_NO_OF_CALLS_PANEL](state) {
        if(state === null || state === undefined) return fromJS(1);
        return fromJS(state+1);
    },
    [DECREMENT_NO_OF_CALLS_PANEL](state) {
        if(state === null || state === undefined) return fromJS(0);
        return fromJS(state-1);
    }
});