import React, { Component } from 'react';
import './EditOrder.scss'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { orderInfoSelector } from './EditOrder.selectors';
import * as editOrderActions from './EditOrder.actions';
import EditOrderReview from './EditOrderReview';
import EditOrderInfo from './EditOrderInfo';
import { DetailsHeader } from '../../../common/components/Details';
import { setPageTitle } from '../../../common/helpers/pageTitle';
import ReactAI from 'react-appinsights';
import { PageTitles } from '../../../common/constants';

export class EditOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order,
            isStateValid: '',
            review: true
        }
    }
    componentDidMount() {
        setPageTitle(PageTitles.EDIT_ORDER);
    }

    handleChange = (keyValueCollection) => {
        let order = this.state.order;
        keyValueCollection.forEach(element => {
            order[element.field].value = element.value;
            order[element.field].text = element.text;
            order[element.field].error = element.value ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" });
        });
        order.ClientTransactionType = 2;
        this.setState({
            order: order,
        });
    }

    handleChangeInLineItem = (object) => {
        var orderData = this.state.order;
        orderData.LineItems = [...object.lineItems];
        this.setState({ order: orderData });
    }

    openReviewPage = () => {
        this.setState({
            review: false
        });
    }

    openEditPage = () => {
        this.setState({
            review: true
        });
    }

    onSubmitClick = () => {
        this.state.order.SiteId && this.props.actions.updateOrder(this.state.order.SiteId.value, this.state.order);
        this.props.onEditSubmit();
    }



    render() {
        return (
            <div className="edit-order">
                <DetailsHeader headerText={this.props.intl.formatMessage({ id: 'orderEdit.HeaderTitle', defaultMessage: 'Edit Order' })}
                    msIdFieldText={this.props.intl.formatMessage({ id: 'orderReview.MSOrderIdTitle', defaultMessage: 'MS Order Number' })}
                    msId={this.state.order.MsOrderId.value} status={this.state.order.Status.text}></DetailsHeader>

                {this.state.review === true ?
                    <EditOrderInfo {...this.state} nextPage={this.openReviewPage} cancelPage={this.props.cancel} handleChange={this.handleChange} handleChangeInLineItem={this.handleChangeInLineItem}  ></EditOrderInfo> :
                    <EditOrderReview order={this.state.order} previousPage={this.openEditPage} cancelPage={this.props.cancel} handleChange={this.handleChange} save={this.onSubmitClick}></EditOrderReview>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orderInfo: orderInfoSelector(state)
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(editOrderActions, dispatch),
    }
}

export default ReactAI.withTracking(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditOrder)), PageTitles.EDIT_ORDER);
