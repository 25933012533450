import * as React from 'react';
import { PropTypes } from 'prop-types';
import './Card.scss';
import { TabIndexes } from '../../../common/constants';

const propTypes = {
  isCollapsed: PropTypes.bool,
}

export class Card extends React.Component {
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
    this.state = { isCollapsed: this.props.isCollapsed };
  }

  changeState(iscollapseStatus) {
    this.setState({ isCollapsed: iscollapseStatus });
  }

  render() {
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { isCollapsed: this.state.isCollapsed, changeState: this.changeState }));

    return (
        <div className="card" tabIndex={TabIndexes.OutOfOrder || this.props.onCardTabIndex}>
        {childrenWithProps}
      </div>
    );
  }
}

Card.propTypes = propTypes;