import React from 'react';

const Iterator = ({items, Component, onItemClick,...rest}) => {
    return (
        items && items.map((item,index)=>             
            <Component key={index} index={index} {...rest}  onItemClick={onItemClick} {...item}/>
        ) 
    );
}

export default Iterator;