import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pendingAPICallsPanelSelector } from './Loader.selectors';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import './Loader.scss';
class PanelLoader extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.show ?
                        <div className="panel-loading"><Spinner size={SpinnerSize.large} /></div>
                    : null
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    var pendingCalls = pendingAPICallsPanelSelector(state);

    return {
        show: pendingCalls > 0,
    }
};


export default connect(mapStateToProps, null)(PanelLoader);