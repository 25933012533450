import { take, takeLatest, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '../../../core/utility';
import * as ShipmentLineItemActions from './ShipmentLineItem.actions';
import ApplicationConfig from '../../../core/utility/applicationConfig';
import {throwException } from '../../../core/components/Logger/Logger.actions';

const mapShipmentTypes = (data) => {
    let shipmenttypes = [];
    data && data.forEach(data => {
        shipmenttypes.push({
            key: data.Value.toString(),
            text: data.Description
        })
    })
    return shipmenttypes;
}

export function* shipmentTypeLineItemSaga() {
    while (true) {
        try {
            const { SoldToType } = yield take(ShipmentLineItemActions.GET_SHIPMENT_TYPE_LINE_ITEM);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/shipmenttypes/${SoldToType}`,method: 'GET'}});
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                yield put(ShipmentLineItemActions.setShipmentTypeLineItem(mapShipmentTypes(data)));
            } 
        } catch (error) {
            yield put(throwException("Shipment Type LineItem Licensable error: " + error))
        }
    }
}

export function* shipmentComponentListener() {
    yield takeLatest(ShipmentLineItemActions.GET_SHIPMENT_LINE_ITEM_COMPONENT, shipmentComponentSaga);
}

function* shipmentComponentSaga(input) {
    try {
        const { inactiveEndItem, searchText } = yield (input);
        const config = yield ApplicationConfig.config;
        const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/coa/components?isActiveEnditemsOnly=${inactiveEndItem}&SearchString=${searchText}`,method: 'GET'}});
        if (response.status === 200) {
            const data = yield apply(response, response.json);
            var ComponentData = data.map((d) => {
                return {
                    key: d.ItemId,
                    displayText: d.Name
                };
            });
            yield put(ShipmentLineItemActions.setShipmentLineItemComponent(ComponentData));
        } 
    } catch (error) {
        yield put(throwException("LineItem Component error: " + error))
    }
}
