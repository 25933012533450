//states : 1 -> Not Started, 2-> In Progress, 3 -> Completed, 0 -> Disabled
export const OrderConstants = {
    DISABLED: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    VALIDATION_ERRROR: 4,
    ORDER_HEADER: "ORDER_HEADER",
    ORDER_LINE_ITEM: "ORDER_LINE_ITEM",
    REVIEW_SUBMIT: "REVIEW_SUBMIT",
    SHIPTOTYPE_OEM_VALUE: "1",
    SHIPTOTYPE_OEM_TEXT: "OEM",
    SHIPTOTYPE_TPI_VALUE: "2",
    SHIPTOTYPE_TPI_TEXT: "Third Party Installer",
    ORDERTYPE_DEFAULT_VALUE: "1",
    ORDERTYPE_DEFAULT_TEXT: "Print For Order",
    SOLDTOTYPE_DEFAULT_VALUE: "1",
    SOLDTOTYPE_DEFAULT_TEXT: "OEM",
    SHIPTOTYPE_DEFAULT_VALUE: "1",
    SHIPTOTYPE_DEFAULT_TEXT: "OEM",
    ORDERLINEITEM_ARLINENUMBER:"ArlineItemNumber",
    ORDERLINEITEM_LINEITEMID:"LineItemId",
    ORDERLINEITEM_INACTIVEENDITEM: "InactiveEndItem",
    ORDERTYPEOPTION_PRINT_FOR_INVENTORY_VALUE: "2",
    ORDERTYPEOPTION_PRINT_FOR_INVENTORY_INT_VALUE:2,
    SOLDTOTYPE_DSP_VALUE: "2",
    SOLDTOTYPE_OEM_VALUE: "1",
    SOLDTOTYPE_EMB_VALUE: "8",
    SUBMITTED_STATUS_CODE: "31",
    SUBMITTED_ACCEPT_STATUS_CODE: "41"
    
}
