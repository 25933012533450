import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Announced } from "office-ui-fabric-react/lib/Announced";

const propTypes = {
	itemsCount: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	pageSize: PropTypes.number,
	initialPage: PropTypes.number
};

const defaultProps = {
	initialPage: 1,
	pageSize: 10
};

class Pagination extends React.Component {
	constructor(props) {
		super(props);
		this.state = { pager: {} };
		this.changePage = this.changePage.bind(this);

		//this.onLazyPageChange = Debounce(this.changePage, 500, true);
	}

	componentWillMount() {
		// set page if items array isn't empty
		if (this.props.itemsCount > 0) {
			this.setPage(this.props.initialPage);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// reset page if items array has changed
		if (this.props.itemsCount !== prevProps.itemsCount || this.props.pageSize !== prevProps.pageSize) {
			this.setPage(this.props.initialPage);
		} else {
			if (this.props.initialPage !== prevProps.initialPage) this.setPage(this.props.initialPage);
		}
	}

	setPage(page) {
		var { itemsCount, pageSize } = this.props;
		var pager = this.state.pager;

		if (page < 1 || (pager.totalPages && page > pager.totalPages)) {
			return;
		}

		// get new pager object for specified page
		pager = this.getPager(itemsCount, page, pageSize);
		// update state
		this.setState({ pager: pager });

		// get new page of items from items array
		// var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

		// call change page function in parent component
		this.props.onChangePage(pager.currentPage);
	}

	getPager(totalItems, currentPage, pageSize) {
		// default to first page
		currentPage = currentPage || defaultProps.initialPage;

		// default page size is 10
		pageSize = pageSize || defaultProps.pageSize;

		// calculate total pages
		var totalPages = Math.ceil(totalItems / pageSize);

		var startPage, endPage;
		if (totalPages <= 5) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = totalPages;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (currentPage <= 3) {
				startPage = 1;
				endPage = 5;
			} else if (currentPage + 2 >= totalPages) {
				startPage = totalPages - 4;
				endPage = totalPages;
			} else {
				startPage = currentPage - 2;
				endPage = currentPage + 2;
			}
		}

		// calculate start and end item indexes
		var startIndex = (currentPage - 1) * pageSize;
		var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		var pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

		// return object with all pager properties required by the view
		return {
			totalItems: totalItems,
			currentPage: currentPage,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages
		};
	}

	changePage(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			if (e.target.value <= 0) {
				e.target.value = 1;
			} else if (e.target.value > this.state.pager.totalPages) {
				e.target.value = this.state.pager.totalPages;
			}
			this.setPage(parseInt(e.target.value));
		}
	}

	nextPreviousKeyPress = (e, currentPage) => {
		if (e.keyCode == 32 || e.keyCode == 13) this.setPage(currentPage);
	};
	onNumberKeyPress = (e, pager) => {
		if (e.keyCode == 32 || e.keyCode == 13) this.setPage(pager);
	};

	render() {
		var pager = this.state.pager;

		if (!pager.pages || pager.pages.length <= 1) {
			// don't display pager if there is only 1 page
			return null;
		}

		return (
			<React.Fragment>
				<div className="pagination">
					<ul className="pages">
						<li>
							<Announced message={pager.currentPage && "Page" + pager.currentPage + " Selected "} />
						</li>
						<li className={"page-item " + (pager.currentPage === 1 ? "disabled" : "")}>
							<a
								role="button"
								tabIndex={pager.currentPage === 1 ? '-1' : '0'}
								className="page-link"
								aria-label={this.props.intl.formatMessage({ id: "common.prevPage", defaultMessage: "Previous Page" })}
								onClick={() => this.setPage(pager.currentPage - 1)}
								onKeyDown={(event) => this.nextPreviousKeyPress(event, pager.currentPage - 1)}>
								&lt;
							</a>
						</li>
						{pager.pages.map((page, index) => (
							<li key={index} className="page-item">
								<a
									role="button"
									tabIndex="0"
									className={"page-link " + (pager.currentPage === page ? "active" : "")}
									aria-label={
										this.props.intl.formatMessage({ id: "common.page", defaultMessage: "Page" }) +
										page +
										(pager.currentPage === page ? " Selected " : " Not Selected")
									}
									onClick={() => this.setPage(page)}
									onKeyDown={(event) => this.onNumberKeyPress(event, page)}>
									{page}
								</a>
							</li>
						))}
						<li className={"page-item " + (pager.currentPage === pager.totalPages ? "disabled" : "")}>
							<a
								aria-label={this.props.intl.formatMessage({ id: "common.nextPage", defaultMessage: "Next Page" })}
								role="button"
								tabIndex={pager.currentPage === pager.totalPages ? '-1' : '0'}
								className="page-link  d-inline-flex align-items-center flex-row-reverse ms-glyph ms-chevron-right"
								onClick={() => this.setPage(pager.currentPage + 1)}
								onKeyDown={(event) => this.nextPreviousKeyPress(event, pager.currentPage + 1)}>
								&gt;
							</a>
						</li>
					</ul>
					<div className="pageno-input">
						<label>
							{this.props.intl.formatMessage({ id: "common.goToPage", defaultMessage: "Go to Page" })} ({1 + "-" + this.state.pager.totalPages})
						</label>
						<input
							title="Page Number"
							id="pageNo"
							type="number"
							min="1"
							max={this.state.pager.totalPages}
							 aria-label={
							 	this.props.intl.formatMessage({
							 		id: "common.pagination.pageNumberMsg1",
							 		defaultMessage: "Go to Page " + "1" + "hyphen" + this.state.pager.totalPages
							 	})
							}
							aria-valuemin="1"
							aria-valuemax={this.state.pager.totalPages}
							onKeyDown={this.changePage}></input>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default injectIntl(Pagination);
