import { createReducer } from "../../../core/utility/createReducer";
import { fromJS } from "immutable";
import * as LineItemActions from './Licensable.actions';

export const lineItemLicensable = createReducer(null, {
    [LineItemActions.SET_LINE_ITEM_LICENSABLE](state, {licenseKey, lineItemLicensable }) {
      var initialState = state? state.toJS(): {};
      initialState[licenseKey] = lineItemLicensable;    
      return fromJS(initialState);
    }
  });